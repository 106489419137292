<template>
  <div>
    <h2 id="scroll-target">{{ $t("courseCompTitle") }}</h2>
    <div v-if="localLoading" class="loading-indicator">
      {{ $t("loading") || "Chargement des cours..." }}
    </div>
    <div v-else class="courses-container">
      <CardCourse
        v-for="cours in userCourses"
        :key="cours?.id"
        :id="cours?.id"
        :titre="cours?.attributes?.titre"
        :points="cours?.attributes?.points"
        :gain="cours?.attributes?.gain"
        :level="cours?.attributes?.niveau"
        :url="cours?.attributes?.url"
        :category="cours?.attributes?.Category"
        :percent="cours?.attributes?.suivis?.data[0]?.attributes?.pourcentage"
        :publishedAt="cours?.attributes?.publishedAt"
        :cover="
          cours?.attributes?.Couverture?.data?.attributes?.formats.small.url
        "
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCourse from "../../components/cours/CardCourse.vue";
import DataLoadingMixin from "../../mixins/DataLoadingMixin";

export default {
  name: "course-profil",
  components: {
    CardCourse,
  },
  mixins: [DataLoadingMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters("cours", ["userCourses"]),
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("cours", ["coursUser"]),
    async fetchCourses() {
      const cacheKey = `userCourses-${this.userId || 'self'}`;
      await this.getWithCache(cacheKey, async () => {
        await this.coursUser({ userId: this.userId, lang: this.$i18n.locale });
        return true;
      });
    }
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.withLoading(this.fetchCourses());
      }
    } catch (error) {
      console.error("Error fetching user courses:", error);
    } finally {
      this.localLoading = false;
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(300px - 15px), 1fr));
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}

.loading-indicator {
  text-align: center;
  margin: 20px 0;
}
</style>
