<template>
  <div>
    <h2 id="scroll-target">{{ $t("duaasCompTitle") }}</h2>
    <div v-if="localLoading" class="loading">
      {{ $t("loading") || "Chargement des invocations..." }}
    </div>
    <div v-else class="duaas-container">
      <div class="container-duaa">
        <div class="item" v-for="duaa in duaasUser" :key="duaa.id">
          <CardDuaaUser
            :id="duaa.id"
            :arab="duaa?.attributes?.arab"
            :transcript="duaa?.attributes?.transcript"
            :translite="duaa?.attributes?.translite"
            :source="duaa.attributes?.source"
            :url_mp3="duaa?.attributes?.url_mp3"
            :category="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.name
            "
            :img="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.icon?.data?.attributes?.url
            "
            :slug="
              duaa?.attributes?.situations?.data[0]?.attributes?.sujets?.data[0]
                ?.attributes?.slug
            "
            :titreSlug="duaa?.attributes?.situations?.data[0]?.attributes?.slug"
            :titre="duaa?.attributes?.situations?.data[0]?.attributes?.name"
            :favoris="Boolean(duaa?.attributes?.users?.data[0]?.id)"
            @addFavorite="addFavorite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardDuaaUser from "../duaas/CardDuaaUser";
import { mapActions, mapGetters } from "vuex";
import DataLoadingMixin from "../../mixins/DataLoadingMixin";

export default {
  name: "duaas-profil",
  components: {
    CardDuaaUser,
  },
  mixins: [DataLoadingMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters("duaas", ["duaasUser"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("user", ["userId"]),
  },
  methods: {
    ...mapActions("duaas", ["fetchUserDuaa", "fetchUserDuaaById", "toggleDuaaInUser"]),
    async addFavorite(duaaId) {
      try {
        if (!this.readonly) {
          await this.toggleDuaaInUser(duaaId); // Gère l'ajout/retrait des favoris seulement pour l'utilisateur connecté
          // Invalider le cache pour forcer un rechargement
          this.invalidateCache(`userDuaas-${this.$i18n.locale}-${this.userId || 'self'}`);
        }
        await this.loadDuaas(true); // Forcer le rechargement
      } catch (error) {
        console.error("Error toggling duaa favorite:", error);
      }
    },
    async loadDuaas(forceRefresh = false) {
      this.localLoading = true;
      try {
        await this.fetchDuaas(forceRefresh);
        this.emitDataLoaded();
      } catch (error) {
        console.error("Error fetching duaas:", error);
      } finally {
        this.localLoading = false;
      }
    },
    async fetchDuaas(forceRefresh = false) {
      const cacheKey = `userDuaas-${this.$i18n.locale}-${this.userId || 'self'}`;
      return this.getWithCache(cacheKey, async () => {
        if (this.readonly && this.userId) {
          // Cas d'un profil d'un autre utilisateur
          await this.fetchUserDuaaById({
            userId: this.userId,
            lang: this.$i18n.locale,
          });
        } else {
          // Cas du profil de l'utilisateur connecté
          await this.fetchUserDuaa(this.$i18n.locale);
        }
        return true;
      }, forceRefresh);
    }
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.withLoading(this.loadDuaas());
      }
    } catch (error) {
      console.error("Error fetching user duaas:", error);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.loadDuaas(true); // Force reload on locale change
    },
    userId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadDuaas(true); // Force reload on user change
      }
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 30px 0 20px 0;
}

.container-duaa {
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px auto;
  gap: 20px;
  position: relative;
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  min-height: 150px;
}

.loading {
  text-align: center;
  margin: 20px 0;
}
</style>
