<template>
  <div>
    <h2 id="scroll-target">{{ $t("surahsCompTitle") }}</h2>
    <div class="box-sourates-fav" v-if="!localLoading">
      <CardCoran
        v-for="sourate in userSourates"
        :key="sourate?.id"
        :Titre="sourate?.attributes?.Titre"
        :Numero="sourate?.attributes?.Numero"
        :Arabe="sourate?.attributes?.Arabe"
        :Phonetique="sourate?.attributes?.Phonetique"
        :Revelation="sourate?.attributes?.Revelation"
        :Nombre="sourate?.attributes?.Nombre"
        :like="sourate?.attributes?.like_sourate?.data?.attributes?.likes"
        :fav="isLiked(sourate?.attributes.Numero)"
        :vues="sourate?.attributes?.vues"
        :taux="
          sourate?.attributes?.versets_checks?.data[0]?.attributes?.taux || 0
        "
        @likeChanged="onLikeChanged"
      />
    </div>
    <div v-else class="loading">
      {{ $t("loading") || "Chargement des sourates..." }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCoran from "../../components/coran/CardCoran.vue";
import DataLoadingMixin from "../../mixins/DataLoadingMixin";

export default {
  name: "sourates-profil",
  components: {
    CardCoran,
  },
  mixins: [DataLoadingMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("sourate", ["userSourates"]),
    ...mapGetters("like", ["likes"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("user", ["userId"]),
    likedSourates() {
      if (!this.likes || !this.likes.data || !Array.isArray(this.likes.data)) {
        return [];
      }
      return this.likes.data
        .filter(like => like && like.attributes && like.attributes.id_sourate)
        .map(like => like.attributes.id_sourate);
    },
  },
  methods: {
    ...mapActions("sourate", ["souratesUser", "fetchUserSourates"]),
    ...mapActions("like", ["fetchLikes"]),
    isLiked(numero) {
      if (!this.likedSourates || !Array.isArray(this.likedSourates) || !numero) {
        return false;
      }
      return this.likedSourates.includes(String(numero));
    },
    async onLikeChanged() {
      try {
        // Invalider le cache des likes pour forcer une mise à jour
        this.invalidateCache(`likes-${this.userId || 'self'}`);
        this.invalidateCache(`userSourates-${this.userId || 'self'}`);
        await this.loadData(true);
      } catch (error) {
        console.error(error);
      }
    },
    async loadData(forceRefresh = false) {
      this.localLoading = true;
      try {
        await this.loadInParallel([
          () => this.fetchSourates(forceRefresh),
          () => this.fetchUserLikes(forceRefresh)
        ]);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
      } finally {
        this.localLoading = false;
      }
    },
    async fetchSourates(forceRefresh = false) {
      const cacheKey = `userSourates-${this.userId || 'self'}`;
      return this.getWithCache(cacheKey, async () => {
        await this.fetchUserSourates(this.userId);
        return true;
      }, forceRefresh);
    },
    async fetchUserLikes(forceRefresh = false) {
      const cacheKey = `likes-${this.userId || 'self'}`;
      return this.getWithCache(cacheKey, async () => {
        await this.fetchLikes({ userId: this.userId });
        return true;
      }, forceRefresh);
    }
  },
  async mounted() {
    await this.withLoading(this.loadData());
  },
  watch: {
    userId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData(true); // Force refresh on user change
        }
      }
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 30px 0 20px 0;
}

.box-sourates-fav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(300px - 15px), 1fr));
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}

.loading {
  text-align: center;
  margin: 20px 0;
}
</style>
