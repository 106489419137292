<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="filter-buttons">
            <span
              :class="{ active: currentFilter === 'recent' }"
              @click="setFilter('recent')"
            >
              {{ $t("recent") }}
            </span>
            <span
              :class="{ active: currentFilter === 'images' }"
              @click="setFilter('images')"
            >
              {{ $t("medias") }}
            </span>
            <span
              :class="{ active: currentFilter === 'liked' }"
              @click="setFilter('liked')"
            >
              {{ $t("likes") }}
            </span>
            <span
              :class="{ active: currentFilter === 'commented' }"
              @click="setFilter('commented')"
            >
              {{ $t("comments") }}
            </span>
          </div>
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>
              <div v-else>
                <div class="lists-posts">
                  <div class="box-posts">
                    <div
                      v-for="item in sortedContent"
                      :key="item?.IdPost || item?.IdComment"
                    >
                      <CardFiiyd
                        v-if="!item?.isComment"
                        :avatar="item?.avatar"
                        :username="item?.username"
                        :time="item?.time"
                        :content="item?.Content"
                        :vues="item?.vues"
                        :likes="item?.likes"
                        :images="item?.images"
                        :liens="item?.liens"
                        :url="item?.url"
                        :IdPost="item?.IdPost"
                        :favoris="getFilteredFav(item)"
                        :comments="item?.comments"
                        :idUsername="item?.idUsername"
                        @addFavorite="addFavorite(item)"
                        @delete-post="deletePost(item?.IdPost)"
                      />
                      <CardFiiydComment
                        v-if="item?.isComment"
                        :username="item?.username"
                        :avatar="item?.avatar"
                        :time="item?.time"
                        :content="item?.Content"
                        :images="item?.images"
                        :liens="item?.liens"
                        :vues="item?.vues"
                        :likes="item?.likes"
                        :comments="item?.comments"
                        :favoris="getFilteredFavForComment(item)"
                        :url="item?.url"
                        :IdComment="item?.IdComment"
                        @addFavorite="addFavorite"
                        @comment-deleted="handleCommentDeleted"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import CardFiiyd from "../../components/fiiyd/CardFiiyd.vue";
import CardFiiydComment from "../../components/fiiyd/CardFiiComment.vue";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-tags",
  components: {
    HomeFii,
    BellFii,
    Trends,
    CardFiiyd,
    CardFiiydComment,
    Loading,
    TabsFiiyd,
    PostsFii,
    Head,
  },
  data() {
    return {
      isLoading: true,
      mixedContent: [], // Nouvelle propriété pour le contenu mixte
      slug: this.$route.params.slug, // Initialiser slug à partir des paramètres de la route
      currentFilter: "recent", // Filtre par défaut
    };
  },
  computed: {
    ...mapState("fiiyd", ["taggedContent"]), // Accédez à taggedContent du store
    ...mapState("user", ["user"]),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length; // Accès direct à la longueur
    },
    sortedContent() {
      let sorted = [...this.mixedContent];
      switch (this.currentFilter) {
        case "liked":
          sorted.sort((a, b) => b.likes - a.likes);
          break;
        case "images":
          sorted.sort((a, b) => b.images.length - a.images.length);
          break;
        case "commented":
          sorted.sort((a, b) => b.comments - a.comments);
          break;
        case "recent":
        default:
          sorted.sort(
            (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
          );
          break;
      }
      return sorted;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("fiiyd", ["fetchTaggedContent"]), // Ajouter l'action pour récupérer le contenu taggé

    setFilter(filter) {
      this.currentFilter = filter;
    },

    getFilteredFav(post) {
      if (post && Array.isArray(post.fav)) {
        const userFav = post.fav.find((favItem) => favItem.id === this.user.id);
        return userFav ? userFav.id : null;
      }
      return null;
    },

    getFilteredFavForComment(comment) {
      if (comment && Array.isArray(comment.fav)) {
        const userFav = comment.fav.find(
          (favItem) => favItem.id === this.user.id
        );
        return userFav ? userFav.id : null;
      }
      return null;
    },

    async fetchContent(slug) {
      this.isLoading = true; // Afficher le loader
      try {
        await this.fetchTaggedContent(slug);
        const posts = this.taggedContent.filter((item) => !item.isComment);
        const comments = this.taggedContent.filter((item) => item.isComment);

        // Fusionner les posts et les commentaires
        this.mixedContent = [...posts, ...comments]
          .sort((a, b) => new Date(b.time) - new Date(a.time))
          .reverse(); // Trier par date de publication
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du contenu taggé:",
          error
        );
      } finally {
        this.isLoading = false; // Masquer le loader
      }
    },

    async addFavorite() {
      try {
        await this.fetchTaggedContent(this.slug);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    handleCommentDeleted(deletedId) {
      this.mixedContent = this.mixedContent.filter(
        (item) => item.IdComment !== deletedId
      );
    },

    async deletePost(postId) {
      if (!postId) {
        console.error("Invalid postId:", postId);
        return;
      }
      try {
        await this.$store.dispatch("fiiyd/deletePost", postId);
        // Retirer le post de mixedContent sans recharger tous les posts
        this.mixedContent = this.mixedContent.filter(
          (item) => item.IdPost !== postId
        );
      } catch (error) {
        console.error("Erreur lors de la suppression d'un post:", error);
      }
    },
  },
  async mounted() {
    const slug = this.$route.params.slug; // Assurez-vous que le paramètre est correctement récupéré
    await this.fetchContent(slug); // Appel de la méthode fetchContent
  },

  watch: {
    "$route.params.slug": {
      handler(newSlug) {
        this.slug = newSlug; // Mettre à jour la variable slug
        this.fetchContent(newSlug); // Relancer la récupération du contenu
      },
      immediate: true, // Exécuter immédiatement lors du premier rendu
    },
  },
};
</script>

<style scoped>
.loading {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.filter-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0 0 0 20px;
  border-bottom: 1px solid var(--card);
}

.filter-buttons span {
  border: none;
  padding: 10px 20px 10px 0;
  cursor: pointer;
  font-size: 12px;
  transition: color 0.3s;
  margin-bottom: -1.5px;
}

.filter-buttons span:hover {
  color: var(--button);
}

.filter-buttons span.active {
  color: color;
  font-weight: 600;
  border-bottom: 2px solid var(--button);
}

.boites {
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  min-height: 100vh;
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.post-input {
  padding: 0 20px;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}

@media (max-width: 998px) {
  .boites {
    margin-top: 100px;
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .boites {
    margin-top: 100px;
    padding: 0;
  }
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .post-input {
    padding: 0;
  }
  .tabs {
    display: block;
  }
}
</style>
