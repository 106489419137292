import axios from "axios";

const state = {
  courses: [],
  coursesChapters: null,
  cours: null,
  lastCourses: [],
  coursesUser: [],
  idSuivi: null,
  progressId: null,
};

const getters = {
  courses: (state) => state.courses,
  lastCourses: (state) => state.lastCourses,
  coursesChapters: (state) => state.coursesChapters,
  cours: (state) => state.cours,
  userCourses: (state) => state.coursesUser,
  idSuivi: (state) => state.idSuivi,
  progressId: (state) => state.progressId,
};

const mutations = {
  SET_COURSES(state, courses) {
    state.courses = courses;
  },
  SET_LAST_COURSES(state, lastCourses) {
    state.lastCourses = lastCourses;
  },
  SET_COURSES_CHAPTERS(state, coursesChapters) {
    state.coursesChapters = coursesChapters;
  },
  SET_COURSES_USER(state, coursesUser) {
    state.coursesUser = coursesUser;
  },
  SET_COURS(state, cours) {
    state.cours = cours;
  },
  RESET_SUIVI(state, idSuivi) {
    state.idSuivi = idSuivi;
  },
  UPDATE_SUIVI(state, progressId) {
    state.progressId = progressId;
  },
};

const actions = {
  async fetchCourses({ commit, rootGetters }, lang) {
    if (state.courses.length) {
      return;
    }
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/courses", {
        params: {
          locale: lang,
          fields: [
            "id",
            "titre",
            "niveau",
            "points",
            "gain",
            "url",
            "Category",
            "publishedAt",
          ],
          populate: {
            Couverture: { fields: ["formats"] },
            suivis: {
              fields: ["id", "user_id", "pourcentage"],
              filters: {
                user_id: { $eq: userId },
              },
            },
          },
        },
      });

      const courses = response.data.data;

      commit("SET_COURSES", courses);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchLastCourses({ commit, rootGetters }, lang) {
    if (state.courses.length) {
      return;
    }
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/courses", {
        params: {
          locale: lang,
          fields: [
            "id",
            "titre",
            "niveau",
            "points",
            "gain",
            "url",
            "Category",
            "publishedAt",
          ],
          populate: {
            Couverture: { fields: ["formats"] },
            suivis: {
              fields: ["id", "user_id", "pourcentage"],
              filters: {
                user_id: { $eq: userId },
              },
            },
          },
        },
      });

      const courses = response.data.data;

      // Trier et sélectionner les 4 derniers cours
      const sortedCourses = courses.sort((a, b) => {
        return (
          new Date(b.attributes.publishedAt) -
          new Date(a.attributes.publishedAt)
        );
      });

      const lastFourCourses = sortedCourses.slice(0, 4);

      commit("SET_LAST_COURSES", lastFourCourses);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCoursesAndChapters({ commit, rootGetters }, { slug, lang }) {
    const userId = rootGetters["user/userId"];
    try {
      const response = await axios.get("/courses", {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: slug,
            },
          },
          fields: ["id", "titre", "gain"],
          populate: {
            chapitres: {
              fields: ["id", "titre", "numero", "course"],
              locale: lang,
              populate: {
                contenus: {
                  locale: lang,
                  fields: ["id", "titre", "cours", "audio"],
                  populate: {
                    quizzes: {
                      locale: lang,
                      fields: [
                        "id",
                        "Question",
                        "Instruction",
                        "A",
                        "B",
                        "C",
                        "D",
                        "reponse",
                      ],
                    },
                  },
                },
              },
            },
            suivis: {
              fields: [
                "id",
                "user_id",
                "id_chapitre",
                "pourcentage",
                "clicks",
                "id_cours",
                "id_contenu",
                "id_quiz",
                "elements",
                "quiz_active",
                "content_active",
                "termine",
                "indexError",
              ],
              filters: {
                user_id: { $eq: userId },
              },
            },
          },
        },
      });

      // Supposant que response.data.data est un tableau avec un seul élément
      commit("SET_COURSES_CHAPTERS", response.data.data[0]);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des cours et chapitres :",
        error
      );
      commit("SET_COURSES_CHAPTERS", null);
    }
  },
  async createCourseProgress({ rootGetters }, payload) {
    // console.log("URL:", payload.url);
    // console.log("idCourse:", payload.idCourse);

    const userId = rootGetters["user/userId"];
    try {
      await axios.post("/suivis", {
        data: {
          id_chapitre: "1",
          course: Number(payload.idCourse),
          id_cours: String(payload.idCourse),
          user_id: String(userId),
          id_contenu: "0",
          pourcentage: "0",
          id_quiz: "0",
          clicks: "0",
          quiz_active: false,
          content_active: true,
          slug: payload.url,
        },
      });
    } catch (error) {
      console.error("Erreur lors de la création du suivi du cours:", error);
    }
  },
  async restartCourseProgress({ commit }, id) {
    // console.log("id Suivi:", id);
    try {
      const response = await axios.put(`/suivis/${id}`, {
        data: {
          id_chapitre: "1",
          id_contenu: "0",
          pourcentage: "0",
          id_quiz: "0",
          clicks: "0",
          quiz_active: false,
          content_active: true,
        },
      });
      // console.log("Suivi mise à jour avec succès !");
      commit("RESET_SUIVI", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du suivi du cours:",
        error.response ? error.response.data : error.message
      );
    }
  },
  async updateCourseProgress({ commit }, payload) {
    try {
      const response = await axios.put(`/suivis/${payload.id}`, {
        data: {
          id_chapitre: payload.id_chapitre,
          id_contenu: payload.id_contenu,
          pourcentage: payload.pourcentage,
          id_quiz: payload.id_quiz,
          indexError: payload.indexError,
          clicks: payload.clicks,
          elements: payload.elements,
          quiz_active: payload.quiz_active,
          content_active: payload.content_active,
          termine: payload.termine,
        },
      });
      // console.log("Suivi mise à jour avec succès !");
      commit("UPDATE_SUIVI", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du suivi du cours:",
        error.response ? error.response.data : error.message
      );
    }
  },

  async fetchUniqueCours({ commit, rootGetters }, { slug, lang }) {
    const userId = rootGetters["user/userId"];
    try {
      const response = await axios.get(`/courses`, {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: slug,
            },
          },
          fields: [
            "id",
            "titre",
            "url",
            "niveau",
            "Category",
            "gain",
            "description",
          ],
          populate: {
            locale: lang,
            Couverture: { fields: ["formats"] },
            chapitres: { fields: ["titre", "numero"], locale: lang },
            suivis: {
              fields: ["id", "user_id", "id_chapitre", "pourcentage"],
              filters: {
                user_id: { $eq: userId },
              },
            },
          },
        },
      });

      // Supposant que response.data.data est un tableau avec un seul élément
      commit("SET_COURS", response.data.data[0]);
    } catch (error) {
      console.error(error);
      commit("SET_COURS", null);
    }
  },

  async coursUser({ commit, rootGetters }, { userId, lang } = {}) {
    try {
      // Utiliser l'ID fourni ou l'ID de l'utilisateur connecté si non fourni
      const userIdToUse = userId || rootGetters["user/userId"];
      const response = await axios.get("/courses", {
        params: {
          locale: lang,
          filters: {
            suivis: {
              user_id: { $eq: userIdToUse },
            },
          },
          fields: [
            "id",
            "titre",
            "niveau",
            "points",
            "gain",
            "url",
            "Category",
            "publishedAt",
          ],
          populate: {
            Couverture: { fields: ["formats"] },
            suivis: {
              fields: ["id", "user_id", "pourcentage"],
              filters: {
                user_id: { $eq: userIdToUse },
              },
            },
          },
        },
      });

      commit("SET_COURSES_USER", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
