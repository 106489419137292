/**
 * Mixin pour gérer le chargement des données et émettre un événement une fois terminé
 * Utilisé dans les composants du profil utilisateur
 */
export default {
  data() {
    return {
      localLoading: true,
      dataCache: {},
      cacheTTL: 5 * 60 * 1000, // 5 minutes en millisecondes
    };
  },
  methods: {
    /**
     * Émet l'événement data-loaded pour informer le composant parent que les données sont chargées
     */
    emitDataLoaded() {
      console.log('Émission de l\'événement data-loaded depuis', this.$options.name || 'un composant');
      this.$emit('data-loaded');
    },
    
    /**
     * Enveloppe une promesse et émet l'événement data-loaded une fois la promesse résolue
     * @param {Promise} promise - La promesse à attendre
     * @returns {Promise} - La promesse d'origine
     */
    async withLoading(promise) {
      try {
        console.log('withLoading: début du chargement');
        const result = await promise;
        console.log('withLoading: chargement terminé avec succès');
        this.emitDataLoaded();
        return result;
      } catch (error) {
        console.error('Error in withLoading:', error);
        this.emitDataLoaded(); // Émettre même en cas d'erreur pour éviter un état de chargement infini
        throw error;
      }
    },

    /**
     * Récupère les données depuis le cache ou exécute la fonction de récupération
     * @param {string} cacheKey - Clé unique pour identifier les données en cache
     * @param {Function} fetchFunction - Fonction qui retourne une promesse pour récupérer les données
     * @param {boolean} forceRefresh - Si true, ignore le cache et force le rechargement
     * @returns {Promise} - Promesse résultant en données
     */
    async getWithCache(cacheKey, fetchFunction, forceRefresh = false) {
      // Vérifier si les données sont en cache et toujours valides
      const cachedData = this.dataCache[cacheKey];
      const now = Date.now();
      
      if (!forceRefresh && cachedData && cachedData.timestamp && (now - cachedData.timestamp < this.cacheTTL)) {
        console.log(`Utilisation des données en cache pour ${cacheKey}`);
        return cachedData.data;
      }
      
      // Récupérer les données et les mettre en cache
      try {
        console.log(`Récupération des données pour ${cacheKey}`);
        const data = await fetchFunction();
        this.dataCache[cacheKey] = {
          data,
          timestamp: Date.now()
        };
        return data;
      } catch (error) {
        console.error(`Erreur lors de la récupération des données pour ${cacheKey}:`, error);
        throw error;
      }
    },
    
    /**
     * Charge plusieurs ressources en parallèle et émet l'événement data-loaded une fois terminé
     * @param {Array} promiseFunctions - Tableau de fonctions retournant des promesses
     * @returns {Promise} - Promesse résultant en un tableau de résultats
     */
    async loadInParallel(promiseFunctions) {
      this.localLoading = true;
      try {
        const results = await Promise.all(promiseFunctions.map(fn => fn()));
        this.localLoading = false;
        this.emitDataLoaded();
        return results;
      } catch (error) {
        console.error('Error in loadInParallel:', error);
        this.localLoading = false;
        this.emitDataLoaded();
        throw error;
      }
    },

    /**
     * Invalide le cache pour une clé spécifique ou toutes les clés
     * @param {string} cacheKey - Clé à invalider, si non fournie, tout le cache est invalidé
     */
    invalidateCache(cacheKey = null) {
      if (cacheKey) {
        delete this.dataCache[cacheKey];
        console.log(`Cache invalidé pour ${cacheKey}`);
      } else {
        this.dataCache = {};
        console.log('Cache entièrement invalidé');
      }
    }
  }
};
