// store/modules/user.js
import axios from "axios";

const state = {
  user: null,
  profileUser: null, // Ajout d'un état pour stocker les données d'un autre utilisateur
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_PROFILE_USER(state, user) {
    state.profileUser = user;
  },
};

const actions = {
  async fetchUser({ commit }) {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in localStorage");
        return false;
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.get("/users/me", {
        params: {
          fields: ["id", "username", "email"],
          populate: ["hassanate", "avatar", "ayahs", "livres", "douas"],
        },
      });

      if (response.data && response.data.id) {
        commit("SET_USER", response.data);
        return true;
      } else {
        console.error("No user data found");
        return false;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return false;
    }
  },

  async fetchUserById({ commit }, userId) {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in localStorage");
        return false;
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.get(`/users/${userId}`, {
        params: {
          fields: ["id", "username", "email"],
          populate: ["hassanate", "avatar", "ayahs", "livres", "douas"],
        },
      });

      if (response.data && response.data.id) {
        commit("SET_PROFILE_USER", response.data);
        return true;
      } else {
        console.error("No user data found for ID:", userId);
        return false;
      }
    } catch (error) {
      console.error("Error fetching user data by ID:", error);
      return false;
    }
  },

  async addDouaToFavorites({ dispatch, state }, douaId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        douas: {
          connect: [douaId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error adding dua to favorites:", error);
    }
  },

  async removeDouaFromFavorites({ dispatch, state }, douaId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        douas: {
          disconnect: [douaId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error removing dua from favorites:", error);
    }
  },

  async addBookToFavorites({ dispatch, state }, bookId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        livres: {
          connect: [bookId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error adding book to favorites:", error);
    }
  },

  async removeBookFromFavorites({ dispatch, state }, bookId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        livres: {
          disconnect: [bookId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error removing book from favorites:", error);
    }
  },

  async addVerseToFavorites({ dispatch, state }, verseId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        ayahs: {
          connect: [verseId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error adding verse to favorites:", error);
    }
  },

  async removeVerseFromFavorites({ dispatch, state }, verseId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        ayahs: {
          disconnect: [verseId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error removing verse from favorites:", error);
    }
  },

  async addPostToFavorites({ dispatch, state }, postId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        favs_posts: {
          connect: [postId],
        },
      });

      const postResponse = await axios.get(`/posts/${postId}`, {
        params: {
          fields: ["id", "enable_notif"],
          populate: {
            users_permissions_user: {
              fields: ["id"],
            },
          },
        },
      });

      const postOwnerId =
        postResponse.data.data.attributes.users_permissions_user?.data?.id;
      if (
        postOwnerId !== state.user.id &&
        postResponse.data.data.attributes.enable_notif === true
      ) {
        await axios.post("/fiiyds", {
          data: {
            like: true,
            post: true,
            name_user: state.user.username,
            owner: postOwnerId,
            posts: postId,
          },
        });
      }

      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error adding post to favorites:", error);
    }
  },

  async removePostFromFavorites({ dispatch, state }, postId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        favs_posts: {
          disconnect: [postId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error removing post from favorites:", error);
    }
  },

  async addCommentToFavorites({ dispatch, state }, commentId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        comments_favs: {
          connect: [commentId],
        },
      });

      const commentResponse = await axios.get(`/comments/${commentId}`, {
        params: {
          fields: ["id", "enable_notif"],
          populate: {
            users_permissions_user: {
              fields: ["id"],
            },
          },
        },
      });

      const commentOwnerId =
        commentResponse.data.data.attributes.users_permissions_user?.data?.id;
      if (
        commentOwnerId !== state.user.id &&
        commentResponse.data.data.attributes.enable_notif === true
      ) {
        await axios.post("/fiiyds", {
          data: {
            like: true,
            commentaire: true,
            name_user: state.user.username,
            owner: commentOwnerId,
            comment: commentId,
          },
        });
      }

      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error adding comment to favorites:", error);
    }
  },

  async removeCommentFromFavorites({ dispatch, state }, commentId) {
    try {
      const userId = state.user.id;
      await axios.put(`/users/${userId}`, {
        comments_favs: {
          disconnect: [commentId],
        },
      });
      await dispatch("fetchUser"); // Mettre à jour les données de l'utilisateur
    } catch (error) {
      console.error("Error removing post from favorites:", error);
    }
  },
};

const getters = {
  user(state) {
    return state.user;
  },
  userId(state) {
    return state.user?.id;
  },
  username(state) {
    return state.user?.username;
  },
  isAuthenticated(state) {
    return !!state.user;
  },
  profileUser(state) {
    return state.profileUser;
  },
  isProfileOwner(state) {
    return state.user && state.profileUser && state.user.id === state.profileUser.id;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
