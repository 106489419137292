<template>
  <div>
    <h2 id="scroll-target">{{ $t("edit") }}</h2>
    <div class="edit-container">
      <div class="box-edit">
        <p class="alert-g">
          {{ $t("alertMessage") }}
        </p>
        <div class="c-info-u">
          <form @submit.prevent="updateUserProfile">
            <label for="username">{{ $t("usernameLabel") }}</label>
            <span
              v-if="usernameError && !usernameVoidError && username.length < 3"
              class="error-message"
              >{{ $t("usernameErrorShort") }}</span
            >
            <span
              v-if="usernameError && username.length > 20"
              class="error-message"
              >{{ $t("usernameErrorLong") }}</span
            >
            <span v-if="usernameVoidError" class="error-message">{{
              $t("usernameVoidError")
            }}</span>
            <input
              type="text"
              v-model="username"
              :class="{ 'input-error': usernameVoidError || usernameError }"
              :placeholder="$t('usernameLabel')"
              name="username"
              @input="checkUsernameField"
            />

            <label for="email">{{ $t("emailLabel") }}</label>
            <input
              type="text"
              v-model="email"
              :placeholder="$t('emailLabel')"
              class=""
              name="email"
              readonly
            />

            <label for="password">{{ $t("passwordLabel") }}</label>
            <span
              v-if="password.length <= 0 && newpassword.length > 0"
              class="error-message"
              >{{ $t("passwordRequiredError") }}</span
            >
            <span v-if="incorrectPasswordError" class="error-message">{{
              $t("incorrectPasswordError")
            }}</span>
            <div
              class="psw"
              :class="{
                'input-error': passwordError || incorrectPasswordError,
                focused: passwordFocused,
              }"
            >
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                :placeholder="$t('passwordLabel')"
                name="password"
                @input="debounceVerifyPassword"
                @focus="setFocus('password', true)"
                @blur="setFocus('password', false)"
              />
              <span class="toggle-password" @click="togglePasswordVisibility">
                <openEye v-if="showPassword" />
                <closeEye v-if="!showPassword" />
              </span>
            </div>
            <label for="newpassword">{{ $t("newPasswordLabel") }}</label>
            <span
              v-if="
                password.length > 8 &&
                newPasswordLengthError &&
                incorrectPasswordError == false
              "
              class="error-message"
              >{{ $t("newPasswordShortError") }}</span
            >
            <span
              v-if="newPasswordWeakError && newpassword.length > 3"
              class="error-message"
              >{{ $t("newPasswordWeakError") }}</span
            >
            <div
              class="psw"
              :class="{
                'input-error':
                  (password.length > 0 &&
                    newpassword.length > 0 &&
                    newPasswordLengthError) ||
                  newPasswordWeakError,
                focused: newPasswordFocused,
              }"
            >
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="newpassword"
                :placeholder="$t('newPasswordLabel')"
                name="newpassword"
                @input="checkPasswordField"
                @focus="setFocus('newpassword', true)"
                @blur="setFocus('newpassword', false)"
              />
              <span class="toggle-password" @click="togglePasswordVisibility">
                <openEye v-if="showPassword" />
                <closeEye v-if="!showPassword" />
              </span>
            </div>
            <label for="confirmpassword">{{
              $t("confirmPasswordLabel")
            }}</label>
            <span
              v-if="
                password.length > 0 &&
                passwordMismatchError &&
                newpassword.length > 0
              "
              class="error-message"
              >{{ $t("passwordMismatchError") }}</span
            >
            <div
              class="psw"
              :class="{
                'input-error': password.length > 0 && passwordMismatchError,
                focused: confirmPasswordFocused,
              }"
            >
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="confirmpassword"
                :placeholder="$t('confirmPasswordLabel')"
                name="confirmpassword"
                @input="checkPasswordField"
                @focus="setFocus('confirmpassword', true)"
                @blur="setFocus('confirmpassword', false)"
              />
              <span class="toggle-password" @click="togglePasswordVisibility">
                <openEye v-if="showPassword" />
                <closeEye v-if="!showPassword" />
              </span>
            </div>
            <span v-if="success" class="success-message">{{
              $t("successMessageEdit")
            }}</span>
            <button type="submit" v-if="isSubmitButtonEnabled">
              {{ $t("saveButton") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import openEye from "../icons/openEye.vue";
import closeEye from "../icons/closeEye.vue";
import axios from "axios";
export default {
  name: "edit-user",
  components: {
    openEye,
    closeEye,
  },
  data() {
    return {
      userData: null,
      username: "",
      email: "",
      password: "",
      newpassword: "",
      confirmpassword: "",
      usernameError: false,
      usernameVoidError: false,
      passwordError: false,
      incorrectPasswordError: false,
      newPasswordLengthError: false,
      newPasswordWeakError: false,
      passwordMismatchError: false,
      passwordValid: false,
      passwordIsValid: null,
      success: false,
      showPassword: false,
      passwordFocused: false,
      newPasswordFocused: false,
      confirmPasswordFocused: false,
      debounceTimer: null,
    };
  },
  async mounted() {
    await this.fetchUser();
  },
  computed: {
    ...mapState("user", ["user"]),
    // ...mapGetters("sub", ["isSubscribed"]),
    // hasSubscription() {
    //   return this.isSubscribed;
    // },
    isFormValid() {
      return this.username && (this.password || !this.newpassword);
    },
    isSubmitButtonEnabled() {
      if (!this.userData) {
        return false;
      }

      const isUsernameChanged = this.username !== this.user.username;
      const isPasswordFieldsFilled =
        this.password && this.newpassword && this.confirmpassword;

      return (
        isUsernameChanged ||
        (isPasswordFieldsFilled &&
          !this.incorrectPasswordError &&
          !this.newPasswordLengthError &&
          !this.newPasswordWeakError &&
          !this.passwordMismatchError)
      );
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    async fetchUser() {
      try {
        if (this.user) {
          this.userData = this.user;
          this.username = this.userData.username;
          this.email = this.userData.email;
        }
      } catch (error) {
        // Handle error
      }
    },

    setFocus(field, focused) {
      if (field === "password") {
        this.passwordFocused = focused;
      } else if (field === "newpassword") {
        this.newPasswordFocused = focused;
      } else if (field === "confirmpassword") {
        this.confirmPasswordFocused = focused;
      }
    },

    checkUsernameField() {
      this.usernameError =
        this.username.length < 3 || this.username.length > 20;
      this.usernameVoidError = this.username == "";
    },

    verifyPassword() {
      if (this.password.length < 8) {
        this.incorrectPasswordError = false;
        return;
      }

      axios
        .post("/auth/local", {
          identifier: this.userData.email, // Use old email here
          password: this.password,
        })
        .then(() => {
          this.incorrectPasswordError = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.incorrectPasswordError = true;
          } else {
            // Handle error
          }
        });
    },

    debounceVerifyPassword() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.verifyPassword();
      }, 600);
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    checkPasswordField() {
      this.newPasswordLengthError = this.newpassword.length < 8;
      this.newPasswordWeakError =
        this.newpassword.length > 8 && !this.isStrongPassword(this.newpassword);
      this.passwordMismatchError = this.newpassword !== this.confirmpassword;
    },

    isStrongPassword(password) {
      if (password.length < 8) {
        return false;
      }

      if (!/[A-Z]/.test(password)) {
        return false;
      }

      if (!/[a-z]/.test(password)) {
        return false;
      }

      if (!/[0-9]/.test(password)) {
        return false;
      }

      return true;
    },

    async updateUserProfile() {
      try {
        const isUsernameChanged =
          this.username !== this.userData.username && this.password.length <= 0;
        if (isUsernameChanged) {
          const userProfileData = {
            username: this.username,
          };

          await axios.put(`/users/${this.userData.id}`, userProfileData);
          this.success = true;
        } else {
          const userProfileData = {
            username: this.username,
          };

          if (
            this.password.length >= 8 &&
            this.incorrectPasswordError == false &&
            this.newPasswordWeakError == false
          ) {
            if (this.newpassword) {
              if (this.newpassword !== this.confirmpassword) {
                return;
              }

              const changePasswordResponse = await axios.post(
                "/auth/change-password",
                {
                  currentPassword: this.password,
                  password: this.newpassword,
                  passwordConfirmation: this.confirmpassword,
                }
              );

              if (changePasswordResponse.status === 200) {
                this.success = true;
              } else {
                console.error("Error changing password");
                return;
              }
            }
          } else {
            return;
          }

          await axios.put(`/users/${this.userData.id}`, userProfileData);

          this.userData.username = userProfileData;

          this.success = true;
        }
      } catch (error) {
        // Handle error
      }
    },
  },
  watch: {
    password(newValue) {
      if (newValue.length >= 8) {
        this.debounceVerifyPassword();
      }
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 30px 0 20px 0;
}

.edit-container {
  display: block;
  max-width: 600px;
  width: 100%;
  background: var(--card);
  margin: 0 auto;
  border-radius: 6px;
}

.box-edit {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  padding: 60px 30px;
  max-width: 400px;
}

@media (max-width: 800px) {
  .box-edit {
    margin-top: 30px;
  }
}

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-info-u form {
  display: flex;
  flex-direction: column;
}

.c-info-u form label {
  font-size: 12px;
  margin-bottom: 5px;
}

.psw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  outline: 1px solid var(--color);
  padding-right: 10px;
  margin-bottom: 15px;
  /* background: var(--body); */
}

.psw input {
  width: 100%;
  margin-bottom: 0 !important;
  outline: none !important;
}

.psw.focused {
  outline: 1px solid var(--button);
}

.psw span {
  cursor: pointer;
  width: auto;
}

.c-info-u form input {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  outline: 1px solid var(--color);
  border: none;
  background: transparent;
  color: var(--text);
  font-size: 14px;
  /* background: var(--body); */
}

.c-info-u form input:focus {
  outline: 1px solid var(--button);
  color: var(--button);
}

.c-info-u form input::placeholder {
  font-size: 14px;
}

.c-info-u form button {
  display: block;
  cursor: pointer;
  margin-left: auto;
  max-width: 140px;
  padding: 10px 20px;
  background: var(--color);
  color: var(--colorBtn);
  border-radius: 5px;
  border: none;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
}

.error-message {
  color: red;
}

.success-message {
  color: rgb(52, 205, 1);
}

.input-error {
  outline: 1px solid red !important;
}

.input-error::placeholder {
  color: red;
}

.alert-g {
  background: #ffecd9;
  color: #000;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.theme {
  text-align: center;
  margin-top: 40px;
}

.links {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .c-selected img {
    width: 75px;
    height: 75px;
  }

  .carousel-item img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 600px) {
  .carousel-container {
    justify-content: space-around;
  }

  .carousel-item img {
    width: 75px;
    height: 75px;
  }
}
</style>
