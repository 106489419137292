<template>
  <div class="container-top"></div>
  <div class="container">
    <div class="box-top">
      <el-popover
        v-if="isProfileOwner"
        :width="250"
        trigger="click"
        popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
      >
        <template #reference>
          <button class="menu">
            <Points />
          </button>
        </template>
        <template #default>
          <div
            class="demo-rich-conent"
            style="display: flex; gap: 16px; flex-direction: column"
          >
            <ul class="options-p">
              <li @click="emitComponentChange('Parametres')">
                {{ $t("settingsP") }}
              </li>
              <li @click="emitComponentChange('Edit')">{{ $t("editP") }}</li>
              <li @click="emitComponentChange('Testimonials')">
                <span class="loader" v-if="!myTestimonials"></span>
                {{ $t("addTestimonial") }}
              </li>
              <li @click="emitComponentChange('Themes')">
                {{ $t("editTheme") }}
              </li>
              <!-- <li @click="emitComponentChange('Reseaux')">{{ $t('') }}</li> -->
              <li @click="emitComponentChange('Abonnement')">
                <span class="loader" v-if="!isSubscribed"></span>
                {{ $t("mySub") }}
              </li>
              <li @click="logout()">{{ $t("disconnect") }}</li>
            </ul>
          </div>
        </template>
      </el-popover>
      <div class="bottom">
        <div class="avatar">
          <img
            :src="`https://cp.doonun.com${
              avatarUrl || '/uploads/small_plant_f9f00dabd7.png'
            }`"
            alt=""
          />
          <div class="edit-avatar" @click="emitComponentChange('Avatar')" v-if="isProfileOwner">
            <Plus />
          </div>
        </div>
        <div class="right">
          <div class="infos">
            <p class="username">
              {{ displayedUser.username }}
            </p>
            <div class="points">
              <span
                >{{ formatPoints(displayedUser.hassanate?.points) || 0 }}
                {{ $t("points") }}</span
              >
            </div>
          </div>
          <div class="complete">
            <div class="item">
              <p><Medal /> {{ $t("rankP") }}</p>
              <span>{{ displayedUser.hassanate?.rang }}</span>
            </div>
            <div class="item">
              <p><Courses /> {{ $t("coursesP") }}</p>
              <span>{{ displayedUser.hassanate?.cours || 0 }}</span>
            </div>
            <div class="item">
              <p><Quran /> {{ $t("surahP") }}</p>
              <span>{{ displayedUser.hassanate?.sourates || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Plus from "../icons/Plus.vue";
import Medal from "../icons/Medal.vue";
import Courses from "../icons/Courses.vue";
import Quran from "../icons/Quran.vue";
import Points from "../icons/Points.vue";
// import Parametres from "./Parametres.vue";
export default {
  name: "top-profil",
  components: {
    Plus,
    Medal,
    Courses,
    Quran,
    Points,
    // Parametres,
  },
  props: {
    userId: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("testimonials", ["myTestimonials"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapState("user", ["user", "profileUser"]),
    ...mapGetters("user", ["isProfileOwner"]),
    ...mapState("avatar", {
      avatarUrl: (state) => state.avatar,
    }),
    displayedUser() {
      return this.profileUser || this.user || {};
    }
  },
  async created() {
    try {
      // Si on a un userId en prop, on charge cet utilisateur spécifique
      if (this.userId) {
        await this.fetchUserById(this.userId);
      } else {
        // Sinon, on charge l'utilisateur connecté
        await this.fetchUser();
      }
      
      // Récupérer l'avatar de l'utilisateur affiché
      const userToDisplay = this.displayedUser;
      if (userToDisplay && userToDisplay.avatar && userToDisplay.avatar.id) {
        const avatarId = userToDisplay.avatar.id;
        await this.fetchAvatar(avatarId);
      } else {
        console.error("User or user avatar ID not found");
      }
    } catch (error) {
      console.error("Error fetching user or avatar:", error);
    }
  },
  methods: {
    ...mapActions("user", ["fetchUser", "fetchUserById"]),
    ...mapActions("login", ["logout"]),
    ...mapActions("avatar", ["fetchAvatar"]),
    emitComponentChange(componentName) {
      this.$emit("componentChange", componentName);
    },
    formatPoints(points) {
      if (!points) return 0;
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },
  },
};
</script>

<style scoped>
.container-top {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  background: var(--button);

  z-index: 1;
}
.container {
  margin-top: -160px !important;
}
.box-top {
  position: relative;
  display: block;
  margin: 0 auto 20px auto;
  max-width: 600px;
  background: var(--card);
  border-radius: 6px;
  padding: 30px 20px 40px 20px;
  z-index: 2 !important;
}

.box-top::after {
  display: block;
  margin-left: auto;
  margin-right: auto;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: var(--card);
  opacity: 0.6;
  width: calc(100% - 20px);
  margin-bottom: -10px;
  z-index: -1 !important;
  border-radius: 0 0 6px 6px;
}

.bottom {
  display: flex;
  gap: 20px;
}

.avatar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  max-height: 60px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
}

.edit-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  width: 60px;
  height: 60px;
  background: #00a6ff;
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 3;
  margin-top: -15px;
}

.avatar img {
  object-fit: cover;
  min-height: 60px;
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  z-index: 2;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.infos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.username {
  font-size: 15px;
  font-weight: 700;
}

.bottom .points {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  min-width: 70px;
  max-width: 90px;
  padding: 2px 10px;
  background: var(--button);
  border-radius: 30px;
}

.bottom .points span {
  line-height: 0;
  color: #fff;
  font-size: 11px;
}

.complete {
  display: flex;
  align-items: center;
  gap: 30px;
}

.complete .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.complete p {
  font-size: 13px;
  font-weight: 400;
}

.complete span {
  font-size: 13px;
  font-weight: 700;
}

.menu {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 4;
  background: transparent;
  margin-top: 5px;
  margin-right: 5px;
  transition: 0.2s ease-in;
}

.menu:hover {
  background-color: var(--body);
}

ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 10px 5px;
  border-bottom: 1px solid var(--body);
  transition: 0.2s ease-in;
  color: var(--color);
}

ul li:hover {
  background-color: var(--body);
}

.loader {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #ffa500;
  animation: 1.5s pulse infinite ease-in-out;
  margin-left: -5px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #ffa500;
  }

  100% {
    box-shadow: 0 0 0 14px #69ffa800;
  }
}
</style>
