<!-- verset-doonun.vue -->
<template>
  <div class="box-verset" :id="numero" ref="cardContainer">
    <div class="top">
      <p class="num">{{ numero }}</p>
      <div class="tooltips">
        <div class="memorize" @click="openDrawer">
          {{ $t("memo") }}
        </div>
        <div class="icon" @click="openSharePopup">
          <Share />
        </div>
        <div class="icon">
          <router-link :to="`/video/${this.$route.params.id}/${numero}`">
            <VideoQuran />
          </router-link>
        </div>
        <div class="icon" v-if="isAuthenticated" @click="toggleFavoris">
          <MarkCheck v-if="isFavoris" />
          <Mark v-else />
        </div>
      </div>
    </div>
    <p class="arabe" v-if="options.arabic && !options.tajweed">
      <span
        class="word-arabic"
        v-for="(word, index) in arabe.split(' ')"
        :key="index"
        @click="playWordAudio(index)"
      >
        {{ word }}&nbsp;
      </span>
    </p>
    <p class="tajweed" v-if="options.tajweed" v-html="tajweed"></p>
    <p class="phonetic" v-if="options.phonetic">
      {{ phonetique }}
    </p>
    <p class="trad" v-if="options.lang">
      {{ fr }}
    </p>
    <span v-if="tafsir.length > 0">
      <p class="tafsir" v-if="options.tafsir">
        <span>Tafsir</span>
        {{ tafsir }}
      </p>
      <p class="tafsir" v-if="showTafsir && !options.tafsir">
        <span>Tafsir</span>
        {{ tafsir }}
      </p>
    </span>
    <div class="bottom">
      <div class="left">
        <div class="ar" @click="$emit('playAudioSolo', numero, 'ar')">
          <Play v-if="!playAr" />
          <Pause v-else />
          <img src="https://cp.doonun.com/uploads/ar_d5a49e3344.png" alt="" />
        </div>
        <div class="fr" @click="$emit('playAudioSolo', numero, $t('lang'))">
          <Play v-if="!playFr" />
          <Pause v-else />
          <img :src="$t('flag')" alt="" />
        </div>
        <div
          v-if="tafsir.length > 0"
          class="display-tafsir"
          @click="toggleTafsir"
        >
          <span>Tafsir</span>
        </div>
        <div class="kathir">
          <router-link :to="`/ibn-kathir/${this.$route.params.id}/${numero}`">{{
            $t("seekathir")
          }}</router-link>
        </div>
      </div>
      <div class="right">
        <label class="box-check" v-if="isAuthenticated">
          <input
            type="checkbox"
            v-model="isChecked"
            :disabled="isCheckboxDisabled(numero)"
            @change="handleCheckChange"
          />
          <svg viewBox="0 0 64 64" height="18px" width="18px">
            <path
              d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
              pathLength="575.0541381835938"
              class="path"
            ></path>
          </svg>
        </label>
      </div>
    </div>

    <MemoDrawer
      v-model="drawer"
      :reciter="reciter"
      :idsourate="idsourate"
      :numero="numero"
      :arabe="arabe"
      :phonetique="phonetique"
      :totalverses="Number(totalverses)"
      @memorizeNextVerse="handleMemorizeNextVerse"
    />

    <SharePopup
      :message="`${$t('surahM1')} ${numero} ${$t('surahM2')}  ${surah}.`"
      :pageUrl="computedPageUrl"
      ref="sharePopup"
    />
  </div>

  <Objectif v-if="showObjectif" @close="showObjectif = false" />
</template>

<script>
import valideSong from "../../assets/audios/quiz/correct.wav";
import { mapGetters, mapActions, mapState } from "vuex";
import confetti from "../../assets/js/confetti";
import Play from "../icons/Play.vue";
import Pause from "../icons/Pause.vue";
import Mark from "../icons/Mark.vue";
import MarkCheck from "../icons/MarkCheck.vue";
import Share from "../icons/Share.vue";
import VideoQuran from "../icons/VideoQuran.vue";
import SharePopup from "../global/Share.vue";
import MemoDrawer from "./MemoDrawer.vue";
import Objectif from "./Objectif.vue";

// Fonction utilitaire pour formater la date
function formatLocalKey(dateObj) {
  const yyyy = dateObj.getFullYear();
  const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
  const dd = String(dateObj.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`; // ex: "2025-01-14"
}

export default {
  name: "verset-doonun",
  emits: ["playAudioSolo"],
  components: {
    Play,
    Mark,
    MarkCheck,
    Share,
    Pause,
    SharePopup,
    VideoQuran,
    MemoDrawer,
    Objectif,
  },
  props: {
    // Vos props existantes
    options: {
      type: Object,
      required: true,
    },
    surah: String,
    id: Number,
    index: Number,
    idVerse: Number,
    numero: Number,
    arabe: String,
    fr: String,
    tajweed: String,
    tafsir: String,
    phonetique: String,
    checks: String,
    favoris: Boolean,
    totalverses: String,
    idsourate: Number,
    playAr: Boolean,
    playFr: Boolean,
    reciter: {
      type: String,
      default: "Hussary",
    },
  },
  data() {
    return {
      drawer: false,
      isChecked:
        this.checks?.split("|").includes(this.numero.toString()) || false,
      showTafsir: false,
      nextIndex: null,
      showObjectif: false, // État local pour afficher Objectif
    };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapState("user", ["user"]),
    checkedVersets() {
      return this.checks ? this.checks.split("|") : [];
    },
    isFavoris() {
      return (
        this.user &&
        this.user.ayahs &&
        this.user.ayahs.some((v) => v.id === this.idVerse)
      );
    },
    computedPageUrl() {
      return `sourate/${this.$route.params.id}#${this.index}`;
    },
  },
  methods: {
    ...mapActions("check", ["updateOrCreateCheck", "fetchGoalChecksForWeek"]),
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("sourate", ["fetchSourate"]),
    ...mapActions("user", ["addVerseToFavorites", "removeVerseFromFavorites"]),
    ...mapActions("points", ["updateUserPoints", "updateUserStatSourates"]),

    openSharePopup() {
      this.$refs.sharePopup.togglePopup();
    },
    toggleTafsir() {
      this.showTafsir = !this.showTafsir;
    },
    async toggleFavoris() {
      if (this.isAuthenticated) {
        if (this.isFavoris) {
          await this.removeVerseFromFavorites(this.idVerse);
        } else {
          await this.addVerseToFavorites(this.idVerse);
        }
      } else {
        alert("Veuillez vous connecter pour ajouter des favoris.");
      }
    },
    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },
    async handleCheckChange() {
      let list_verset = [];
      if (this.checks) {
        list_verset = this.checks.split("|");
      }
      const tauxBefore = (list_verset.length / this.totalverses) * 100;

      // Ajout ou retrait du verset dans la liste
      if (this.isChecked) {
        list_verset.push(this.numero.toString());
        let currentPoints = Number(this.user.hassanate.points) + 10;
        this.updateUserPoints(currentPoints);
        this.user.hassanate.points = currentPoints;
      } else {
        list_verset = list_verset.filter((v) => v !== this.numero.toString());
        let currentPoints = Number(this.user.hassanate.points) - 10;
        this.updateUserPoints(currentPoints);
        this.user.hassanate.points = currentPoints;
      }

      let newlist_verset = [...new Set(list_verset)];
      const tauxAfter = Math.round(
        (newlist_verset.length / this.totalverses) * 100
      );

      // Confetti si tauxAfter atteint 100%
      if (tauxAfter === 100) {
        this.triggerConfetti();
        let sourateStat = (this.user.hassanate.sourates += 1);
        this.updateUserStatSourates(sourateStat);
      }
      // Si on repasse sous les 100%
      if (tauxBefore === 100 && tauxAfter < 100) {
        let sourateStat = (this.user.hassanate.sourates -= 1);
        this.updateUserStatSourates(sourateStat);
      }

      let sourateId = this.idsourate;
      if (this.$i18n.locale === "en") {
        sourateId += 114;
      }

      try {
        // 1. Récupérer l'état actuel avant la mise à jour
        const todayKey = formatLocalKey(new Date());
        const currentTodayCheck = this.$store.state.check.weekChecks.find(
          (check) => {
            const checkDate = new Date(check.attributes.createdAt);
            return formatLocalKey(checkDate) === todayKey;
          }
        );
        const wasValide = currentTodayCheck?.attributes?.valide || false;

        // 2. Mettre à jour ou créer le check
        await this.updateOrCreateCheck({
          id: this.id,
          list_verset,
          sourate: sourateId,
          iduser: String(this.user.id),
          taux: Number(tauxAfter),
          lang: this.$i18n.locale,
        });

        // 3. Recharger la sourate
        await this.fetchSourate(sourateId.toString());

        // 4. Recharger les checks pour obtenir les données à jour
        await this.fetchGoalChecksForWeek();

        // 5. Récupérer le check mis à jour
        const updatedTodayCheck = this.$store.state.check.weekChecks.find(
          (check) => {
            const checkDate = new Date(check.attributes.createdAt);
            return formatLocalKey(checkDate) === todayKey;
          }
        );

        // 6. Vérifier si l'objectif est atteint et s'il vient d'être atteint
        if (updatedTodayCheck?.attributes?.daily_goal?.data?.attributes) {
          const dailyGoal =
            updatedTodayCheck.attributes.daily_goal.data.attributes.Goal;
          const versesCount = updatedTodayCheck.attributes.versesCount;
          const valide = updatedTodayCheck.attributes.valide;

          // console.log(
          //   "dailyGoal =",
          //   dailyGoal,
          //   "versesCount =",
          //   versesCount,
          //   "valide =",
          //   valide
          // );

          valide;

          // Condition mise à jour : vérifier si l'objectif vient d'être atteint
          if (versesCount === dailyGoal && !wasValide) {
            // console.log("Objectif atteint !");
            this.showObjectif = true;
            const correctSound = new Audio(valideSong);
            correctSound.play();
          } else {
            // console.log("Objectif non atteint ou déjà validé.");
            this.showObjectif = false;
          }
        }
      } catch (error) {
        console.error("Erreur mise à jour check :", error);
      }
    },
    isCheckboxDisabled(versetId) {
      const versetIndex = this.numero - 1;
      const previousVersetId = versetIndex > 0 ? versetIndex.toString() : null;
      const nextVersetId =
        versetIndex < this.totalverses - 1
          ? (versetIndex + 2).toString()
          : null;
      if (previousVersetId && !this.checkedVersets.includes(previousVersetId)) {
        return true;
      }
      if (
        this.checkedVersets.includes(versetId.toString()) &&
        nextVersetId &&
        this.checkedVersets.includes(nextVersetId)
      ) {
        return true;
      }
      return false;
    },
    playWordAudio(index) {
      const audioPath = this.getWordAudioPath(this.numero, index);
      if (!audioPath) return;
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.currentAudio = new Audio(audioPath);
      this.currentAudio.play();
      this.currentAudio.addEventListener("ended", () => {
        this.currentAudio = null;
      });
    },
    getWordAudioPath(verset, wordIndex) {
      if (!verset) return null;
      const sourateDossier = String(this.idsourate);
      const versetFile = String(this.numero).padStart(3, "0");
      const sourateNumero = String(this.idsourate).padStart(3, "0");
      const versetNumero = String(this.numero).padStart(3, "0");
      const wordNumber = String(wordIndex + 1).padStart(3, "0");
      return `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;
    },
    handleMemorizeNextVerse() {
      // Fermer le drawer du verset actuel
      this.drawer = false;

      // Récupérer l’index du verset dans la liste
      const currentIndex = this.index - 1; // si index démarre à 1

      // Calculer l’index suivant
      this.nextIndex = currentIndex + 1;

      // Vérifier s’il y a un verset suivant
      if (this.nextIndex < this.totalverses) {
        // Récupérer la référence du composant Verset suivant via :ref="verset-0", :ref="verset-1", etc.
        const nextVersetRef = this.$parent.$refs[`verset-${this.nextIndex}`];

        if (nextVersetRef && nextVersetRef[0]) {
          // Ouvrir son Drawer
          nextVersetRef[0].drawer = true;
        }
      }
    },
    openDrawer() {
      if (this.arabe.split(" ").length > 1) {
        this.drawer = true;
      }
    },
  },
};
</script>

<style scoped>
.box-verset {
  display: block;
  margin: 10px auto 80px auto;
  max-width: 800px;
  border-radius: 6px;
}
.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.top p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  min-width: 40px;
  min-height: 40px;
  background: var(--body);
  border-radius: 50%;
}
.tooltips {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tooltips .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: var(--body);
  border-radius: 50%;
  cursor: pointer;
}
.tooltips .memorize {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 12px;
  height: 30px;
  min-width: 90px;
  padding: 0 10px;
  border: 1px solid var(--body);
  color: var(--color);
  border-radius: 6px;
}
.tooltips .memorize:hover {
  background: var(--button);
  border: 1px solid var(--button);
  color: #fff;
}
.arabe,
.tajweed {
  font-family: "uthmanic";
  text-align: end;
  font-size: clamp(20px, 8vw, 30px);
  background: var(--bgV);
  padding: 15px 10px;
  border-radius: 6px;
  border: 2px solid var(--borderV);
}
.trad,
.phonetic {
  font-size: 17px;
  margin-top: 20px;
  text-align: justify;
}
.tafsir {
  margin-top: 20px;
  font-size: 17px;
  text-align: justify;
}
.tafsir span {
  background: #ff6370;
  padding: 3px 10px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
}
.arabe .word-arabic:hover {
  cursor: pointer;
  color: #ff6370;
}
.bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.bottom .left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bottom .left .ar,
.bottom .left .fr,
.bottom .left .kathir,
.bottom .left .display-tafsir {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 6px;
  border: 1px solid var(--body);
  background: var(--body);
  border-radius: 3px;
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
}
.bottom .left .ar:hover,
.bottom .left .fr:hover,
.bottom .left .kathir:hover,
.bottom .left .display-tafsir:hover {
  border: 1px solid var(--button);
}
.bottom .left .kathir a {
  display: inline;
  font-size: 13px;
  padding: 10px 7px;
}
.btn-finalStep {
  display: flex;
  justify-content: center;
}
.btn-finalStep span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--body);
  border-radius: 50px;
  padding: 10px 30px;
  width: auto;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.btn-finalStep span:hover {
  outline: 1px solid var(--button);
}
.box-check {
  cursor: pointer;
}
.box-check input {
  display: none;
}
.box-check svg {
  overflow: visible;
}
.path {
  fill: none;
  stroke: var(--color);
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}
.box-check input:checked ~ svg .path {
  stroke-dasharray: 70.5096664428711 9999999;
  stroke-dashoffset: -262.2723388671875;
  stroke: var(--button);
}
@media (max-width: 700px) {
  .trad,
  .phonetic {
    text-align: start;
  }
  .tafsir {
    text-align: start;
  }
}
@media (max-width: 380px) {
  .bottom .left .kathir a,
  .bottom .left .display-tafsir span {
    font-size: 11px;
  }
}
.bottom .left img {
  width: 15px;
  height: 15px;
}
</style>
