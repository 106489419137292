<template>
  <div v-if="!isLoading">
    <Navbar />
    <div class="main">
      <router-view> </router-view>
      <Up />
      <div v-if="!myTestimonials">
        <popupTesti />
      </div>
      <!-- Gestionnaire de notifications (invisible) -->
      <!-- <NotificationManager v-if="isAuthenticated" /> -->
      <!-- Prompt pour demander l'activation des notifications -->
      <!-- <NotificationPrompt v-if="isAuthenticated" /> -->
    </div>
    <Footer />
  </div>
  <div class="loading" v-else>
    <!-- Vous pouvez ajouter un écran de chargement ici -->
    <Loading />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
import Navbar from "./components/navigation/Navbar.vue";
import Footer from "./components/navigation/Footer.vue";
import Loading from "./components/navigation/Loading.vue";
import Up from "./components/navigation/Up.vue";
import popupTesti from "./components/Profil/popupTesti.vue";
// import NotificationManager from "./components/notification/NotificationManager.vue";
// import NotificationPrompt from "./components/notification/NotificationPrompt.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Up,
    popupTesti,
    Loading,
    // NotificationManager,
    // NotificationPrompt
  },
  data() {
    return {
      userEmail: "",
      loading: {},
      isLoading: true, // Ajouté pour gérer l'état de chargement
    };
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    ...mapGetters("testimonials", ["myTestimonials"]),
    ...mapState("user", ["user"]),
    ...mapGetters("aiIslam", ["isAiActive"]),
    hasSubscription() {
      return this.isSubscribed;
    },
  },
  async created() {
    try {
      // Enregistrement du service worker pour les notifications PWA
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.register('/service-worker.js');
          console.log('Service Worker enregistré avec succès:', registration.scope);
        } catch (error) {
          console.error('Erreur lors de l\'enregistrement du Service Worker:', error);
        }
      }

      await this.fetchUser();
      await this.fetchUserEmail();
      if (this.userEmail) {
        await this.checkSubscriptionStatus();
        this.fetchTestimonialById();
      }
      await this.checkUserStatus();

      // Initialiser l'état des notifications si l'utilisateur est authentifié
      if (this.isAuthenticated) {
        this.initNotificationState();
        await this.createDailyGoalIfNotExists();
        
        // Initialiser l'IA Islam si elle est active
        if (this.isAiActive) {
          this.startPostingCycle();
        }
      }
    } catch (error) {
      console.log(
        "Erreur lors de la récupération des informations de l'utilisateur.",
        error
      );
    } finally {
      this.isLoading = false; // Fin de l'état de chargement
    }
  },
  mounted() {
    // Masquer l'écran de démarrage une fois que l'application est chargée
    this.isLoading = false;
  },
  methods: {
    ...mapActions("subscribe", ["checkSubscriptionStatus"]),
    ...mapActions("testimonials", ["fetchTestimonialById"]),
    ...mapActions("user", ["fetchUser"]),
    // Mapper les actions du module 'check'
    ...mapActions("check", ["createDailyGoalIfNotExists"]),
    // Mapper les actions du module 'notification'
    ...mapActions("notification", ["initNotificationState"]),
    // Mapper les actions du module 'aiIslam'
    ...mapActions("aiIslam", ["startPostingCycle"]),

    async fetchUserEmail() {
      if (this.user) {
        try {
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("subscribe/SET_USER_EMAIL", this.userEmail);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
        }
      }
    },

    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = button.getAttribute("data-id");
      const userEmail = button.getAttribute("data-email");
      this.loading[planId] = true;

      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${userEmail}`
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },

    async checkUserStatus() {
      // Vérifiez si l'utilisateur est authentifié
      if (this.isAuthenticated) {
        // Vérifiez l'état de l'abonnement
        await this.checkSubscriptionStatus();

        if (this.isSubscribed) {
          // Si l'utilisateur est abonné et se trouve sur la page d'abonnement, redirigez-le vers la page d'accueil
          if (this.$route.path === "/tarifs") {
            this.$router.push("/");
          }
          // Aucun besoin de rediriger si l'utilisateur est déjà abonné
          return;
        }

        // Si l'utilisateur n'est pas abonné, vérifiez l'offre gratuite
        const freeOfferData = localStorage.getItem("freeOfferSelected");
        if (freeOfferData) {
          const { expiration } = JSON.parse(freeOfferData); // Extraire la date d'expiration
          const currentTime = new Date().getTime();
          if (currentTime > expiration) {
            // Si l'offre gratuite a expiré, supprimer et rediriger vers /tarifs
            localStorage.removeItem("freeOfferSelected");
            this.$router.push("/tarifs");
          }
          // Si l'offre gratuite est toujours active, ne redirigez pas
        } else {
          // Si 'freeOfferSelected' n'existe pas, rediriger vers /tarifs
          this.$router.push("/tarifs");
        }
      }
    },
  },
  watch: {
    // Ajouter un watcher pour gérer les changements d'authentification en temps réel
    isAuthenticated(newVal) {
      if (newVal) {
        this.createDailyGoalIfNotExists();
      }
    },
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.main {
  min-height: 100vh
  }
</style>
