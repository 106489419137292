/**
 * Utilitaire simple de mise en cache des requêtes API
 * Permet d'éviter de refaire des requêtes pour des données qui changent rarement
 */

// Stockage du cache en mémoire
const cache = {};

// Configuration par défaut du TTL (Time-To-Live) en millisecondes
const DEFAULT_TTL = 5 * 60 * 1000; // 5 minutes

/**
 * Vérifie si une clé de cache est valide (existe et n'a pas expiré)
 * @param {string} key - Clé de cache
 * @returns {boolean} - True si la clé existe et n'a pas expiré
 */
const isValid = (key) => {
  if (!cache[key]) return false;
  
  return cache[key].timestamp + cache[key].ttl > Date.now();
};

/**
 * Récupère les données en cache pour une clé donnée
 * @param {string} key - Clé de cache
 * @returns {any|null} - Données en cache ou null si non trouvé ou expiré
 */
export const getCachedData = (key) => {
  if (!isValid(key)) return null;
  
  // Actualiser le timestamp pour prolonger la durée de vie du cache
  // si les données sont encore utilisées activement
  cache[key].timestamp = Date.now();
  
  return cache[key].data;
};

/**
 * Stocke des données en cache
 * @param {string} key - Clé de cache
 * @param {any} data - Données à mettre en cache
 * @param {number} [ttl=DEFAULT_TTL] - Durée de vie du cache en millisecondes
 */
export const setCachedData = (key, data, ttl = DEFAULT_TTL) => {
  cache[key] = {
    data,
    timestamp: Date.now(),
    ttl
  };
};

/**
 * Invalidate un élément spécifique du cache
 * @param {string} key - Clé de cache à invalider
 */
export const invalidateCache = (key) => {
  if (cache[key]) {
    delete cache[key];
  }
};

/**
 * Invalide tout le cache ou une partie basée sur un préfixe
 * @param {string} [prefix] - Préfixe de clé à invalider (optionnel)
 */
export const clearCache = (prefix = null) => {
  if (prefix) {
    Object.keys(cache).forEach(key => {
      if (key.startsWith(prefix)) {
        delete cache[key];
      }
    });
  } else {
    Object.keys(cache).forEach(key => {
      delete cache[key];
    });
  }
};

/**
 * Wrapper pour les fonctions asynchrones avec mise en cache
 * @param {string} key - Clé de cache
 * @param {Function} fetchFunction - Fonction asynchrone à exécuter si pas de cache
 * @param {number} [ttl=DEFAULT_TTL] - Durée de vie du cache en millisecondes
 * @returns {Promise<any>} - Données en cache ou résultat de fetchFunction
 */
export const withCache = async (key, fetchFunction, ttl = DEFAULT_TTL) => {
  // Vérifier si les données sont en cache et valides
  const cachedData = getCachedData(key);
  if (cachedData !== null) {
    return cachedData;
  }

  // Sinon, exécuter la fonction de récupération
  const data = await fetchFunction();
  
  // Mettre en cache le résultat
  setCachedData(key, data, ttl);
  
  return data;
};

export default {
  getCachedData,
  setCachedData,
  invalidateCache,
  clearCache,
  withCache
};
