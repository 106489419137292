// store/modules/notification.js

const state = () => ({
  enabled: false,
  times: ['09:00', '13:00', '19:00'],
  blocked: false,
  lastNotificationSent: null,
  notificationType: 'native' // Type de notification: 'native', 'inapp', 'none'
});

const mutations = {
  SET_ENABLED(state, enabled) {
    state.enabled = enabled;
  },
  SET_TIMES(state, times) {
    state.times = times;
  },
  SET_BLOCKED(state, blocked) {
    state.blocked = blocked;
  },
  SET_LAST_NOTIFICATION_SENT(state, timestamp) {
    state.lastNotificationSent = timestamp;
  },
  SET_NOTIFICATION_TYPE(state, type) {
    state.notificationType = type;
  }
};

const actions = {
  // Initialiser l'état des notifications depuis le localStorage
  initNotificationState({ commit, dispatch }) {
    dispatch('syncWithBrowserPermissions');
    
    const enabled = localStorage.getItem('doonun-notifications-enabled');
    const inappEnabled = localStorage.getItem('doonun-notifications-inapp');
    const times = localStorage.getItem('doonun-notification-times');
    const lastSent = localStorage.getItem('doonun-last-notification-sent');
    const notificationType = localStorage.getItem('doonun-notification-type');
    
    if (enabled !== null) {
      commit('SET_ENABLED', enabled === 'true');
    }
    
    if (notificationType) {
      commit('SET_NOTIFICATION_TYPE', notificationType);
    } else if (inappEnabled === 'true') {
      commit('SET_NOTIFICATION_TYPE', 'inapp');
    }
    
    if (times) {
      try {
        const parsedTimes = JSON.parse(times);
        if (Array.isArray(parsedTimes) && parsedTimes.length > 0) {
          commit('SET_TIMES', parsedTimes);
        }
      } catch (e) {
        console.error('Erreur lors du chargement des heures de notification:', e);
      }
    }
    
    if (lastSent) {
      try {
        commit('SET_LAST_NOTIFICATION_SENT', new Date(lastSent));
      } catch (e) {
        console.error('Erreur lors du chargement de la dernière notification:', e);
      }
    }
  },
  
  // Enregistrer que les notifications sont activées/désactivées
  setNotificationsEnabled({ commit }, enabled) {
    commit('SET_ENABLED', enabled);
    localStorage.setItem('doonun-notifications-enabled', enabled);
  },
  
  // Enregistrer que les notifications sont bloquées
  setNotificationsBlocked({ commit }, blocked) {
    commit('SET_BLOCKED', blocked);
  },
  
  // Définir le type de notification utilisé
  setNotificationType({ commit }, type) {
    commit('SET_NOTIFICATION_TYPE', type);
    localStorage.setItem('doonun-notification-type', type);
  },
  
  // Enregistrer les heures de notification
  setNotificationTimes({ commit }, times) {
    commit('SET_TIMES', times);
    localStorage.setItem('doonun-notification-times', JSON.stringify(times));
  },
  
  // Enregistrer l'heure de la dernière notification
  recordNotificationSent({ commit }) {
    const now = new Date();
    commit('SET_LAST_NOTIFICATION_SENT', now);
    localStorage.setItem('doonun-last-notification-sent', now.toISOString());
  },
  
  // Vérifier si on peut envoyer une notification (pour éviter spam)
  canSendNotification({ state }) {
    if (!state.lastNotificationSent) return true;
    
    // Minimum 30 minutes entre chaque notification
    const now = new Date();
    const timeDiff = now - new Date(state.lastNotificationSent);
    return timeDiff > (30 * 60 * 1000); // 30 minutes en millisecondes
  },
  
  // Synchroniser les permissions avec le navigateur
  syncWithBrowserPermissions({ commit }) {
    if ('Notification' in window) {
      const permission = Notification.permission;
      commit('SET_BLOCKED', permission === 'denied');
      commit('SET_ENABLED', permission === 'granted');
      
      // Vérifier le type de notification disponible
      const notificationType = localStorage.getItem('doonun-notification-type');
      if (!notificationType) {
        if (permission === 'granted') {
          commit('SET_NOTIFICATION_TYPE', 'native');
        } else if (localStorage.getItem('doonun-notifications-inapp') === 'true') {
          commit('SET_NOTIFICATION_TYPE', 'inapp');
        }
      }
    } else if (localStorage.getItem('doonun-notifications-inapp') === 'true') {
      // Si pas de notifications natives mais in-app activées
      commit('SET_NOTIFICATION_TYPE', 'inapp');
      commit('SET_ENABLED', true);
      commit('SET_BLOCKED', false);
    }
  }
};

const getters = {
  isEnabled: state => state.enabled,
  getNotificationTimes: state => state.times,
  isBlocked: state => state.blocked,
  getLastNotificationSent: state => state.lastNotificationSent,
  getNotificationType: state => state.notificationType,
  isNativeNotification: state => state.notificationType === 'native',
  isInAppNotification: state => state.notificationType === 'inapp'
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
