import axios from 'axios';

const storedToken = localStorage.getItem("token");
const envToken = process.env.VUE_APP_API_STRAPI;
const token = storedToken ? storedToken : envToken;

// Ajoutez Axios à l'instance Vue
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default axios;
