<template>
  <div class="card-fiiyd">
    <div class="top">
      <div class="left">
        <router-link :to="`/profil/${idUsername}`">
          <img :src="`https://cp.doonun.com${avatar}`" alt="" />
        </router-link>
        <p class="username">
          {{ username }} •
          <span class="time">{{ time }}</span>
        </p>
      </div>
      <div class="right">
        <el-popover
          placement="bottom"
          :width="240"
          trigger="click"
          v-if="this.user.username == username"
        >
          <template #reference>
            <div class="settings"><Settings /></div>
          </template>
          <template #default>
            <div
              class="demo-rich-conent"
              style="display: flex; gap: 16px; flex-direction: column"
            >
              <div class="options">
                <div class="option">
                  <el-button
                    type="danger"
                    @click="open"
                    :icon="Delete"
                    circle
                  />
                  <span @click="open">{{ $t("delete") }}</span>
                </div>
                <!-- Commenté pour le moment
                <div class="option">
                  <el-button type="warning" :icon="Warning" circle />
                  <span>{{ $t("signal") }}</span>
                </div>
                -->
                <div class="option">
                  <el-switch
                    v-model="contentSensitive"
                    size="small"
                    class="ml-2"
                    @change="toggleContentSensitive"
                  />
                  <span>{{ $t("sensible") }}</span>
                </div>
                <div class="option">
                  <el-switch
                    v-model="enableNotifications"
                    size="small"
                    class="ml-2"
                    @change="toggleEnableNotifications"
                  />
                  <span>{{ $t("activeNotif") }}</span>
                </div>
                <div class="option">
                  <el-switch
                    v-model="enableComments"
                    size="small"
                    class="ml-2"
                    @change="toggleEnableComments"
                  />
                  <span>{{ $t("activeComment") }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
    <router-link :to="`/fiiyd/${url}`">
      <div class="middle">
        <p>
          <span v-for="(part, index) in formattedContent" :key="index">
            <router-link
              v-if="part.type === 'tag'"
              class="tags"
              :to="`/fiiyd/hashtag/${part.content.substring(1)}`"
            >
              {{ part.content }}
            </router-link>
            <span v-else>{{ part.content }}</span>
          </span>
        </p>

        <div class="sensible" v-if="contentSensitive">
          <closeEye />
          <p>{{ username }} {{ $t("messageSensible") }}</p>
          <span @click.prevent="seeContent">{{ $t("displaySensible") }}</span>
        </div>
        <div class="list-medias" v-else>
          <!-- Gestion des images -->
          <div v-if="imageLinks.length >= 1" class="medias">
            <ImageCarousel
              :images="imageLinks.map((link) => link.url)"
              @open-modal="showModal"
              @click.prevent
            />
          </div>

          <!-- Gestion des vidéos -->
          <div v-if="videoLinks.length > 0" class="medias">
            <MediaRenderer
              v-for="(link, index) in videoLinks"
              :key="index"
              :link="link.url"
              :domain="link.domain"
              :isVideo="link.isVideo"
              @click.prevent
            />
          </div>

          <!-- Gestion des aperçus de liens -->
          <LinkPreview
            v-for="(link, index) in previewLinks"
            :key="`preview-${index}`"
            :link="link.url"
          />
        </div>
      </div>
    </router-link>
    <div class="icons">
      <ul>
        <li>
          <Stat />
          <span>{{ formatPoints(vues) || 0 }}</span>
        </li>
        <router-link :to="`/fiiyd/${url}`">
          <li>
            <Comment />
            <span>{{ formatPoints(comments) || 0 }}</span>
          </li>
        </router-link>
        <li
          @click.prevent="toggleFavoris"
          class="like"
          :class="{ fav: isFavoris }"
        >
          <Heart />
          <span>{{ formatPoints(currentLikes) || 0 }}</span>
        </li>
      </ul>
    </div>
    
    <!-- Affichage des avatars des utilisateurs qui ont interagi -->
    <div class="interactions-container">
      <div v-if="likeUsers && likeUsers.length > 0" class="interaction-section">
        <InteractionAvatars 
          :users="likeUsers" 
          type="likes" 
        />
      </div>
      
      <div v-if="commentUsers && commentUsers.length > 0" class="interaction-section">
        <InteractionAvatars 
          :users="commentUsers" 
          type="comments" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Settings from "../icons/fiiyd/Settings.vue";
import Heart from "../icons/fiiyd/Heart.vue";
import Stat from "../icons/fiiyd/Stat.vue";
import Comment from "../icons/fiiyd/Comment.vue";
import MediaRenderer from "./MediaRenderer.vue";
import ImageCarousel from "./ImageCarousel.vue";
import LinkPreview from "./LinkPreview.vue";
import InteractionAvatars from "./InteractionAvatars.vue";
import { mapGetters, mapActions } from "vuex";
import { Delete, Warning } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "card-fiiyd",
  props: {
    avatar: {
      type: String,
    },
    IdPost: {
      type: Number,
    },
    idUsername: {
      type: Number,
    },
    username: {
      type: String,
    },
    time: {
      type: String,
    },
    content: {
      type: String,
      default: "",
    },
    images: {
      type: String,
    },
    liens: {
      type: String,
    },
    comments: {
      type: Number,
    },
    vues: {
      type: [Number, String],
      default: 0,
    },
    likes: {
      type: Number,
      default: 0,
    },
    likeUsers: {
      type: Array,
      default: () => [],
    },
    commentUsers: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
    },
    favoris: Boolean,
    enable_comment: {
      type: Boolean,
      default: true,
    },
    enable_notif: {
      type: Boolean,
      default: true,
    },
    content_sensitive: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Settings,
    Heart,
    Comment,
    Stat,
    MediaRenderer,
    ImageCarousel,
    LinkPreview,
    InteractionAvatars,
  },
  data() {
    return {
      parsedLinks: [],
      parsedImages: [],
      cleanedText: this.content,
      currentImage: "",
      currentImageDomain: "",
      showTooltips: false,
      videoDomains: ["youtube.com", "youtu.be", "x.com", "tiktok.com"],
    };
  },
  setup(props) {
    const isFavoris = ref(props.favoris);
    const contentSensitive = ref(props.content_sensitive);
    const enableNotifications = ref(props.enable_notif);
    const enableComments = ref(props.enable_comment);
    const currentLikes = ref(props.likes);

    watch(
      () => props.favoris,
      (newVal) => {
        isFavoris.value = newVal;
      }
    );

    watch(
      () => props.likes,
      (newVal) => {
        currentLikes.value = newVal;
      }
    );

    return {
      isFavoris,
      contentSensitive,
      enableNotifications,
      enableComments,
      currentLikes,
      Delete,
      ElMessage,
      ElMessageBox,
      Warning,
    };
  },
  mounted() {
    this.parseLinks();
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "isAuthenticated", "user"]),
    formattedContent() {
      const text = this.cleanedText;
      const tagRegex = /#\w+/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = tagRegex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          parts.push({
            type: "text",
            content: text.slice(lastIndex, match.index),
          });
        }
        parts.push({
          type: "tag",
          content: match[0],
        });
        lastIndex = match.index + match[0].length;
      }

      if (lastIndex < text.length) {
        parts.push({
          type: "text",
          content: text.slice(lastIndex),
        });
      }

      return parts;
    },
    imageLinks() {
      return this.parsedImages;
    },
    videoLinks() {
      return this.parsedLinks.filter((link) => link.isVideo);
    },
    previewLinks() {
      return this.parsedLinks.filter((link) => link.isPreview);
    },
  },
  watch: {
    liens() {
      this.parseLinks();
    },
    images() {
      this.parseLinks();
    },
    content() {
      this.cleanedText = this.content;
    },
  },
  methods: {
    ...mapActions("fiiyd", [
      "fetchPosts",
      "toggleLike",
      "updatePostSettings",
      "deletePost",
      "togglePostLike",
    ]),
    ...mapActions("user", ["addPostToFavorites", "removePostFromFavorites"]),
    formatPoints(points) {
      if (points === undefined || points === null) return 0;

      points = Number(points);
      if (isNaN(points)) return 0;

      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      }
      return points;
    },
    seeContent() {
      this.contentSensitive = false;
    },
    async toggleFavoris() {
      if (this.isAuthenticated) {
        // Mettre à jour le compteur de likes localement pour un affichage instantané
        const increment = this.isFavoris ? -1 : 1;
        this.currentLikes += increment;
        
        // Inverser l'état du like localement
        this.isFavoris = !this.isFavoris;
        
        // Mettre à jour dans le store avec l'action (qui enverra la requête API)
        await this.togglePostLike(this.IdPost);
        
        // Émettre l'événement si nécessaire (optionnel)
        this.$emit("addFavorite");
      } else {
        alert("Veuillez vous connecter pour ajouter des favoris.");
      }
    },
    async toggleContentSensitive(newVal) {
      try {
        await this.updatePostSettings({
          postId: this.IdPost,
          updatedFields: { sensible: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour du contenu sensible :",
          error
        );
        this.contentSensitive = !newVal;
      }
    },
    async toggleEnableNotifications(newVal) {
      try {
        await this.updatePostSettings({
          postId: this.IdPost,
          updatedFields: { enable_notif: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des notifications :",
          error
        );
        this.enableNotifications = !newVal;
      }
    },
    async toggleEnableComments(newVal) {
      try {
        await this.updatePostSettings({
          postId: this.IdPost,
          updatedFields: { enable_comment: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des commentaires :",
          error
        );
        this.enableComments = !newVal;
      }
    },
    parseLinks() {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      const isImageLink = (url) =>
        imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));

      const links = this.liens
        ? this.liens
            .split(/\s+/)
            .map((link) => link.trim())
            .filter((link) => link !== "")
        : [];

      const imageLinks = this.images
        ? this.images
            .split(/\s+/)
            .map((image) => image.trim())
            .filter((image) => image !== "")
        : [];

      this.parsedLinks = links.map((link) => {
        const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
        const domain = match ? match[1] : "";
        const isVideo = this.videoDomains.some((videoDomain) =>
          domain.includes(videoDomain)
        );
        return {
          url: link,
          domain,
          isVideo,
          isPreview: !isVideo && !isImageLink(link),
        };
      });

      this.parsedImages = imageLinks.map((link) => {
        const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
        const domain = match ? match[1] : "";
        return { url: link, domain };
      });
    },

    isImageURL(url) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    },

    open() {
      ElMessageBox.confirm(`${this.$t("messageDelete")}`, {
        confirmButtonText: `${this.$t("deletePost")}`,
        cancelButtonText: `${this.$t("escapeDelete")}`,
        type: "warning",
      })
        .then(() => {
          // Émettre un événement pour notifier le parent (qui a la méthode deletePost)
          this.$emit("delete-post", this.IdPost);
          
          // Afficher message de succès
          ElMessage({
            type: "success",
            message: `${this.$t("successDelete")}`,
          });
          
          // Rediriger seulement si on est sur la page détaillée du post
          if (this.$route.path.includes(`/fiiyd/post/${this.url}`)) {
            this.$router.push("/fiiyd");
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: `${this.$t("wrongDelete")}`,
          });
        });
    },
  },
};
</script>

<style scoped>
.card-fiiyd {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 20px;
  padding: 20px;
  border-bottom: 1px solid var(--card);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.options .option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.options .option span {
  font-weight: 700;
  color: var(--color);
  cursor: pointer;
}

@media (max-width: 800px) {
  .card-fiiyd {
    padding: 0 0 30px 0;
  }
}

.card-fiiyd .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
}

.card-fiiyd .top .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-fiiyd .top .right {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.top img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  font-weight: 600;
  color: var(--placeholder);
}

.time {
  font-weight: 400;
  font-size: 14px;
}

.middle {
  display: block;
  margin-left: auto;
  width: calc(100% - 45px);
  margin-top: 5px;
}

.middle a p {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.middle .top p {
  font-size: 16px;
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, Helvetica;
  font-weight: 600;
  color: var(--text);
}

.middle .icons {
  margin-top: 20px;
}

.middle .icons ul {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}

.middle .icons ul li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.middle .icons ul li span {
  font-size: 14px;
  color: #71767b;
}

.like {
  cursor: pointer;
}

.medias {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.medias img.img-post {
  object-fit: cover;
  object-position: center;
  width: calc(50% - 5px);
  max-width: 100%;
  height: auto;
  max-height: 300px;
  flex-grow: 1;
}

.medias .img-post:nth-last-child(1):only-child {
  width: calc(100% - 3px);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .modal-close-button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 1rem;
} */

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
}

.modal-content img {
  display: inline;
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
}

.tags {
  color: var(--button); /* Exemple de couleur */
  text-decoration: none;
  cursor: pointer;
}

.tags:hover {
  text-decoration: underline;
}

.sensible {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
  margin-top: 8px;
  width: 100%;
  height: 220px;
  padding: 20px;
  background: var(--card);
  border-radius: 6px;
}

.sensible p {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 16px;
  /* margin: 10px 0; */
}

.sensible span {
  z-index: 2;
  background: var(--color);
  color: var(--colorBtn);
  border-radius: 30px;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

/* Styles pour le conteneur d'interactions */
.interactions-container {
  padding: 0 15px 15px;
}

.interaction-section {
  margin-bottom: 8px;
}

.interaction-section:last-child {
  margin-bottom: 0;
}
</style>
