<template>
  <div>
    <h2 id="scroll-target">{{ $t("booksCompTitle") }}</h2>
    <div v-if="localLoading" class="loading">
      {{ $t("loading") || "Chargement des livres..." }}
    </div>
    <div v-else class="books-container">
      <div class="container-book">
        <div class="item" v-for="livre in livreUser" :key="livre?.id">
          <cardBook
            :id="livre?.id"
            :titre="livre?.attributes?.titre"
            :lien="livre?.attributes?.url"
            :cover="livre?.attributes?.cover.data.attributes.formats.small.url"
            :favoris="Boolean(livre?.attributes?.users?.data[0]?.id)"
            @addFavorite="addFavorite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cardBook from "../livres/cardBook.vue";
import DataLoadingMixin from "../../mixins/DataLoadingMixin";

export default {
  name: "livres-profil",
  components: {
    cardBook,
  },
  mixins: [DataLoadingMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters("livres", ["livreUser"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("user", ["userId"]),
  },
  methods: {
    ...mapActions("livres", ["fetchUserLivre", "fetchUserLivreById"]),
    async addFavorite() {
      try {
        // Invalider le cache pour forcer un rechargement
        this.invalidateCache(`userLivres-${this.userId || 'self'}`);
        await this.loadBooks(true);
      } catch (error) {
        console.error("Error updating book favorites:", error);
      }
    },
    async loadBooks(forceRefresh = false) {
      this.localLoading = true;
      try {
        await this.fetchBooks(forceRefresh);
        this.emitDataLoaded();
      } catch (error) {
        console.error("Error fetching books:", error);
      } finally {
        this.localLoading = false;
      }
    },
    async fetchBooks(forceRefresh = false) {
      const cacheKey = `userLivres-${this.userId || 'self'}`;
      return this.getWithCache(cacheKey, async () => {
        if (this.readonly && this.userId) {
          // Cas d'un profil d'un autre utilisateur
          await this.fetchUserLivreById(this.userId);
        } else {
          // Cas du profil de l'utilisateur connecté
          await this.fetchUserLivre();
        }
        return true;
      }, forceRefresh);
    }
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.withLoading(this.loadBooks());
      }
    } catch (error) {
      console.error("Error fetching user books:", error);
    }
  },
  watch: {
    userId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadBooks(true); // Force reload on user change
      }
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 30px 0 20px 0;
}

.books-container {
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

.container-book {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 5px;
  margin-top: 20px;
}

.item {
  width: 100%;
}

.loading {
  text-align: center;
  margin: 20px 0;
}

@media (max-width: 600px) {
  .container-book {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
</style>
