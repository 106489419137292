import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axiosInstance from "./plugins/axios";
import "moment/locale/fr";
import ElementPlus from "element-plus";
import MarkdownIt from "markdown-it";
import { html5Media } from "markdown-it-html5-media";
import markdownItVideo from "markdown-it-video";
import markdownItMultimdTable from "markdown-it-multimd-table";
import "element-plus/dist/index.css";
import "./assets/markdown.css";
import "./assets/style.css";
import "./assets/tajweed.css";
import "./assets/artplayer.css";
import * as ElIcons from "@element-plus/icons-vue";
import VueHtml2Canvas from "vue-html2canvas";
import { themes } from "./assets/js/themes";
import { applyTheme } from "./assets/js/themeUtils";
import { createHead } from "@vueuse/head";
import "./registerServiceWorker";

// Appliquer le thème stocké avant de créer l'application
const storedTheme = localStorage.getItem("selectedTheme");
if (storedTheme !== null) {
  const index = parseInt(storedTheme, 10);
  if (index >= 0 && index < themes.length) {
    applyTheme(index);
  }
}

const head = createHead();

const app = createApp(App).use(head);

// Enregistrer globalement les icônes
for (const [key, component] of Object.entries(ElIcons)) {
  app.component(key, component);
}

// Utiliser l'instance axios configurée
app.config.globalProperties.$axios = axiosInstance;

const md = new MarkdownIt();

md.use(markdownItMultimdTable, {
  multiline: false,
  rowspan: false,
  headerless: false,
  multibody: true,
  autolabel: true,
});

md.use(html5Media, {
  audioAttrs: 'class="my-audio-css" controls',
});

md.use(markdownItVideo, {
  youtube: {
    width: "100%",
    height: "340",
    nocookie: false,
    modestbranding: 1,
    related: 0,
    showinfo: 0,
    parameters: {},
  },
  vimeo: { width: "100%", height: 281 },
  vine: { width: "100%", height: 600, embed: "simple" },
  prezi: { width: "100%", height: 400 },
});

app.config.globalProperties.$md = md;

app.config.errorHandler = (err, vm, info) => {
  console.error("Error:", err);
  console.error("Vue component:", vm);
  console.error("Additional info:", info);
};

app.use(VueHtml2Canvas);
app.use(router);
app.use(store);
app.use(i18n);
app.use(ElementPlus);
// app.use(createMetaManager())
router.afterEach(() => {
  window.scrollTo({ top: 0 });
});

app.mount("#app");
