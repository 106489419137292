<template>
  <div class="card-fiiyd">
    <div class="top">
      <div class="left">
        <router-link :to="`/profil/${idUsername}`">
          <img :src="`https://cp.doonun.com${avatar}`" alt="" />
        </router-link>
        <p class="username">
          {{ username }} •
          <span class="time">{{ time }}</span>
        </p>
      </div>
      <div class="right">
        <el-popover
          placement="bottom"
          :width="240"
          trigger="click"
          v-if="this.user.username == username"
        >
          <template #reference>
            <div class="settings"><Settings /></div>
          </template>
          <template #default>
            <div
              class="demo-rich-conent"
              style="display: flex; gap: 16px; flex-direction: column"
            >
              <div class="options">
                <div class="option">
                  <el-button
                    type="danger"
                    @click="open"
                    :icon="Delete"
                    circle
                  />
                  <span @click="open">{{ $t("delete") }}</span>
                </div>
                <!-- Commenté pour le moment
                <div class="option">
                  <el-button type="warning" :icon="Warning" circle />
                  <span>{{ $t("signal") }}</span>
                </div>
                -->
                <div class="option">
                  <el-switch
                    v-model="contentSensitive"
                    size="small"
                    class="ml-2"
                    @change="toggleContentSensitive"
                  />
                  <span>{{ $t("sensible") }}</span>
                </div>
                <div class="option">
                  <el-switch
                    v-model="enableNotifications"
                    size="small"
                    class="ml-2"
                    @change="toggleEnableNotifications"
                  />
                  <span>{{ $t("activeNotif") }}</span>
                </div>
                <div class="option">
                  <el-switch
                    v-model="enableComments"
                    size="small"
                    class="ml-2"
                    @change="toggleEnableComments"
                  />
                  <span>{{ $t("activeComment") }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
    <div class="middle">
      <p>
        <span v-for="(part, index) in formattedContent" :key="index">
          <router-link
            v-if="part.type === 'tag'"
            class="tags"
            :to="`/fiiyd/hashtag/${part.content.substring(1)}`"
          >
            {{ part.content }}
          </router-link>
          <span v-else>{{ part.content }}</span>
        </span>
      </p>

      <div class="sensible" v-if="contentSensitive">
        <closeEye />
        <p>{{ username }} {{ $t("messageSensible") }}</p>
        <span @click.prevent="seeContent">{{ $t("displaySensible") }}</span>
      </div>

      <div class="list-medias" v-else>
        <!-- Gestion des images -->
        <div v-if="imageLinks.length >= 1" class="medias">
          <ImageCarousel
            :images="imageLinks.map((link) => link.url)"
            @open-modal="showModal"
            @click.prevent
          />
        </div>

        <!-- Gestion des vidéos -->
        <div v-if="videoLinks.length > 0" class="medias">
          <MediaRenderer
            v-for="(link, index) in videoLinks"
            :key="index"
            :link="link.url"
            :domain="link.domain"
            :isVideo="link.isVideo"
            @open-modal="showModal"
            @click.prevent
          />
        </div>

        <!-- Gestion des aperçus de liens -->
        <LinkPreview
          v-for="(link, index) in previewLinks"
          :key="`preview-${index}`"
          :link="link.url"
        />
      </div>
    </div>
    <div class="icons">
      <ul>
        <li>
          <Stat />
          <span>{{ formatPoints(vues) || 0 }}</span>
        </li>

        <li>
          <Comment />
          <span>{{ formatPoints(comments) || 0 }}</span>
        </li>
        <li
          @click.prevent="toggleFavoris"
          class="like"
          :class="{ fav: isFavoris }"
        >
          <Heart />
          <span>{{ formatPoints(likes) || 0 }}</span>
        </li>
      </ul>
    </div>
    <!-- Modal pour afficher l'image sélectionnée -->
    <div v-if="isModalVisible" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="currentImage" :alt="currentImageDomain" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Settings from "../icons/fiiyd/Settings.vue";
import Heart from "../icons/fiiyd/Heart.vue";
import Stat from "../icons/fiiyd/Stat.vue";
import Comment from "../icons/fiiyd/Comment.vue";
import MediaRenderer from "./MediaRenderer.vue";
import ImageCarousel from "./ImageCarousel.vue";
import LinkPreview from "./LinkPreview.vue";
import { Delete, Warning } from "@element-plus/icons-vue";
import { mapGetters, mapActions } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "card-fiiyd-comment-url",
  props: {
    IdComment: {
      type: Number,
    },
    idUsername: {
      type: Number,
    },
    username: {
      type: String,
    },
    time: {
      type: String,
    },
    avatar: {
      type: String,
    },
    content: {
      type: String,
      default: "",
    },
    images: {
      type: String,
    },
    liens: {
      type: String,
    },
    vues: {
      type: [Number, String],
      default: 0,
    },
    likes: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
    },
    comments: {
      type: Number,
    },
    favoris: Boolean,
    sensible: {
      type: Boolean,
      default: false,
    },
    enable_notif: {
      type: Boolean,
      default: true,
    },
    enable_comment: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Settings,
    Heart,
    Comment,
    Stat,
    MediaRenderer,
    ImageCarousel,
    LinkPreview,
  },
  data() {
    return {
      parsedLinks: [],
      parsedImages: [], // Nouvelle propriété pour les images
      cleanedText: this.content,
      isModalVisible: false,
      currentImage: "",
      currentImageDomain: "",
      showTooltips: false,
      videoDomains: ["youtube.com", "youtu.be", "x.com"], // Domains spécifiques pour les vidéos
    };
  },
  setup(props) {
    const isFavoris = ref(props.favoris);
    const contentSensitive = ref(props.sensible);
    const enableNotifications = ref(props.enable_notif);
    const enableComments = ref(props.enable_comment);

    watch(
      () => props.favoris,
      (newVal) => {
        isFavoris.value = newVal;
      }
    );

    return {
      isFavoris,
      contentSensitive,
      enableNotifications,
      enableComments,
      Delete,
      Warning,
      ElMessage,
      ElMessageBox,
    };
  },
  mounted() {
    this.parseLinks();
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "isAuthenticated", "user"]),

    formattedContent() {
      const text = this.cleanedText;
      const tagRegex = /#\w+/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = tagRegex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          parts.push({
            type: "text",
            content: text.slice(lastIndex, match.index),
          });
        }
        parts.push({
          type: "tag",
          content: match[0],
        });
        lastIndex = match.index + match[0].length;
      }

      if (lastIndex < text.length) {
        parts.push({
          type: "text",
          content: text.slice(lastIndex),
        });
      }

      return parts;
    },

    imageLinks() {
      return this.parsedImages; // Utilisation de parsedImages
    },

    videoLinks() {
      return this.parsedLinks.filter((link) => link.isVideo);
    },

    previewLinks() {
      return this.parsedLinks.filter((link) => link.isPreview);
    },
  },
  watch: {
    liens() {
      this.parseLinks();
    },
    images() {
      this.parseLinks();
    },
    content() {
      this.cleanedText = this.content;
    },
  },
  methods: {
    ...mapActions("fiiyd", [
      "fetchComments",
      "updateCommentSettings",
      "deleteComment",
    ]),
    ...mapActions("user", [
      "addCommentToFavorites",
      "removeCommentFromFavorites",
    ]),

    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },

    async toggleContentSensitive(newVal) {
      try {
        await this.updateCommentSettings({
          commentId: this.IdComment,
          updatedFields: { sensible: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour du contenu sensible :",
          error
        );
        // Optionnel : Réinitialiser la valeur en cas d'erreur
        this.contentSensitive = !newVal;
      }
    },

    seeContent() {
      this.contentSensitive = !this.contentSensitive;
    },

    async toggleEnableNotifications(newVal) {
      try {
        await this.updateCommentSettings({
          commentId: this.IdComment,
          updatedFields: { enable_notif: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des notifications :",
          error
        );
        // Optionnel : Réinitialiser la valeur en cas d'erreur
        this.enableNotifications = !newVal;
      }
    },

    async toggleEnableComments(newVal) {
      try {
        await this.updateCommentSettings({
          commentId: this.IdComment,
          updatedFields: { enable_comment: newVal },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des commentaires :",
          error
        );
        // Optionnel : Réinitialiser la valeur en cas d'erreur
        this.enableComments = !newVal;
      }
    },

    async toggleFavoris() {
      if (this.isAuthenticated) {
        if (this.isFavoris) {
          await this.removeCommentFromFavorites(this.IdComment);
        } else {
          await this.addCommentToFavorites(this.IdComment);
        }
        this.isFavoris = !this.isFavoris;
        this.$emit("addFavorite"); // Émettre un événement pour notifier le parent si nécessaire
      } else {
        alert("Veuillez vous connecter pour ajouter des favoris.");
      }
    },

    parseLinks() {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      const isImageLink = (url) =>
        imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));

      const links = this.liens
        ? this.liens
            .split(/\s+/)
            .map((link) => link.trim())
            .filter((link) => link !== "")
        : [];

      const imageLinks = this.images
        ? this.images
            .split(/\s+/)
            .map((image) => image.trim())
            .filter((image) => image !== "")
        : [];

      // Parsing des liens généraux
      this.parsedLinks = links.map((link) => {
        const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
        const domain = match ? match[1] : "";
        const isVideo = this.videoDomains.some((videoDomain) =>
          domain.includes(videoDomain)
        );
        const isYouTubeLink = /youtube\.com|youtu\.be/.test(domain);
        const isTwitterVideo = /twitter\.com|x\.com/.test(domain);
        // console.log("Parsed link:", link, {
        //   domain,
        //   isVideo,
        //   isYouTubeLink,
        //   isTwitterVideo,
        // });
        return {
          url: link,
          domain,
          isVideo,
          isYouTubeLink,
          isTwitterVideo,
          isPreview: !isVideo && !isImageLink(link),
        };
      });

      // Parsing des images
      this.parsedImages = imageLinks.map((link) => {
        const match = link.match(/https?:\/\/(?:www\.)?([^/]+)/);
        const domain = match ? match[1] : "";
        return { url: link, domain };
      });
    },

    isImageURL(url) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
      ];
      return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    },

    // Nouvelle méthode `open` pour la suppression du commentaire
    open() {
      ElMessageBox.confirm(`${this.$t("messageDelete")}`, {
        confirmButtonText: `${this.$t("deletePost")}`,
        cancelButtonText: `${this.$t("escapeDelete")}`,
        type: "warning",
      })
        .then(() => {
          // Émission de l'événement pour notifier le parent
          this.$emit("comment-deleted", this.IdComment);
          
          // Affichage du message de succès
          ElMessage({
            type: "success",
            message: `${this.$t("successDelete")}`,
          });
          
          // Rediriger seulement si on est sur la page du commentaire
          if (this.$route.path.includes(`/fiiyd/comment/${this.url}`)) {
            this.$router.push("/fiiyd");
          }
        })
        .catch(() => {
          // Affichage du message d'annulation
          ElMessage({
            type: "info",
            message: `${this.$t("wrongDelete")}`,
          });
        });
    },

    // Méthodes pour gérer le modal des images
    showModal(imageUrl, domain) {
      this.currentImage = imageUrl;
      this.currentImageDomain = domain;
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.card-fiiyd {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid var(--card);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.options .option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.options .option span {
  font-weight: 700;
  color: var(--color);
  cursor: pointer;
}

@media (max-width: 800px) {
  .card-fiiyd {
    padding: 0 0 30px 0;
  }
}

.card-fiiyd .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
}

.card-fiiyd .top .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-fiiyd .top .right {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.top img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  font-weight: 600;
  color: var(--placeholder);
}

.time {
  font-weight: 400;
  font-size: 14px;
}

.middle {
  display: block;
  margin-left: auto;
  width: 100%;
  margin-top: 5px;
}

.middle a p {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.middle .top p {
  font-size: 16px;
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, Helvetica;
  font-weight: 600;
  color: var(--text);
}

.middle .icons {
  margin-top: 20px;
}

.middle .icons ul {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}

.middle .icons ul li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.middle .icons ul li span {
  font-size: 14px;
  color: #71767b;
}

.medias {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.medias img.img-Comment {
  object-fit: cover;
  object-position: center;
  width: calc(50% - 5px);
  max-width: 100%;
  height: auto;
  max-height: 300px;
  flex-grow: 1;
}

.medias .img-Comment:nth-last-child(1):only-child {
  width: calc(100% - 3px);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .modal-close-button {
      background-color: transparent;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      align-self: flex-end;
      margin-top: 1rem;
    } */

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
}

.modal-content img {
  display: inline;
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
}

.like {
  cursor: pointer;
}

.tags {
  color: var(--button); /* Exemple de couleur */
  text-decoration: none;
  cursor: pointer;
}

.tags:hover {
  text-decoration: underline;
}
</style>
