<template>
  <div class="fiiyd-icon">
    <div class="container">
      <ul>
        <li>
          <router-link to="/fiiyd">
            <HomeFii />
          </router-link>
        </li>
        <li>
          <router-link to="/fiiyd/notifications">
            <div class="bell">
              <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
              <BellFii />
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/fiiyd/myposts">
            <PostsFii />
          </router-link>
        </li>
        <li>
          <router-link to="/fiiyd/trends">
            <Trends />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapState } from "vuex";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import Trends from "../icons/fiiyd/Trends.vue";

export default {
  name: "tabs-fiiyd",
  components: {
    BellFii,
    HomeFii,
    PostsFii,
    Trends,
  },
  computed: {
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length;
    },
  },
};
</script>

<style scoped>
.fiiyd-icon ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding: 15px 0 5vh 0;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}
</style>
