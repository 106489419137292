import axios from "../../plugins/axios";
import router from "../../router";

const state = {
  token: localStorage.getItem('token') || null,
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  REMOVE_TOKEN(state) {
    state.token = null;
  },
};

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await axios.post("/auth/local", credentials);
      const token = response.data.jwt;

      commit("SET_TOKEN", token);
      localStorage.setItem('token', token);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async logout({ commit }) {
    try {
      localStorage.removeItem('token');
      commit("REMOVE_TOKEN");
      router.push("/");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async checkEmail(_, email) {
    try {
      const response = await axios.get(`/users?filters[email][$eq]=${email}`);
      return response.data.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
