<template>
  <div class="container c-sourate">
    <div class="sourate">
      <div class="back">
        <Back :link="'/coran'" />
      </div>
      <div class="loading" v-if="loading">
        <Loading />
      </div>
      <div class="after-load" v-else>
        <div class="recitateur">
          <Recitateur @singer-changed="handleSingerChange" :defaultSinger="selectedSinger" />
        </div>
        <h1>{{ topicName }}</h1>
        <div class="lists-ayahs" ref="ayahsContainer">
          <AyahsSujet
            v-for="(ayah, index) in ayahs"
            :key="ayah.id"
            :options="options"
            :idsourate="getSourateNumero(ayah)"
            :surah="getSourateName(ayah)"
            :id="ayah.id"
            :index="index + 1"
            :idVerse="ayah.id"
            :numero="ayah.attributes.numero"
            :arabe="ayah.attributes.arabe"
            :fr="ayah.attributes.fr"
            :tajweed="ayah.attributes.Tajweed"
            :tafsir="ayah.attributes.tafsir"
            :phonetique="ayah.attributes.phonetique"
            :playAr="ayah.playAr"
            :playFr="ayah.playFr"
            :reciter="selectedSinger"
            @playAudioSolo="playAudioSolo"
          />
        </div>
        <div class="charity">
          <Charity />
        </div>
      </div>
      <audio ref="audioPlayer" controls hidden></audio>
      <audio ref="audioPlayerAll" controls hidden></audio>
    </div>
  </div>
  <div class="opt">
    <div class="cont-options">
      <OptionsTopic
        :initial-options="options"
        @update:initialOptions="updateOptions"
        :playAllAr="playAllAr"
        :playAllFr="playAllFr"
        :playAllArFr="playAllArFr"
        @playAllAudio="playAllAudio"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import Back from "../../components/navigation/Back.vue";
import AyahsSujet from "../../components/coran/AyahsSujet.vue";
import Recitateur from "../../components/coran/Recitateur.vue";
import OptionsTopic from "../../components/coran/OptionsTopic.vue";
import Charity from "../../components/global/Charity.vue";
// import { Head } from "@vueuse/head";

export default {
  name: "topic-view",
  components: {
    AyahsSujet,
    OptionsTopic,
    Loading,
    Recitateur,
    // Head,
    Charity,
    Back,
  },
  data() {
    return {
      displayV: false,
      options: {
        lang: true,
        arabic: true,
        phonetic: true,
        tafsir: false,
        tajweed: false,
      },
      ayahs: [],
      loading: true,
      playAllAr: false,
      playAllFr: false,
      playAllArFr: false,
      playingAllAyahs: false,
      currentAyahPlaying: null,
      currentLanguagePlaying: null,
      ayahsChecksList: [],
      ayahsCheckId: [],
      selectedSinger: localStorage.getItem("singer") || "Hussary",
      currentAudioUrl: "",
      lastAudio: null,
      currentVerseIndex: 0,
      isPlayingAll: false,
      currentLangAll: "",
    };
  },
  computed: {
    topicId() {
      return this.$route.params.id;
    },
    ...mapState({
      currentTopic: state => state.topics.currentTopic,
      topicAyahs: state => state.topics.currentTopicAyahs,
      isLoading: state => state.topics.isFetchingTopic
    }),
    topicName() {
      return this.currentTopic?.attributes?.name || "Sujet";
    }
  },
  methods: {
    ...mapActions({
      fetchTopicAyahs: "topics/fetchTopicAyahs"
    }),
    getSourateName(ayah) {
      return ayah.attributes?.sourate?.data?.attributes?.Phonetique || "Sourate";
    },
    getSourateNumero(ayah) {
      return ayah.attributes?.sourate?.data?.attributes?.Numero || 1;
    },
    getAyahsCount() {
      return this.ayahs.length || 0;
    },
    handleSingerChange(singer) {
      this.selectedSinger = singer;
      localStorage.setItem("singer", singer);
    },
    updateOptions(newOptions) {
      this.options = { ...newOptions };
    },
    playAudioSolo(numero, lang) {
      const reciter =
        lang === "ar"
          ? this.selectedSinger || "Hussary"
          : `${this.$t("reciterSurah")}`;
          
      // Trouver l'ayah correspondant
      const ayahIndex = this.ayahs.findIndex(
        a => a.attributes.numero == numero
      );
      
      if (ayahIndex === -1) return;
      
      const ayah = this.ayahs[ayahIndex];
      const sourateNumero = this.getSourateNumero(ayah);
      
      const newAudioUrl = this.constructAudioUrl(
        reciter,
        sourateNumero,
        lang,
        numero
      );
      
      const audioPlayer = this.$refs.audioPlayer;
      this.lastAudio = numero;

      this.stopAudioAll();

      if (this.currentAudioUrl === newAudioUrl && !audioPlayer.paused) {
        this.pauseAudioSolo();
        this.updatePlayState(numero, false, lang);
      } else if (this.currentAudioUrl === newAudioUrl && audioPlayer.paused) {
        this.resumeAudioSolo();
        this.updatePlayState(numero, true, lang);
      } else {
        this.currentAudioUrl = newAudioUrl;
        this.playAudioUnique(newAudioUrl, numero, lang);
      }
    },

    constructAudioUrl(reciter, sourateNumero, lang, versetNumero) {
      return `https://audio.doonun.com/sourates/${lang}/${reciter}/${sourateNumero}/${versetNumero}.mp3`;
    },

    playAudioUnique(url, versetNumero, lang) {
      this.pauseAudioAll();
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.src = url;
        audioPlayer.play();
        audioPlayer.onended = () => {
          this.updatePlayState(versetNumero, false, lang);
          this.currentAudioUrl = "";
        };
        this.updatePlayState(versetNumero, true, lang);
      }
    },

    pauseAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer && !audioPlayer.paused) {
        audioPlayer.pause();
      }
    },

    resumeAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer && audioPlayer.paused) {
        audioPlayer.play().catch((error) => {
          console.error("Error resuming audio:", error);
        });
      }
    },

    stopAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.src = "";
        this.currentAudioUrl = "";
        this.updatePlayState(this.lastAudio, false);
      }
    },

    updatePlayState(numero, isPlaying, lang) {
      this.ayahs = this.ayahs.map((ayah) => {
        if (ayah.attributes.numero === numero) {
          return {
            ...ayah,
            playAr: lang === "ar" ? isPlaying : false,
            playFr: lang === this.$t("lang") ? isPlaying : false,
          };
        }
        return { ...ayah, playAr: false, playFr: false };
      });
      if (!isPlaying) {
        this.currentAudioUrl = "";
      }
    },

    stopAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll) {
        audioPlayerAll.pause();
        audioPlayerAll.src = "";
        this.currentVerseIndex = 0;
        this.isPlayingAll = false;
        this.currentLangAll = "";
        this.updateGroupPlayState(false);
      }
    },

    pauseAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll && !audioPlayerAll.paused) {
        audioPlayerAll.pause();
      }
    },

    updateGroupPlayState(isPlaying, lang) {
      this.playAllAr = lang === "ar" ? isPlaying : false;
      this.playAllFr = lang === this.$t("lang") ? isPlaying : false;
      this.playAllArFr = lang === "ar-fr" ? isPlaying : false;
    },
    
    async playAllAudio(lang) {
      if (this.isPlayingAll && this.currentLangAll === lang) {
        this.pauseAudioAll();
        this.isPlayingAll = false;
        this.updateGroupPlayState(false, lang);
        return;
      } else if (!this.isPlayingAll && this.currentLangAll === lang) {
        this.resumeAudioAll();
        this.isPlayingAll = true;
        this.updateGroupPlayState(true, lang);
        return;
      }

      this.stopAudioSolo();
      this.currentVerseIndex = this.lastAudio ? this.ayahs.findIndex(
        a => a.attributes.numero == this.lastAudio
      ) : 0;
      
      if (this.currentVerseIndex < 0) this.currentVerseIndex = 0;
      
      this.isPlayingAll = true;
      this.currentLangAll = lang;
      this.updateGroupPlayState(true, lang);

      await this.playNextVerse(lang);
    },
    
    async playNextVerse(lang) {
      if (
        this.currentVerseIndex === undefined ||
        this.currentVerseIndex >= this.ayahs.length
      ) {
        this.lastAudio = 0;
        this.currentVerseIndex = 0;
        this.stopAudioAll();
        return;
      }
      
      const reciter =
        lang === "ar"
          ? this.selectedSinger || "Hussary"
          : `${this.$t("reciterSurah")}`;
          
      const currentVerse = this.ayahs[this.currentVerseIndex];

      if (!currentVerse) {
        console.error("currentVerse is undefined");
        return;
      }

      const numero = currentVerse.attributes.numero;
      const sourateNumero = this.getSourateNumero(currentVerse);

      // Faire défiler vers l'ayah en cours de lecture
      if (document.getElementById(numero)) {
        document.getElementById(numero).scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      if (lang === "ar-fr") {
        const arAudioUrl = this.constructAudioUrl(
          this.selectedSinger || "Hussary",
          sourateNumero,
          "ar",
          numero
        );
        await this.playAudioGroup(arAudioUrl);
        if (!this.isPlayingAll) return;

        const frAudioUrl = this.constructAudioUrl(
          `${this.$t("reciterSurah")}`,
          sourateNumero,
          `${this.$t("lang")}`,
          numero
        );
        await this.playAudioGroup(frAudioUrl);
      } else {
        const audioUrl = this.constructAudioUrl(
          reciter,
          sourateNumero,
          lang,
          numero
        );
        await this.playAudioGroup(audioUrl);
      }

      this.currentVerseIndex++;
      this.playNextVerse(lang);
    },
    
    playAudioGroup(url) {
      return new Promise((resolve) => {
        const audioPlayerAll = this.$refs.audioPlayerAll;
        this.pauseAudioSolo();
        audioPlayerAll.src = url;
        audioPlayerAll.play();
        audioPlayerAll.onended = resolve;
      });
    },
    
    resumeAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll && audioPlayerAll.paused) {
        audioPlayerAll.play().catch((error) => {
          console.error("Error resuming audio:", error);
        });
      }
    },
    loadTopicAyahs() {
      this.loading = true;
      this.fetchTopicAyahs(this.topicId).then(() => {
        if (this.topicAyahs && this.topicAyahs.length > 0) {
          // Préparer les ayahs avec les propriétés de lecture
          this.ayahs = this.topicAyahs.map(ayah => ({
            ...ayah,
            playAr: false,
            playFr: false
          }));
        } else {
          this.ayahs = [];
        }
        this.loading = false;
      }).catch(error => {
        console.error("Erreur lors du chargement des ayahs:", error);
        this.loading = false;
      });
    }
  },
  created() {
    this.loadTopicAyahs();
  },
  watch: {
    topicId() {
      // Recharger les ayahs si l'ID du topic change
      this.loadTopicAyahs();
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  border-radius: 0 0 10px 10px;
}

.c-sourate {
  /* position: relative; */
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
}

.sourate {
  position: relative;
  padding: 100px 0 60px 0;
  display: block;
  margin: 70px auto 0 auto;
  width: 100%;
  min-height: 100vh;
  background: var(--card);
  border-radius: 6px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}

.recitateur {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  font-size: clamp(20px, 8vw, 30px);
  font-weight: 600;
}

.toggle-v {
  display: flex;
  justify-content: center;
}

.lists-ayahs {
  margin-top: 60px;
}

.opt {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky !important;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px 0px 100px 0px;
  padding: 0 20px;
}

.cont-options {
  display: block;
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  background: var(--truncated);
}

.charity {
  width: 100%;
  display: flex;
  margin: 20px auto;
  justify-content: center;
}

@media (max-width: 900px) {
  .container {
    background: var(--card);
  }

  .opt {
    background: var(--truncated);
  }

  .sourate {
    padding: 100px 0 30px 0;
    margin-top: 0;
  }

  .back {
    margin-top: 80px;
    margin-left: 0;
  }
}
</style>