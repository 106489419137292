<template>
  <div class="profil">
    <Head>
      <meta name="robots" content="Noindex, nofollow" />
      <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
    </Head>
    <Top @componentChange="handleComponentChange" :userId="userId" />
    <div class="container">
      <div class="navigation">
        <ul>
          <li>
            <div class="components" @click="changeComponent('Cours')">
              <Courses /> <span>{{ $t("courseComp") }}</span>
            </div>
          </li>
          <li>
            <div class="components" @click="changeComponent('Sourates')">
              <Favoris /> <span>{{ $t("surahsComp") }}</span>
            </div>
          </li>
          <li>
            <div class="components" @click="changeComponent('VersetsComponents')">
              <Versets />
              <span>{{ $t("versesComp") }}</span>
            </div>
          </li>
          <li>
            <div class="components" @click="changeComponent('Livres')">
              <Book />
              <span>{{ $t("booksComp") }}</span>
            </div>
          </li>
          <li>
            <div class="components" @click="changeComponent('DuaasComponents')">
              <Duaas />
              <span>{{ $t("duaasComp") }}</span>
            </div>
          </li>
          <!-- <li>
            <div class="components" @click="changeComponent('NotesComponents')">
              <Note />
              <span>{{ $t("notesComp") }}</span>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="box-component">
        <div v-if="isLoading" class="loading-container">
          <Loading />
          <p>{{ $t("loading") }}</p>
        </div>
        <component 
          v-else
          :is="currentComponent" 
          :key="componentKey"
          :readonly="!isProfileOwner" 
          :userId="parseInt(userId)"
          @data-loaded="handleDataLoaded"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Importation des composants de navigation
import Top from "../../components/Profil/Top.vue";
import Loading from "../../components/navigation/Loading.vue";

// Importation des composants d'icônes
import Courses from "../../components/icons/Courses.vue";
import Favoris from "../../components/icons/Favoris.vue";
import Versets from "../../components/icons/Versets.vue";
import Book from "../../components/icons/Book.vue";
import Duaas from "../../components/icons/Duaas.vue";
// import Note from "../../components/icons/Note.vue";

// Importation des composants de profil
import Cours from "../../components/Profil/Cours.vue";
import DuaasComponents from "../../components/Profil/Duaas.vue";
import NotesComponents from "../../components/Profil/Notes.vue";
import Sourates from "../../components/Profil/Sourates.vue";
import Livres from "../../components/Profil/Livres.vue";
import VersetsComponents from "../../components/Profil/Versets.vue";
import Parametres from "../../components/Profil/Parametres.vue";
import Edit from "../../components/Profil/Edit.vue";
import Themes from "../../components/Profil/Themes.vue";
import Reseaux from "../../components/Profil/Reseaux.vue";
import Abonnement from "../../components/Profil/Abonnement.vue";
import Testimonials from "../../components/Profil/Testimonials.vue";
import Avatar from "../../components/Profil/Avatar.vue";

// Importation de Head
import { Head } from "@vueuse/head";

export default {
  name: "profil-user",
  components: {
    Top,
    Courses,
    Favoris,
    Versets,
    Book,
    Duaas,
    DuaasComponents,
    Sourates,
    Livres,
    VersetsComponents,
    Cours,
    // Note,
    NotesComponents,
    Parametres,
    Edit,
    Themes,
    Reseaux,
    Abonnement,
    Avatar,
    Testimonials,
    Loading,
    Head,
  },
  props: {
    initialComponent: {
      type: String,
      default: "Cours",
    },
  },
  data() {
    return {
      currentComponent: this.initialComponent,
      isLoading: true, // Commencer avec le chargement actif
      componentKey: 0, // Clé pour forcer le rendu du composant
    };
  },
  computed: {
    ...mapGetters("user", ["isProfileOwner"]),
    userId() {
      return this.$route.params.id;
    },
    allowedComponents() {
      // Composants accessibles à tous les utilisateurs
      const publicComponents = [
        "Cours", 
        "Sourates", 
        "VersetsComponents", 
        "Livres", 
        "DuaasComponents", 
        "NotesComponents"
      ];
      
      // Composants accessibles uniquement au propriétaire du profil
      const ownerOnlyComponents = [
        "Parametres", 
        "Edit", 
        "Themes", 
        "Reseaux", 
        "Abonnement", 
        "Avatar", 
        "Testimonials"
      ];
      
      return this.isProfileOwner ? [...publicComponents, ...ownerOnlyComponents] : publicComponents;
    }
  },
  mounted() {
    // Pour le premier chargement, définir un délai maximum avant de désactiver le chargement
    this.setLoadingTimeout();
  },
  methods: {
    setLoadingTimeout() {
      // Définir un délai maximum de 5 secondes pour le chargement
      setTimeout(() => {
        if (this.isLoading) {
          console.log("Le délai maximum de chargement est atteint, désactivation forcée du loader");
          this.isLoading = false;
        }
      }, 5000);
    },
    changeComponent(componentName) {
      // Vérifier si le composant est accessible à l'utilisateur actuel
      if (this.allowedComponents.includes(componentName)) {
        console.log(`Changement vers le composant ${componentName}, activation du chargement`);
        this.currentComponent = componentName;
        this.isLoading = true;
        this.componentKey++; // Incrémentation de la clé pour forcer le rendu
        this.scrollToH3();
        
        // Définir un délai maximum pour le chargement
        this.setLoadingTimeout();
      }
    },
    scrollToH3() {
      const target = document.getElementById("scroll-target");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleComponentChange(newComponent) {
      this.changeComponent(newComponent);
    },
    handleDataLoaded() {
      console.log("Événement data-loaded reçu dans Profil.vue");
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.box-component {
  flex: 1;
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
}

.loading-container {
  text-align: center;
  margin-top: 50px;
}

.loading-container p {
  margin-top: 20px;
  font-size: 14px;
  color: var(--text);
}

.navigation ul {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}

.navigation ul li .components {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.2s ease-in;
}
.navigation ul li .components span {
  font-size: 14px;
  line-height: 0;
}

.components:hover span {
  font-weight: 400;
  color: var(--button);
}

@media (max-width: 800px) {
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--card);
    min-height: 50px;
    width: 100%;
    z-index: 4;
    padding: 20px 30px 5vh 30px;
  }

  .navigation ul {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .navigation ul li .components span {
    display: none;
  }
}
</style>
