<template>
  <Head>
    <title>Fiiyd - Réseau social musulman</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <div class="boites">
      <div class="content-with-sidebars">
        <div class="left">
          <div class="fiiyd-icon">
            <div class="icons">
              <ul>
                <li>
                  <router-link to="/fiiyd">
                    <HomeFii />
                    <span>{{ $t("feed") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/notifications">
                    <div class="bell">
                      <span v-if="fiiydsCount >= 1">{{ fiiydsCount }}</span>
                      <BellFii />
                    </div>
                    <span>{{ $t("notif") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/fiiyd/myposts">
                    <PostsFii />
                    <span>{{ $t("myposts") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle">
          <h1>Notifications</h1>
          <div class="size-box-v">
            <div class="fil">
              <div class="loading" v-if="isLoading">
                <Loading />
              </div>

              <div v-else class="lists-notifs">
                <div
                  v-for="(fiiyd, index) in fiiyds"
                  :key="index"
                  class="notification-card"
                >
                  <router-link
                    v-if="fiiyd.attributes.like && fiiyd.attributes.posts?.data && !fiiyd.attributes.comment?.data"
                    :to="`/fiiyd/${fiiyd.attributes.posts.data.attributes.url}`"
                    class="notification-link"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellFav /> 
                        </span>
                        <div class="notification-text">
                          <div class="user-avatars">
                            <!-- Avatar principal de l'utilisateur qui a aimé -->
                            <div class="main-avatar" v-if="fiiyd.attributes.user_who_liked?.data">
                              <img 
                                v-if="fiiyd.attributes.user_who_liked.data.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${fiiyd.attributes.user_who_liked.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || fiiyd.attributes.user_who_liked.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${fiiyd.attributes.user_who_liked.data.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                            <!-- Autres avatars des personnes qui ont aimé -->
                            <div 
                              v-for="(user, userIndex) in getOtherUsers(fiiyd.attributes.posts?.data?.attributes?.users_posts_favs?.data || [], fiiyd.attributes.user_who_liked?.data?.id, 4)"
                              :key="`like-avatar-${userIndex}`"
                              class="secondary-avatar"
                              :style="{ zIndex: 10 - userIndex, marginLeft: '-12px' }"
                            >
                              <img 
                                v-if="user.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${user.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                          </div>
                          <p>
                            {{ formatInteractionMessage(fiiyd.attributes.name_user, getOtherUsersCount(fiiyd.attributes.posts?.data?.attributes?.users_posts_favs?.data || [], fiiyd.attributes.user_who_liked?.data?.id), 'like-post') }}
                          </p>
                        </div>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.posts.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-else-if="
                      fiiyd.attributes.like && fiiyd.attributes.comment?.data
                    "
                    :to="`/fiiyd/comment/${fiiyd.attributes.comment.data.attributes.url}`"
                    class="notification-link"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellFav />
                        </span>
                        <div class="notification-text">
                          <div class="user-avatars">
                            <!-- Avatar principal de l'utilisateur qui a aimé -->
                            <div class="main-avatar" v-if="fiiyd.attributes.user_who_liked?.data">
                              <img 
                                v-if="fiiyd.attributes.user_who_liked.data.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${fiiyd.attributes.user_who_liked.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || fiiyd.attributes.user_who_liked.data.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${fiiyd.attributes.user_who_liked.data.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                            <!-- Autres avatars des personnes qui ont aimé -->
                            <div 
                              v-for="(user, userIndex) in getOtherUsers(fiiyd.attributes.comment?.data?.attributes?.users_comments_favs?.data || [], fiiyd.attributes.user_who_liked?.data?.id, 4)"
                              :key="`like-avatar-${userIndex}`"
                              class="secondary-avatar"
                              :style="{ zIndex: 10 - userIndex, marginLeft: '-12px' }"
                            >
                              <img 
                                v-if="user.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${user.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                          </div>
                          <p>
                            {{ formatInteractionMessage(fiiyd.attributes.name_user, getOtherUsersCount(fiiyd.attributes.comment?.data?.attributes?.users_comments_favs?.data || [], fiiyd.attributes.user_who_liked?.data?.id), 'like-comment') }}
                          </p>
                        </div>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.comment.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-else-if="
                      fiiyd.attributes.commentaire &&
                      fiiyd.attributes.posts?.data &&
                      !fiiyd.attributes.comment?.data
                    "
                    :to="`/fiiyd/${fiiyd.attributes.posts?.data?.attributes?.url || ''}`"
                    class="notification-link"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellComment />
                        </span>
                        <div class="notification-text">
                          <div class="user-avatars">
                            <!-- Avatar principal de l'utilisateur qui a commenté -->
                            <div class="main-avatar" v-if="fiiyd.attributes.user_who_commented?.data && fiiyd.attributes.user_who_commented.data.length > 0">
                              <img 
                                v-if="fiiyd.attributes.user_who_commented.data[0]?.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${fiiyd.attributes.user_who_commented.data[0].attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || fiiyd.attributes.user_who_commented.data[0].attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${fiiyd.attributes.user_who_commented.data[0].attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                            <div class="main-avatar" v-else>
                              <div class="avatar-placeholder"></div>
                            </div>
                            <!-- Autres avatars des personnes qui ont commenté -->
                            <div 
                              v-for="(user, userIndex) in getOtherUsers(fiiyd.attributes?.posts?.data?.attributes?.users_posts_comments?.data || [], fiiyd.attributes?.user_who_commented?.data?.id, 4)"
                              :key="`comment-avatar-${userIndex}`"
                              class="secondary-avatar"
                              :style="{ zIndex: 10 - userIndex, marginLeft: '-12px' }"
                            >
                              <img 
                                v-if="user.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${user.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                          </div>
                          <p>
                            {{ formatInteractionMessage(fiiyd.attributes.name_user, getOtherUsersCount(fiiyd.attributes?.posts?.data?.attributes?.users_posts_comments?.data || [], fiiyd.attributes?.user_who_commented?.data?.id), 'comment-post') }}
                          </p>
                        </div>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.posts.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                  <router-link
                    v-else-if="
                      fiiyd.attributes.commentaire &&
                      fiiyd.attributes.comment?.data &&
                      !fiiyd.attributes.posts?.data
                    "
                    :to="`/fiiyd/${fiiyd.attributes.comment?.data?.attributes?.url || ''}`"
                    class="notification-link"
                  >
                    <div class="notification">
                      <div class="event">
                        <span>
                          <BellComment />
                        </span>
                        <div class="notification-text">
                          <div class="user-avatars">
                            <!-- Avatar principal de l'utilisateur qui a commenté -->
                            <div class="main-avatar" v-if="fiiyd.attributes.user_who_commented?.data && fiiyd.attributes.user_who_commented.data.length > 0">
                              <img 
                                v-if="fiiyd.attributes.user_who_commented.data[0]?.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${fiiyd.attributes.user_who_commented.data[0].attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || fiiyd.attributes.user_who_commented.data[0].attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${fiiyd.attributes.user_who_commented.data[0].attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                            <div class="main-avatar" v-else>
                              <div class="avatar-placeholder"></div>
                            </div>
                            <!-- Autres avatars des personnes qui ont commenté -->
                            <div 
                              v-for="(user, userIndex) in getOtherUsers(fiiyd.attributes?.comment?.data?.attributes?.users_comments_comments?.data || [], fiiyd.attributes?.user_who_commented?.data?.id, 4)"
                              :key="`comment-comment-avatar-${userIndex}`"
                              class="secondary-avatar"
                              :style="{ zIndex: 10 - userIndex, marginLeft: '-12px' }"
                            >
                              <img 
                                v-if="user.attributes?.avatar?.data?.attributes?.Avatar?.data?.attributes?.formats"
                                :src="`https://cp.doonun.com${user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.thumbnail?.url || user.attributes.avatar.data.attributes.Avatar.data.attributes.formats.small?.url}`"
                                :alt="`Avatar de ${user.attributes.username || 'utilisateur'}`"
                                class="avatar-img"
                              />
                              <div v-else class="avatar-placeholder"></div>
                            </div>
                          </div>
                          <p>
                            {{ formatInteractionMessage(fiiyd.attributes.name_user, getOtherUsersCount(fiiyd.attributes?.comment?.data?.attributes?.users_comments_comments?.data || [], fiiyd.attributes?.user_who_commented?.data?.id), 'comment-comment') }}
                          </p>
                        </div>
                      </div>

                      <p class="content">
                        {{ fiiyd.attributes.comment.data.attributes.Content }}
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <Trends />
        </div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <TabsFiiyd />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import HomeFii from "../../components/icons/fiiyd/Home.vue";
import BellFii from "../../components/icons/fiiyd/Bell.vue";
import BellComment from "../../components/icons/fiiyd/BellComment.vue";
import BellFav from "../../components/icons/fiiyd/BellFav.vue";
import PostsFii from "../../components/icons/fiiyd/Posts.vue";
import Trends from "../../components/fiiyd/Trends.vue";
import TabsFiiyd from "../../components/fiiyd/TabsFiiyd.vue";
import { Head } from "@vueuse/head";

export default {
  name: "fiiyd-d",
  components: {
    Loading,
    HomeFii,
    BellFii,
    Trends,
    PostsFii,
    BellFav,
    BellComment,
    TabsFiiyd,
    Head,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("fiiyd", ["fiiyds"]),
    fiiydsCount() {
      return this.$store.state.fiiyd.fiiyds.length;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("fiiyd", ["fetchFiiyds", "markFiiydsAsRead"]),
    getOtherUsers(users, userId, limit) {
      if (!users || !Array.isArray(users)) return [];
      
      // Afficher la structure des données pour le débogage
      console.log("users data:", users);
      
      // Filtrer les utilisateurs uniques par ID (pour éviter les doublons)
      const uniqueUsers = users.filter((user, index, self) => 
        index === self.findIndex(u => u.id === user.id)
      );
      
      // Exclure l'utilisateur principal s'il est présent
      const otherUsers = uniqueUsers.filter(user => user.id !== userId).slice(0, limit);
      console.log("other users:", otherUsers);
      
      return otherUsers;
    },
    getOtherUsersCount(users, userId) {
      if (!users || !Array.isArray(users)) return 0;
      
      // Filtrer les utilisateurs uniques par ID (pour éviter les doublons)
      const uniqueUsers = users.filter((user, index, self) => 
        index === self.findIndex(u => u.id === user.id)
      );
      
      // Exclure l'utilisateur principal et compter les autres
      return uniqueUsers.filter(user => user.id !== userId).length;
    },
    formatInteractionMessage(name, count, type) {
      if (!name) return ""; // Protection contre les valeurs nulles
      
      console.log("formatInteractionMessage:", { name, count, type });
      
      // Pour les likes
      if (type === "like-post") {
        if (count === 0) {
          return `${name} a aimé votre post`;
        } else if (count === 1) {
          return `${name} et 1 autre personne ont aimé votre post`;
        } else {
          return `${name} et ${count} autres personnes ont aimé votre post`;
        }
      } 
      // Pour les likes de commentaires
      else if (type === "like-comment") {
        if (count === 0) {
          return `${name} a aimé votre commentaire`;
        } else if (count === 1) {
          return `${name} a aimé votre commentaire`;
        } else {
          return `${name} et ${count} autres personnes ont aimé votre commentaire`;
        }
      } 
      // Pour les commentaires de posts
      else if (type === "comment-post") {
        if (count === 0) {
          return `${name || "Un utilisateur"} a commenté votre post`;
        } else if (count === 1) {
          return `${name || "Un utilisateur"} et 1 autre personne ont commenté votre post`;
        } else {
          return `${name || "Un utilisateur"} et ${count} autres personnes ont commenté votre post`;
        }
      } 
      // Pour les commentaires de commentaires
      else if (type === "comment-comment") {
        if (count === 0) {
          return `${name || "Un utilisateur"} a commenté votre commentaire`;
        } else if (count === 1) {
          return `${name || "Un utilisateur"} et 1 autre personne ont commenté votre commentaire`;
        } else {
          return `${name || "Un utilisateur"} et ${count} autres personnes ont commenté votre commentaire`;
        }
      }
      
      // Cas par défaut
      return `${name} a interagi avec votre contenu`;
    },
  },
  async mounted() {
    this.fetchFiiyds().then(() => {
      // Débogage des données de notifications
      console.log("Fiiyds:", this.fiiyds);
      
      // Vérifier les données user_who_commented
      this.fiiyds.forEach((fiiyd, index) => {
        if (fiiyd.attributes && fiiyd.attributes.commentaire) {
          console.log(`Notification commentaire #${index}:`, {
            id: fiiyd.id,
            user_who_commented: fiiyd.attributes.user_who_commented,
            commentaire: fiiyd.attributes.commentaire,
            name_user: fiiyd.attributes.name_user
          });
          
          // Vérifier la structure des données pour les posts
          if (fiiyd.attributes && fiiyd.attributes.posts && fiiyd.attributes.posts.data) {
            console.log(`Structure des attributs posts #${index}:`, {
              users_posts_comments: fiiyd.attributes.posts.data.attributes.users_posts_comments
            });
          }
          
          // Vérifier la structure des données pour les commentaires
          if (fiiyd.attributes && fiiyd.attributes.comment && fiiyd.attributes.comment.data) {
            console.log(`Structure des attributs comment #${index}:`, {
              users_comments_comments: fiiyd.attributes.comment.data.attributes.users_comments_comments
            });
          }
        }
      });
      
      // Debug - afficher la structure de la notification
      this.fiiyds.forEach((notification, index) => {
        console.log(`Notification commentaire #${index}: `, notification.attributes);
        
        if (notification.attributes.user_who_commented) {
          console.log(`Structure de user_who_commented #${index}: `, notification.attributes.user_who_commented);
          if (notification.attributes.user_who_commented.data && notification.attributes.user_who_commented.data.length > 0) {
            console.log(`Premier utilisateur qui a commenté #${index}: `, notification.attributes.user_who_commented.data[0]);
            if (notification.attributes.user_who_commented.data[0].attributes && notification.attributes.user_who_commented.data[0].attributes.avatar) {
              console.log(`Avatar de l'utilisateur qui a commenté #${index}: `, notification.attributes.user_who_commented.data[0].attributes.avatar);
            }
          }
        }
        
        if (notification.attributes.commentaire && notification.attributes.posts?.data) {
          console.log(`Structure des attributs posts #${index}: `, notification.attributes.posts.data.attributes);
        }
      });
      
      this.markFiiydsAsRead();
      this.isLoading = false;
    });
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}
.boites {
  width: 100%;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  min-height: 100vh;
}

.loading {
  display: flex;
  justify-content: center;
}

.content-with-sidebars {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-with-sidebars .left {
  width: 20%;
}

.content-with-sidebars .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(60% - 40px);
  border-right: 1px solid var(--card);
  border-left: 1px solid var(--card);
}

.content-with-sidebars .right {
  width: 20%;
}

.container {
  padding-bottom: 30px;
  min-height: 100vh;
}

.icons ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icons ul li {
  padding-bottom: 20px;
  padding-right: 10px;
}

.icons ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons ul li a span {
  font-size: 20px;
  font-weight: 500;
}

.size-box-v {
  display: flex;
  justify-content: center;
  max-width: 550px;
  width: 100%;
}

.size-box-v .fil {
  width: 100%;
}

.post-input {
  padding: 0 20px;
}

.bell {
  position: relative;
  z-index: 1;
}

.bell span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  padding: 10px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  margin-right: -15px;
  margin-top: -14px;
  font-size: 10px !important;
  font-weight: 700;
}

.notification-card {
  border-radius: 10px;
  transition: background-color 0.2s ease;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--card);
  padding: 0px 20px;
}

.notification-card:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.notification {
  padding: 12px 15px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.notification .event {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
  align-items: flex-start;
}

.notification .event span {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}

.notification .event span svg {
  color: var(--color);
  width: 20px;
  height: 20px;
}

.notification-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  overflow: hidden;
}

.notification-text p {
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 14px;
}

.notification .content {
  margin-top: 8px;
  padding-left: 10px;
  font-weight: 400 !important;
  color: #71767b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
  border-left: 2px solid #f0f0f0;
  margin-left: 25px;
}

.notification-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.user-avatars {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 4px;
  margin-left: 10px;
}

.main-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.secondary-avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: -12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tabs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background: var(--card);
  width: 100%;
  z-index: 10;
}

@media (max-width: 998px) {
  .boites {
    margin-top: 100px;
  }

  .content-with-sidebars .left {
    width: calc(10% - 20px);
  }

  .content-with-sidebars .left span {
    display: none;
  }

  .content-with-sidebars .middle {
    width: calc(75% - 20px);
  }

  .content-with-sidebars .right {
    width: calc(15% - 20px);
    display: none;
  }
}

@media (max-width: 800px) {
  .boites {
    margin-top: 100px;
    padding: 0;
  }
  h1 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .content-with-sidebars .left {
    width: 100%;
    display: none;
  }

  .content-with-sidebars .middle {
    width: 100%;
    border: none;
  }

  .content-with-sidebars .right {
    width: 100%;
    display: none;
  }

  .size-box-v .fil {
    padding: 0;
  }

  .post-input {
    padding: 0;
  }

  .notification-card {
    border-bottom: 1px solid var(--card);
    padding: 15px 0 15px 0;
  }

  .tabs {
    display: block;
  }
}
</style>
