// store/modules/livres.js

import axios from "axios";

const state = {
  livres: [],
  livreUser: [],
  livre: {},
};

const getters = {
  livres: (state) => state.livres,
  livre: (state) => state.livre,
  livreUser: (state) => state.livreUser,
};

const mutations = {
  SET_LIVRES(state, livres) {
    state.livres = livres;
  },
  SET_LIVRE(state, livre) {
    state.livre = livre;
  },
  SET_USER_LIVRE(state, livreUser) {
    state.livreUser = livreUser;
  },
};

const actions = {
  async fetchLivres({ commit, rootGetters }, lang) {
    const userId = rootGetters["user/userId"];
    if (state.livres.length) {
      return;
    }
    try {
      const response = await axios.get("/livres", {
        params: {
          locale: lang,
          fields: ["id", "titre", "desc", "url"],
          // sort: ["publishedAt:desc"],
          populate: {
            cover: {
              fields: ["cover", "formats"],
            },
            pdf: {
              fields: [""],
            },
            users: {
              fields: ["id"],
              filters: {
                id: {
                  $eq: userId,
                },
              },
            },
          },
        },
      });

      commit("SET_LIVRES", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  // Modification de fetchLivre pour accepter un objet { slug, lang }
  async fetchLivre({ commit }, { slug, lang }) {
    try {
      const response = await axios.get("/livres", {
        params: {
          locale: lang, // Ajout de la locale
          filters: {
            url: {
              $eq: slug,
            },
          },
          fields: ["id", "titre", "desc", "url"],

          populate: {
            pdf: {
              fields: ["url"],
            },
          },
        },
      });

      // Vérifiez si des données ont été renvoyées
      if (response.data.data.length > 0) {
        commit("SET_LIVRE", response.data.data[0]);
      } else {
        commit("SET_LIVRE", {}); // Ou gérez le cas où le livre n'est pas trouvé
      }
    } catch (error) {
      console.error(error);
    }
  },

  async fetchUserLivre({ commit, rootGetters }) {
    const userId = rootGetters["user/userId"];
    try {
      const response = await axios.get("/livres", {
        params: {
          filters: {
            users: {
              id: {
                $eq: userId,
              },
            },
          },
          fields: ["id", "titre", "desc", "url"],
          sort: ["publishedAt:desc"],
          populate: {
            cover: {
              fields: ["cover", "formats"],
            },
            users: {
              fields: ["id"],
            },
          },
        },
      });

      // console.log("livre unique : ", response.data.data);
      commit("SET_USER_LIVRE", response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchUserLivreById({ commit }, userId) {
    try {
      const response = await axios.get("/livres", {
        params: {
          filters: {
            users: {
              id: {
                $eq: userId,
              },
            },
          },
          fields: ["id", "titre", "desc", "url"],
          sort: ["publishedAt:desc"],
          populate: {
            cover: {
              fields: ["cover", "formats"],
            },
            users: {
              fields: ["id"],
            },
          },
        },
      });

      commit("SET_USER_LIVRE", response.data.data);
    } catch (error) {
      console.error("Error fetching user books:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
