<!-- verset-doonun.vue -->
<template>
    <div class="box-verset" :id="numero" ref="cardContainer">
      <div class="top">
        <p class="num">{{ numero }}</p>
        <h4 class="title">{{ surah }}</h4>
        <div class="tooltips">
          <div class="icon" @click="openSharePopup">
            <Share />
          </div>
        </div>
      </div>
      <p class="arabe" v-if="options.arabic && !options.tajweed">
        <span
          class="word-arabic"
          v-for="(word, index) in arabe.split(' ')"
          :key="index"
          @click="playWordAudio(index)"
        >
          {{ word }}&nbsp;
        </span>
      </p>
      <p class="tajweed" v-if="options.tajweed" v-html="tajweed"></p>
      <p class="phonetic" v-if="options.phonetic">
        {{ phonetique }}
      </p>
      <p class="trad" v-if="options.lang">
        {{ fr }}
      </p>
      <span v-if="tafsir">
        <p class="tafsir" v-if="showTafsir || options.tafsir">
          <span>Tafsir</span>
          {{ tafsir }}
        </p>
      </span>
      <div class="bottom">
        <div class="left">
          <div class="ar" @click="$emit('playAudioSolo', numero, 'ar')">
            <Play v-if="!playAr" />
            <Pause v-else />
            <img src="https://cp.doonun.com/uploads/ar_d5a49e3344.png" alt="" />
          </div>
          <div class="fr" @click="$emit('playAudioSolo', numero, $t('lang'))">
            <Play v-if="!playFr" />
            <Pause v-else />
            <img :src="$t('flag')" alt="" />
          </div>
          <div
            v-if="tafsir.length > 0"
            class="display-tafsir"
            @click="toggleTafsir"
          >
            <span>Tafsir</span>
          </div>
          <div class="kathir">
            <router-link :to="`/ibn-kathir/${idsourate}/${numero}`">{{
              $t("seekathir")
            }}</router-link>
          </div>
        </div>
        <div class="right">
            <router-link :to="`/sourate/${idsourate}#${numero}`">
              <Right/>
            </router-link>
        </div>
      </div>
  
      <SharePopup
        :message="`${$t('surahM1')} ${numero} ${$t('surahM2')}  ${surah}.`"
        :pageUrl="computedPageUrl"
        ref="sharePopup"
      />
    </div>
  </template>
  
  <script>
  import Play from "../icons/Play.vue";
  import Right from "../icons/Right.vue";
  import Pause from "../icons/Pause.vue";
  import Share from "../icons/Share.vue";
  import SharePopup from "../global/Share.vue";
  
  export default {
    name: "verset-sujet",
    components: {
      Play,
      Share,
      Pause,
      SharePopup,
      Right
    },
    props: {
      // Vos props existantes
      options: {
        type: Object,
        required: true,
      },
      surah: String,
      id: Number,
      index: Number,
      idVerse: Number,
      numero: Number,
      arabe: String,
      fr: String,
      tajweed: String,
      tafsir: String,
      phonetique: String,
      totalverses: String,
      idsourate: Number,
      playAr: Boolean,
      playFr: Boolean,
      reciter: {
        type: String,
        default: "Hussary",
      },
    },
    data() {
      return {
        showTafsir: false,
        currentAudio: null,
      };
    },
    computed: {
      computedPageUrl() {
        return `sourate/${this.idsourate}#${this.numero}`;
      },
    },
    methods: {
      openSharePopup() {
        this.$refs.sharePopup.togglePopup();
      },
      toggleTafsir() {
        this.showTafsir = !this.showTafsir;
      },
      
      playWordAudio(index) {
        const audioPath = this.getWordAudioPath(this.numero, index);
        if (!audioPath) return;
        if (this.currentAudio) {
          this.currentAudio.pause();
          this.currentAudio = null;
        }
        this.currentAudio = new Audio(audioPath);
        this.currentAudio.play();
        this.currentAudio.addEventListener("ended", () => {
          this.currentAudio = null;
        });
      },
      getWordAudioPath(verset, wordIndex) {
        if (!verset) return null;
        const sourateDossier = String(this.idsourate);
        const versetFile = String(this.numero).padStart(3, "0");
        const sourateNumero = String(this.idsourate).padStart(3, "0");
        const versetNumero = String(this.numero).padStart(3, "0");
        const wordNumber = String(wordIndex + 1).padStart(3, "0");
        return `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;
      },
    },
  };
  </script>
  
  <style scoped>
  .box-verset {
    display: block;
    margin: 10px auto 80px auto;
    max-width: 800px;
    border-radius: 6px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .top p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    min-width: 40px;
    min-height: 40px;
    background: var(--body);
    border-radius: 50%;
  }
  .tooltips {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .tooltips .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--body);
    border-radius: 50%;
    cursor: pointer;
  }
  .arabe,
  .tajweed {
    font-family: "uthmanic";
    text-align: end;
    font-size: clamp(20px, 8vw, 30px);
    background: var(--bgV);
    padding: 15px 10px;
    border-radius: 6px;
    border: 2px solid var(--borderV);
  }
  .trad,
  .phonetic {
    font-size: 17px;
    margin-top: 20px;
    text-align: justify;
  }
  .tafsir {
    margin-top: 20px;
    font-size: 17px;
    text-align: justify;
  }
  .tafsir span {
    background: #ff6370;
    padding: 3px 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
  }
  .arabe .word-arabic:hover {
    cursor: pointer;
    color: #ff6370;
  }
  .bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .bottom .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .bottom .left .ar,
  .bottom .left .fr,
  .bottom .left .kathir,
  .bottom .left .display-tafsir {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 5px 6px;
    border: 1px solid var(--body);
    background: var(--body);
    border-radius: 3px;
    min-height: 30px;
    max-height: 30px;
    font-size: 13px;
  }
  .bottom .left .ar:hover,
  .bottom .left .fr:hover,
  .bottom .left .kathir:hover,
  .bottom .left .display-tafsir:hover {
    border: 1px solid var(--button);
  }
  .bottom .left .kathir a {
    display: inline;
    font-size: 13px;
    padding: 10px 7px;
  }
  .btn-finalStep {
    display: flex;
    justify-content: center;
  }
  .btn-finalStep span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: var(--body);
    border-radius: 50px;
    padding: 10px 30px;
    width: auto;
    transition: 0.2s;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 700px) {
    .trad,
    .phonetic {
      text-align: start;
    }
    .tafsir {
      text-align: start;
    }
  }
  @media (max-width: 380px) {
    .bottom .left .kathir a,
    .bottom .left .display-tafsir span {
      font-size: 11px;
    }
  }
  .bottom .left img {
    width: 15px;
    height: 15px;
  }
  </style>
  