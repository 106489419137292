<template>
  <div class="interaction-avatars" :class="type">
    <div class="avatars-list">
      <div v-for="(user, index) in limitedUsers" :key="index" 
           class="avatar-wrapper" 
           :style="{ zIndex: users.length - index }">
        <img 
          v-if="user.avatar" 
          :src="user.avatar" 
          :alt="`Avatar de ${user.username}`"
          class="avatar"
        />
        <div v-else class="avatar avatar-placeholder">
          {{ getUserInitials(user.username) }}
        </div>
      </div>
    </div>
    
    <span v-if="users.length > 0" class="interaction-text">
      <strong>{{ firstUsername }}</strong>
      <template v-if="users.length > 1">
        {{ $t('interaction.and') }} {{ users.length - 1 }} {{ $t('interaction.others') }}
      </template>
      {{ actionText }}
    </span>
    <span v-else class="interaction-text empty">
      {{ $t('interaction.noInteractions') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InteractionAvatars',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'likes',
      validator: (value) => ['likes', 'comments'].includes(value)
    },
    maxDisplay: {
      type: Number,
      default: 5
    }
  },
  computed: {
    limitedUsers() {
      return this.users.slice(0, this.maxDisplay);
    },
    firstUsername() {
      return this.users.length > 0 ? this.users[0].username : '';
    },
    actionText() {
      return this.type === 'likes' 
        ? this.$t('interaction.liked') 
        : this.$t('interaction.commented');
    }
  },
  methods: {
    getUserInitials(username) {
      if (!username) return '?';
      return username.split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .join('')
        .substring(0, 2);
    }
  }
}
</script>

<style scoped>
.interaction-avatars {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.avatars-list {
  display: flex;
  margin-right: 10px;
}

.avatar-wrapper {
  margin-left: -10px;
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
  border: 2px solid var(--color-background);
}

.avatar-wrapper:first-child {
  margin-left: 0;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.avatar-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  color: var(--color-text-light);
  font-size: 10px;
  font-weight: bold;
}

.interaction-text {
  font-size: 0.85em;
  color: var(--color-text);
}

.interaction-text.empty {
  color: var(--color-text-muted);
  font-style: italic;
}

.likes .avatar-wrapper {
  border-color: rgba(255, 0, 86, 0.1);
}

.comments .avatar-wrapper {
  border-color: rgba(54, 162, 235, 0.1);
}
</style>
