// src/router.js
import store from "./store";
import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import History from "./views/History.vue";
import Ranking from "./views/Ranking.vue";
import Coran from "./views/Coran/Coran.vue";
import Video from "./views/Coran/Video.vue";
import Cours from "./views/Cours/Cours.vue";
import Sourate from "./views/Coran/sourate.vue";
import Versets from "./views/Coran/Versets.vue";
import Topic from "./views/Coran/Topic.vue";
import CoursSlug from "./views/Cours/CoursSlug.vue";
import Chapitres from "./views/Cours/Chapitres.vue";
import Livres from "./views/Livres/Livres.vue";
import LivresDetails from "./views/Livres/LivresDetails.vue";
import Kathir from "./views/Coran/Kathir.vue";
import Register from "./views/auth/Register.vue";
import Login from "./views/auth/Login.vue";
import Redirect from "./views/auth/Redirect.vue";
import Oublie from "./views/auth/Oublie.vue";
import Reset from "./views/auth/Reset.vue";
import Profil from "./views/Profil/Profil.vue";
import Duaas from "./views/Duaas/Duaas.vue";
import DuaasSlug from "./views/Duaas/DuaasSlug.vue";
import DuaasTitre from "./views/Duaas/DuaasTitre.vue";
import CategoriesList from "./views/Posts/CategoriesList.vue";
import Actualite from "./views/Posts/Actualite.vue";
import Categories from "./views/Posts/Categories.vue";
import Read from "./views/Posts/Read.vue";
import Horaires from "./views/Priere/Horaires.vue";
import fiiyd from "./views/Fiiyd/Fiiyd.vue";
import fiiydUrl from "./views/Fiiyd/FiiydUrl.vue";
import fiiydComment from "./views/Fiiyd/fiiydComment.vue";
import Notifications from "./views/Fiiyd/Notifications.vue";
import Hashtag from "./views/Fiiyd/Hashtag.vue";
import Trends from "./views/Fiiyd/Trends.vue";
import MyPosts from "./views/Fiiyd/MyPosts.vue";
import Doovie from "./views/Streaming/Doovie.vue";
import DooviePlay from "./views/Streaming/DooviePlay.vue";
import Tarifs from "./views/auth/Tarifs.vue";
import Contact from "./views/Contact.vue";
import Utilisations from "./views/mentions/Utilisations.vue";
import CGV from "./views/mentions/CGV.vue";
import Cookies from "./views/mentions/Cookies.vue";
import PageNotFound from "./views/PageNotFound.vue";
import ServerError from "./views/ServerError.vue";

const routes = [
  {
    path: "/erreur-serveur",
    name: "ServerError",
    component: ServerError,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/histoire",
    name: "History",
    component: History,
  },
  {
    path: "/inscription",
    name: "Register",
    component: Register,
    meta: { guestOnly: true },
  },
  {
    path: "/connexion",
    name: "Login",
    component: Login,
    meta: { guestOnly: true },
  },
  {
    path: "/connect/google/redirect",
    name: "redirect",
    component: Redirect,
    meta: { guestOnly: true },
  },
  {
    path: "/oublie",
    name: "oublie",
    component: Oublie,
    meta: { guestOnly: true },
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset,
    meta: { guestOnly: true },
  },
  {
    path: "/tarifs",
    name: "tarifs",
    component: Tarifs,
    meta: { requiresAuth: true },
  },
  {
    path: "/coran",
    name: "Coran",
    component: Coran,
  },
  {
    path: "/coran/topic/:id",
    name: "Topic",
    component: Topic,
    props: true,
  },
  {
    path: "/video/:id/:subId",
    name: "Video",
    component: Video,
    meta: { requiresAuth: true },
  },
  {
    path: "/versets",
    name: "versets",
    component: Versets,
  },
  {
    path: "/doovie",
    name: "Doovie",
    component: Doovie,
    meta: { requiresAuth: true },
  },
  {
    path: "/doovie/:id",
    name: "Play",
    component: DooviePlay,
    meta: { requiresAuth: true },
  },
  {
    path: "/cours",
    name: "Cours",
    component: Cours,
  },
  {
    path: "/cours/:slug",
    name: "Cours-slug",
    component: CoursSlug,
  },
  {
    path: "/cours/:slug/:id",
    name: "Cours-chapitre",
    component: Chapitres,
  },
  {
    path: "/articles",
    name: "articles",
    component: Actualite,
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesList,
  },
  {
    path: "/categories/:slug",
    name: "categories-slug",
    component: Categories,
  },
  {
    path: "/articles/:slug/:subslug",
    name: "read-slug",
    component: Read,
  },
  {
    path: "/priere",
    name: "horaires",
    component: Horaires,
  },
  {
    path: "/duaas",
    name: "Duaas",
    component: Duaas,
  },
  {
    path: "/duaas/:slug",
    name: "Duaas-slug",
    component: DuaasSlug,
  },
  {
    path: "/duaas/:slug/:subSlug",
    name: "Duaas-sub-slug",
    component: DuaasTitre,
  },
  {
    path: "/livres",
    name: "Livres",
    component: Livres,
    meta: { requiresAuth: true },
  },
  {
    path: "/livres/:slug",
    name: "Livres-name",
    component: LivresDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/profil/:id",
    name: "profil-name",
    component: Profil,
    meta: { requiresAuth: true },
  },
  {
    path: "/sourate/:id",
    name: "Sourate",
    component: Sourate,
  },
  {
    path: "/ibn-kathir/:id/:subId",
    name: "Kathir",
    component: Kathir,
  },
  {
    path: "/fiiyd",
    name: "fiiyd",
    component: fiiyd,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/:slug",
    name: "fiiydurl",
    component: fiiydUrl,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/comment/:slug",
    name: "fiiydcomments",
    component: fiiydComment,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/hashtag/:slug",
    name: "hashtag",
    component: Hashtag,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/notifications",
    name: "notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/trends",
    name: "trends",
    component: Trends,
    meta: { requiresAuth: true },
  },
  {
    path: "/fiiyd/myposts",
    name: "myposts",
    component: MyPosts,
    meta: { requiresAuth: true },
  },
  {
    path: "/classement",
    name: "classement",
    component: Ranking,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/mentions-legales",
    name: "mentions-legal",
    component: Utilisations,
  },
  {
    path: "/cgv",
    name: "cgv",
    component: CGV,
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
  },

  // Route 404 (doit être la dernière)
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Garde de navigation
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["login/isAuthenticated"];

  // Si la route nécessite une authentification
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      // Rediriger non authentifié vers la page de connexion avec redirection après connexion
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  }
  // Si la route est réservée aux invités
  else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (isAuthenticated) {
      // Rediriger authentifié vers la page d'accueil
      next({ name: "Home" });
    } else {
      next();
    }
  }
  // Toutes les autres routes
  else {
    next();
  }
});

export default router;
