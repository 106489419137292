<template>
  <div>
    <h2 id="scroll-target">{{ $t("versesCompTitle") }}</h2>
    <div class="verses">
      <router-link
        v-for="verse in myVersesFavorites"
        :key="verse.id"
        :to="`/sourate/${verse.attributes.sourate.data.attributes.Numero}#${verse.attributes.numero}`"
      >
        <div class="card-vevrse">
          <div class="top">
            <div class="q-top">
              <QuoteTop />
            </div>
            <div class="check" @click.prevent="handleClick(verse.id)">
              <MarkCheck />
            </div>
          </div>
          <div class="middle">
            <p class="fr">{{ verse.attributes.fr }}</p>
            <p class="s">
              {{ verse.attributes.sourate.data.attributes.Phonetique }} :
              {{ verse.attributes.numero }}
            </p>
          </div>
          <div class="q-bottom">
            <QuoteBottom />
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuoteTop from "../icons/QuoteTop.vue";
import QuoteBottom from "../icons/QuoteBottom.vue";
import MarkCheck from "../icons/MarkCheck.vue";

export default {
  name: "versets-profil",
  components: { QuoteTop, QuoteBottom, MarkCheck },
  computed: {
    ...mapState("sourate", ["myVersesFavorites"]),
  },
  methods: {
    ...mapActions("sourate", ["fetchMyVersesFavorites", "updateVerse"]),
    ...mapActions("user", ["removeVerseFromFavorites"]),
    async handleClick(idVerse) {
      // console.log("HandleClick appelé avec l'ID du verset :", idVerse);
      try {
        await this.removeVerseFromFavorites(idVerse);
        await this.updateVerse(idVerse, { isFavorite: false });
        await this.fetchMyVersesFavorites(this.$i18n.locale);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du verset :", error);
      }
    },
  },

  created() {
    this.fetchMyVersesFavorites(this.$i18n.locale);
  },
  watch: {
    "$i18n.locale"() {
      this.fetchMyVersesFavorites(this.$i18n.locale);
    },
  },
};
</script>



<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.verses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.verses a {
  max-width: 600px;
  width: 100%;
}
.card-vevrse {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 30px;
  background: var(--card);
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  min-height: 150px;
  margin-bottom: 10px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.check {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: top;
}

.card-vevrse .middle {
  position: relative;
  z-index: 2 !important;
}

.q-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  background: var(--body);
  border-radius: 6px;
}

.q-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 30px;
  margin-right: 30px;
  z-index: 1;
  max-width: 100px;
  max-height: 100px;
}

.fr {
  font-style: italic;
  text-align: justify;
}

.s {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}

.loading {
  text-align: center;
  margin: 20px 0;
}
</style>
