<template>
    <div class="lists">
      <div class="category-sujets">
        <button
          v-for="(topicTag, index) in topicTags"
          :key="topicTag.id"
          :class="[
            'category-btn',
            { active: selectedCategory === topicTag.attributes.name },
          ]"
          :style="getCategoryStyle(index)"
          @click="openTopicModal(topicTag.id)"
        >
          <Plus />
          {{ topicTag.attributes.name }}
        </button>
      </div>
      <Slider :cible="'category-sujets'" />
  
      <!-- Modal avec glissement vers le bas pour fermer -->
      <div class="modal" v-if="showModal" @click="closeModal">
        <div 
          class="modal-content" 
          @click.stop
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
          :style="modalStyle"
        >
          <!-- Indicateur de glissement -->
          <div class="drag-indicator">
            <div class="drag-indicator-bar"></div>
          </div>
          
          <h2 class="modal-title">{{currentTopicTag.attributes.name}}</h2>
          <div class="topics-list">
            <div 
              v-for="topic in currentTopicTag?.attributes?.topics?.data" 
              :key="topic.id" 
              class="topic-item"
              :style="getTopicItemStyle()"
              @click="goToTopic(topic.id)"
            >
            <span class="name">
              {{ topic.attributes.name }}
              <span class="length">{{ getAyahsCount(topic) }}</span>
            </span>
              <span class="topic-arrow">
                <Right />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Plus from "../icons/Plus.vue"; 
  import Slider from "../global/Slider";
  import Right from "../icons/Right.vue";
  import { mapState, mapActions } from "vuex";
  
  export default {
    name: "SubjectsQuran",
    components: { Slider, Plus, Right },
    data() {
      return {
        selectedCategory: null,
        showModal: false,
        pastelGradients: [
          { color1: '#FFD1DC', color2: '#FFC8DD' }, // Rose pastel
          { color1: '#FFB347', color2: '#FFCC99' }, // Pêche pastel
          { color1: '#98FB98', color2: '#CCFFCC' }, // Vert pastel
          { color1: '#ADD8E6', color2: '#B6E0FF' }, // Bleu pastel
          { color1: '#D8BFD8', color2: '#E6E6FA' }, // Lavande pastel
          { color1: '#FFFACD', color2: '#FAFAD2' }, // Citron pastel
          { color1: '#FFC3A0', color2: '#FFBE7B' }, // Corail pastel
          { color1: '#B0E0E6', color2: '#87CEEB' }, // Bleu ciel pastel
          { color1: '#E6E6FA', color2: '#CCCCFF' }, // Lilas pastel
          { color1: '#FFDAB9', color2: '#FFE4B5' }, // Abricot pastel
          { color1: '#C1FFC1', color2: '#8FD8A0' }, // Vert menthe pastel
          { color1: '#F0E6FF', color2: '#DCBEFF' }, // Violet pastel
          { color1: '#FFDEAD', color2: '#FFE4B5' }, // Crème pastel
          { color1: '#AFEEEE', color2: '#7FFFD4' }, // Turquoise pastel
          { color1: '#FFF0F5', color2: '#FFB6C1' }, // Rose pâle
        ],
        // Variables pour le glissement
        touchStartY: 0,
        touchCurrentY: 0,
        isDragging: false,
        modalTranslateY: 0,
        modalOpacity: 1
      };
    },
    computed: {
      ...mapState({
        topicTags: state => state.topics.topicTags,
        currentTopicTag: state => state.topics.currentTopicTag
      }),
      modalStyle() {
        return {
          transform: `translateY(${this.modalTranslateY}px)`,
          opacity: this.modalOpacity
        };
      },
      currentTopicTagIndex() {
        // Trouver l'index du topic tag courant dans le tableau des topic tags
        if (!this.currentTopicTag || !this.topicTags) return 0;
        
        const index = this.topicTags.findIndex(
          tag => tag.id === this.currentTopicTag.id
        );
        
        return index !== -1 ? index : 0;
      }
    },
    methods: {
      ...mapActions({
        fetchTopicTags: "topics/fetchTopicTags",
        fetchTopicTag: "topics/fetchTopicTag"
      }),
      getCategoryStyle(index) {
        // Utiliser l'index modulo la longueur du tableau pour assurer que chaque bouton a un dégradé
        const gradientIndex = index % this.pastelGradients.length;
        const { color1, color2 } = this.pastelGradients[gradientIndex];
        
        return {
          background: `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`,
          color: '#333' // Texte foncé pour contraster avec les couleurs pastel
        };
      },
      getTopicItemStyle() {
        // Utiliser le même dégradé que le currentTopicTag mais avec une opacité réduite
        const gradientIndex = this.currentTopicTagIndex % this.pastelGradients.length;
        const { color1, color2 } = this.pastelGradients[gradientIndex];
        
        return {
          background: `linear-gradient(135deg, ${color1}20 0%, ${color2}20 100%)`,
          borderRadius: '8px',
          padding: '12px 12px',
          margin: '5px 0'
        };
      },
      async openTopicModal(topicTagId) {
        await this.fetchTopicTag(topicTagId);
        this.showModal = true;
        this.resetModalPosition();
        
        // Mettre à jour la catégorie sélectionnée
        if (this.currentTopicTag) {
          this.selectedCategory = this.currentTopicTag.attributes.name;
        }
      },
      goToTopic(topicId) {
        // Naviguer vers la page Topic avec l'ID du topic
        this.showModal = false;
        this.$router.push({ name: 'Topic', params: { id: topicId } });
      },
      closeModal() {
        this.showModal = false;
        this.resetModalPosition();
      },
      resetModalPosition() {
        this.modalTranslateY = 0;
        this.modalOpacity = 1;
        this.isDragging = false;
      },
      // Gestion du glissement
      handleTouchStart(event) {
        this.touchStartY = event.touches[0].clientY;
        this.isDragging = true;
      },
      handleTouchMove(event) {
        if (!this.isDragging) return;
        
        this.touchCurrentY = event.touches[0].clientY;
        const deltaY = this.touchCurrentY - this.touchStartY;
        
        // Seulement permettre le glissement vers le bas
        if (deltaY > 0) {
          this.modalTranslateY = deltaY;
          // Réduire l'opacité proportionnellement au glissement
          this.modalOpacity = Math.max(0.5, 1 - (deltaY / 500));
        }
      },
      handleTouchEnd() {
        if (!this.isDragging) return;
        
        // Si le glissement est suffisant, fermer la modale
        if (this.modalTranslateY > 100) {
          this.closeModal();
        } else {
          // Sinon, réinitialiser la position avec une animation
          this.modalTranslateY = 0;
          this.modalOpacity = 1;
        }
        
        this.isDragging = false;
      },
      getAyahsCount(topic) {
        // Ajouter cette méthode pour obtenir le compte d'ayahs
        if (topic.attributes && topic.attributes.ayahs && topic.attributes.ayahs.data) {
          return topic.attributes.ayahs.data.length;
        } else {
          // Si les données ne sont pas structurées comme prévu, 
          // afficher un tiret ou valeur par défaut
          return '-';
        }
      },
    },
    created() {
      // Charger les topic tags au chargement du composant
      this.fetchTopicTags();
    }
  };
  </script>
  
  <style scoped>
  .lists {
    margin: 5px 0 15px 0;
    position: relative;
  }
  .category-sujets {
    display: flex;
    justify-content: start;
    gap: 10px;
    overflow-x: auto;
    scrollbar-width: none;
  }
  .category-sujets::-webkit-scrollbar {
    display: none;
  }
  
  .category-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 1.1rem 0.5rem 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
  
  /* Styles pour la modale */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000 !important;
  }
  
  .modal-content {
    background-color: var(--card);
    width: 100%;
    max-width: 600px;
    border-radius: 30px 30px 0 0;
    padding: 0 0 150px 0;
    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: none;
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 1000 !important;
  }
  
  .drag-indicator {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    background-color: var(--card);
  }
  
  .drag-indicator-bar {
    width: 48px;
    height: 6px;
    background-color: var(--body);
    border-radius: 3px;
  }
  
  .modal-title {
    position: sticky;
    top: 12px;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 20px 15px 20px;
    font-size: 20px;
    color: var(--color);
    background-color: var(--card);
  }
  
  .topics-list {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  
  .topic-item {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    gap: 20px;
  }

  .topic-item .name {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .topic-item .name .length {
    font-size: 12px;
    font-weight: 600;
    background-color: var(--body);
    color:var(--color);
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .topic-arrow {
    font-size: 20px;
    transition: all 0.2s ease;
  }

  .topic-item:hover {
    background-color: var(--card);
  }
  
  @media (max-width: 1000px) {
    .category-sujets {
      justify-content: start;
    }
  }
  </style>