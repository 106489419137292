<template>
  <div
    class="calendar-hijri"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div class="navigation">
      <button @click="goToPreviousMonth"><Left /></button>
      <div>
        <div class="months">
          <h3>
            <span
              >{{ monthHijri }} - {{ secondMonthHijri }}
              {{ secondYearHijri }}</span
            >
          </h3>
        </div>
      </div>
      <button @click="goToNextMonth"><Right /></button>
    </div>
    <p class="gregorian">
      {{ monthName }}
      {{ yearMonth }}
    </p>

    <!-- Wrapper pour permettre la transformation lors du swipe -->
    <div
      class="calendar-container"
      :style="{ transform: `translateX(${touchDeltaX}px)` }"
    >
      <!-- Transition pour la partie dynamique du calendrier -->
      <transition :name="transitionName" mode="out-in">
        <div class="calendar" :key="currentMonthKey">
          <div class="weeks">
            <span>{{ $t("dim") }}</span>
            <span>{{ $t("lun") }}</span>
            <span>{{ $t("mar") }}</span>
            <span>{{ $t("mer") }}</span>
            <span>{{ $t("jeu") }}</span>
            <span>{{ $t("ven") }}</span>
            <span>{{ $t("sam") }}</span>
          </div>
          <div class="days-calendar">
            <div
              class="days"
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
            >
              <div
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
                class="day"
                @click="handleDayClick(day)"
                :class="[
                  isCurrentDay(day.gregorian) ? 'current-day' : '',
                  getSpecialHijriClass(day.hijriDay, day.hijriMonth),
                ]"
              >
                <p class="hijri-date">{{ day.hijriDay }}</p>
                <p class="grego">{{ day.gregorian }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Popup Modal -->
    <el-dialog
      class="hijri"
      v-model="dialogTableVisible"
      align-center
      :title="modalTitle"
    >
      <p>{{ modalContent }}</p>
      <div v-if="modalCitation" class="citation">
        <p>
          <strong>{{ modalCitation.type === 'coran' ? 'Coran' : modalCitation.type === 'hadith' ? 'Hadith' : 'Histoire' }}</strong>
          : {{ modalCitation.texte }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UQCal from "../../assets/js/UQCal";
import specialDates from "../../assets/js/months.json";
import Left from "../../components/icons/priere/Left.vue";
import Right from "../../components/icons/priere/Right.vue";
import { ref } from "vue";

export default {
  name: "calendar-hijri",
  components: {
    Left,
    Right,
  },
  data() {
    return {
      specialDates,
      showModal: false,
      modalContent: "",
      modalTitle: "",
      modalCitation: null,
      currentDate: new Date(),
      hijriMonths: [
        "Muharram",
        "Safar",
        "Rabi' al-awwal",
        "Rabi' al-thani",
        "Jumada al-awwal",
        "Jumada al-thani",
        "Rajab",
        "Sha'ban",
        "Ramadan",
        "Shawwal",
        "Dhu al-Qi'dah",
        "Dhu al-Hijjah",
      ],
      // Propriétés pour gérer les touches
      touchStartX: 0,
      touchStartY: 0,
      touchCurrentX: 0,
      touchDeltaX: 0,
      // Propriété pour la transition
      transitionName: "slide-left", // Valeur par défaut
      // Seuil de swipe
      swipeThreshold: 100, // Pixels
      // Direction du swipe
      swipeDirection: null, // 'left' ou 'right'
    };
  },
  setup() {
    const dialogTableVisible = ref(false);

    return {
      dialogTableVisible,
    };
  },
  computed: {
    day() {
      return this.currentDate.getDate();
    },
    month() {
      return this.currentDate.getMonth() + 1;
    },
    year() {
      return this.currentDate.getFullYear();
    },
    monthName() {
      const months = [
        `${this.$t("janM")}`,
        `${this.$t("febM")}`,
        `${this.$t("marM")}`,
        `${this.$t("aprM")}`,
        `${this.$t("mayM")}`,
        `${this.$t("junM")}`,
        `${this.$t("julM")}`,
        `${this.$t("augM")}`,
        `${this.$t("sepM")}`,
        `${this.$t("octM")}`,
        `${this.$t("novM")}`,
        `${this.$t("decM")}`,
      ];
      return months[this.month - 1];
    },
    noDays() {
      // Corrected logic to get the number of days in the current month
      return new Date(this.year, this.month, 0).getDate();
    },
    weeks() {
      const weeks = [];
      let week = [];
      let dayOne = new Date(this.year, this.month - 1, 1).getDay();
      let iterator = 0;

      // Remplir les jours vides initiaux
      while (dayOne > 0) {
        week.push({ gregorian: "", hijriDay: "", hijriMonth: "" });
        dayOne--;
        iterator++;
      }

      for (let i = 1; i <= this.noDays; i++) {
        if (iterator > 6) {
          weeks.push(week);
          week = [];
          iterator = 0;
        }
        const hijriDate = this.convertToHijri(
          new Date(this.year, this.month - 1, i)
        );
        week.push({
          gregorian: i,
          hijriDay: hijriDate.Hday,
          hijriMonth: hijriDate.Hmonth,
        });
        iterator++;
      }

      // Ajouter la dernière semaine si elle contient des jours
      if (week.length > 0) {
        weeks.push(week);
      }

      return weeks;
    },
    monthHijri() {
      // Calculer le mois Hijri pour le début du mois grégorien
      const hijriDate = this.convertToHijri(
        new Date(this.year, this.month - 1, 1)
      );
      return this.hijriMonths[hijriDate.Hmonth - 1];
    },
    yearHijri() {
      // Calculer l'année Hijri pour le début du mois grégorien
      const hijriDate = this.convertToHijri(
        new Date(this.year, this.month - 1, 1)
      );
      return hijriDate.Hyear;
    },
    secondMonthHijri() {
      // Calculer le mois Hijri pour la fin du mois grégorien
      const endOfMonthDate = new Date(this.year, this.month, 0);
      const hijriDate = this.convertToHijri(endOfMonthDate);
      return this.hijriMonths[hijriDate.Hmonth - 1];
    },
    secondYearHijri() {
      // Calculer l'année Hijri pour la fin du mois grégorien
      const endOfMonthDate = new Date(this.year, this.month, 0);
      const hijriDate = this.convertToHijri(endOfMonthDate);
      return hijriDate.Hyear;
    },
    dayMonth() {
      return this.day;
    },
    yearMonth() {
      return this.year;
    },
    // Clé unique pour le mois actuel, utilisée pour le transition
    currentMonthKey() {
      return `${this.year}-${this.month}`;
    },
  },
  methods: {
    convertToHijri(date) {
      // Ensure the date is in the correct format for UQCal
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      const cal = new UQCal(formattedDate);
      return cal.convert();
    },
    isCurrentDay(gregorianDay) {
      const today = new Date();
      return (
        gregorianDay === today.getDate() &&
        this.month === today.getMonth() + 1 &&
        this.year === today.getFullYear()
      );
    },
    getSpecialHijriClass(hijriDay, hijriMonth) {
      hijriDay = parseInt(hijriDay);
      hijriMonth = parseInt(hijriMonth);

      // 10th of Dhu al-Hijjah
      if (hijriMonth === 12 && hijriDay === 10) {
        return "eid-al-adha";
      }

      // First 13 days of Dhu al-Hijjah
      if (hijriMonth === 12 && hijriDay <= 9) {
        return "dhul-hijjah-first-13";
      }

      // 9th and 10th of Muharram
      if (hijriMonth === 1 && (hijriDay === 9 || hijriDay === 10)) {
        return "ashura";
      }

      // All days of Ramadan
      if (hijriMonth === 9 && hijriDay <= 20) {
        return "ramadan";
      }

      // Last 10 days of Ramadan
      if (hijriMonth === 9 && hijriDay >= 21) {
        return "ramadan-last-10";
      }

      // 1st of Shawwal
      if (hijriMonth === 10 && hijriDay === 1) {
        return "eid-al-fitr";
      }

      // 13, 14, 15 of the Hijri month
      if (hijriDay === 13 || hijriDay === 14 || hijriDay === 15) {
        return "white-days";
      }

      if (
        hijriMonth === 1 ||
        hijriMonth === 7 ||
        hijriMonth === 11 ||
        hijriMonth === 12
      ) {
        return "sacred-month";
      }

      return "";
    },
    handleDayClick(day) {
      if (!day.hijriDay || !day.hijriMonth) {
        // Ignore les jours vides
        return;
      }
      
      // Vérifie si le jour est spécial
      const specialClass = this.getSpecialHijriClass(day.hijriDay, day.hijriMonth);
      if (specialClass) {
        // Trouver l'événement correspondant et afficher dans le modal
        this.showModalForSpecialDay(day.hijriDay, day.hijriMonth);
      } else {
        // Si ce n'est pas un jour spécial, ne pas ouvrir le modal
        this.dialogTableVisible = false;
      }
    },

    showModalForSpecialDay(hijriDay, hijriMonth) {
      // Convertir le mois hijri en numéro (1-12)
      const numericMonth = hijriMonth;
      
      // Récupérer le nom du mois hijri (utile pour l'affichage dans le modal si nécessaire)
      // const monthName = this.hijriMonths[numericMonth - 1];
      
      // Trouver l'événement correspondant dans specialDates
      let foundEvent = null;
      
      for (const event of this.specialDates.datesImportantes) {
        // Vérifier si le mois correspond
        let eventMonths = [];
        
        // Vérifier si c'est un des mois sacrés listés
        if (event.date.includes("Muḥarram") && numericMonth === 1) eventMonths.push(1);
        if (event.date.includes("Rajab") && numericMonth === 7) eventMonths.push(7);
        if (event.date.includes("Dhū al-Qaʿdah") && numericMonth === 11) eventMonths.push(11);
        if (event.date.includes("Dhū al-Ḥijjah") && numericMonth === 12) eventMonths.push(12);
        
        // Pour les autres événements, examiner la date spécifique
        if (event.date.includes("Ramaḍān") && numericMonth === 9) {
          // Vérifier les jours de Ramadan
          if (event.date.includes("1-30") || 
              (event.date.includes("1-19") && hijriDay >= 1 && hijriDay <= 19) ||
              (event.date.includes("20-29") && hijriDay >= 20 && hijriDay <= 29) ||
              (event.date.includes("21, 23, 25, 27, 29") && [21, 23, 25, 27, 29].includes(hijriDay))) {
            eventMonths.push(9);
          }
        }
        
        if (event.date.includes("Shawwāl") && numericMonth === 10) {
          // Vérifier les jours de Shawwal
          if (event.date.includes("1-30") || 
              (event.date.includes("13-15") && hijriDay >= 13 && hijriDay <= 15)) {
            eventMonths.push(10);
          }
        }
        
        if (event.date.includes("Muḥarram") && numericMonth === 1) {
          // Vérifier les jours de Muharram
          if (event.date.includes("09-10") && (hijriDay === 9 || hijriDay === 10)) {
            foundEvent = event;
            break;
          }
        }
        
        if (event.date.includes("Dhū al-Ḥijjah") && numericMonth === 12) {
          // Vérifier les jours de Dhul Hijjah
          if ((event.date.includes("01-10") && hijriDay >= 1 && hijriDay <= 10) ||
              (event.date.includes("08") && hijriDay === 8) ||
              (event.date.includes("09") && hijriDay === 9) ||
              (event.date.includes("10") && hijriDay === 10) ||
              (event.date.includes("11") && hijriDay === 11) ||
              (event.date.includes("12") && hijriDay === 12) ||
              (event.date.includes("13") && hijriDay === 13)) {
            foundEvent = event;
            break;
          }
        }
        
        // Si l'événement correspond au mois et au jour
        if (eventMonths.includes(numericMonth) && !foundEvent) {
          foundEvent = event;
        }
      }
      
      // Si un événement est trouvé, afficher ses informations dans le modal
      if (foundEvent) {
        this.modalTitle = foundEvent.nom;
        this.modalContent = foundEvent.explication;
        this.modalCitation = foundEvent.citation;
        this.dialogTableVisible = true;
      } else {
        // Si aucun événement n'est trouvé, ne pas ouvrir le modal
        this.dialogTableVisible = false;
      }
    },

    closeModal() {
      this.dialogTableVisible = false;
      this.modalTitle = "";
      this.modalContent = "";
      this.modalCitation = null;
    },

    goToPreviousMonth() {
      this.transitionName = "slide-right"; // Définir la direction de la transition

      const currentMonth = this.currentDate.getMonth();
      const currentYear = this.currentDate.getFullYear();

      if (currentMonth === 0) {
        // Si on est en janvier
        this.currentDate.setMonth(11); // Aller à décembre de l'année précédente
        this.currentDate.setFullYear(currentYear - 1);
      } else {
        this.currentDate.setMonth(currentMonth - 1);
      }

      this.currentDate = new Date(this.currentDate); // Assure la réactivité
      this.touchDeltaX = 0; // Réinitialiser le delta après le changement de mois
    },

    goToNextMonth() {
      this.transitionName = "slide-left"; // Définir la direction de la transition

      const currentMonth = this.currentDate.getMonth();
      const currentYear = this.currentDate.getFullYear();

      if (currentMonth === 11) {
        // Si on est en décembre
        this.currentDate.setMonth(0); // Aller à janvier de l'année suivante
        this.currentDate.setFullYear(currentYear + 1);
      } else {
        this.currentDate.setMonth(currentMonth + 1);
      }

      this.currentDate = new Date(this.currentDate); // Assure la réactivité
      this.touchDeltaX = 0; // Réinitialiser le delta après le changement de mois
    },

    // Gestion des événements tactiles
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
      this.touchDeltaX = 0; // Réinitialiser le delta au début du toucher
      this.swipeDirection = null; // Réinitialiser la direction
    },
    handleTouchMove(event) {
      const touchCurrentX = event.changedTouches[0].screenX;
      const touchCurrentY = event.changedTouches[0].screenY;
      const deltaX = touchCurrentX - this.touchStartX;
      const deltaY = touchCurrentY - this.touchStartY;

      // Empêcher le défilement vertical si le mouvement est horizontal
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        event.preventDefault();
      }

      this.touchDeltaX = deltaX;
    },
    handleTouchEnd() {
      const finalDeltaX = this.touchDeltaX;
      const threshold = this.swipeThreshold;

      if (finalDeltaX > threshold) {
        // Swipe vers la droite
        this.goToPreviousMonth();
      } else if (finalDeltaX < -threshold) {
        // Swipe vers la gauche
        this.goToNextMonth();
      } else {
        // Revenir à la position initiale
        this.resetSwipe();
      }
    },
    resetSwipe() {
      // Ajoute une transition pour revenir à la position initiale
      this.transitionName = "slide-reset";
      this.touchDeltaX = 0;
    },
  },
};
</script>

<style scoped>
.calendar {
}
.calendar-hijri {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  touch-action: pan-y;
  background: var(--card);
  padding: 10px;
  border-radius: 6px;
}

.navigation {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.gregorian {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: var(--button);
  margin-bottom: 20px;
}

/* Conteneur pour appliquer la transformation lors du swipe */
.calendar-container {
  transition: transform 0.3s ease; /* Transition pour le déplacement */
  position: relative;
}

/* Transition Animations */

.slide-left-enter-active,
.slide-right-enter-active,
.slide-reset-enter-active {
  transition: all 0.5s ease;
}

.slide-left-leave-active,
.slide-right-leave-active,
.slide-reset-leave-active {
  transition: all 0.5s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to,
.slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-to,
.slide-right-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Transition pour le retour en arrière */
.slide-reset-enter-active,
.slide-reset-leave-active {
  transition: transform 0.3s ease;
}

.slide-reset-enter-from,
.slide-reset-leave-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-reset-enter-to,
.slide-reset-leave-from {
  transform: translateX(0);
  opacity: 1;
}

/* Styles du calendrier */
.calendar .weeks {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.weeks span {
  text-align: center;
  padding: 5px 0;
  color: var(--link);
  font-weight: 500;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  justify-content: center;
  margin-bottom: 5px;
}

.day {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  text-align: center;
  background: var(--body);
  border-radius: 8px;
}

.day .hijri-date {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.day .grego {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: var(--button);
}

.current-day {
  font-weight: 700;
  border-radius: 6px;
  background: var(--button);
}

.current-day .hijri-date {
  color: #fff;
}

.current-day .grego {
  color: var(--colorBtn);
}

/* Special Day Styles */
.monday {
  background-color: #f0f8ff;
  border: 1px solid var(--button);
  color: #000;
  border-radius: 6px;
}

.thursday {
  background-color: #ffe4e1;
  border: 1px solid #cd5c5c;
  color: #000;
  border-radius: 6px;
}

.white-days {
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  outline: 1px solid var(--body);
}

.dhul-hijjah-first-13 {
  background-color: #ffa600;
  color: #000;
  border-radius: 6px;
}

.dhul-hijjah-first-13 .grego {
  color: #fff;
}

.eid-al-adha {
  background-color: #ff745c;
  color: #000;
  border-radius: 6px;
}

.eid-al-adha .grego {
  color: #fff;
}

.ashura {
  background-color: #2c9bef;
  color: #000;
  border-radius: 6px;
}

.ashura .grego {
  color: #fff;
}

.ramadan {
  background-color: #28bd7f;
  color: #000;
  border-radius: 6px;
}

.ramadan .grego {
  color: #fff;
}

.ramadan-last-10 {
  background-color: #f367c7;
  color: #000;
  border-radius: 6px;
}

.ramadan-last-10 .grego {
  color: #fff;
}

.eid-al-fitr {
  background-color: #7d49d5;
  color: #000;
  border-radius: 6px;
}

.eid-al-fitr .grego {
  color: #fff;
}

.sacred-month {
  background: #fae8b4;
  color: #000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 500px;
  z-index: 12;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background: #000;
}
</style>
