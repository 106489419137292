import axios from "axios";

const state = {
  duaas: [],
  selectedDuaa: null,
  categories: [],
  currentCategory: null,
  duaasUser: [],
};

const getters = {
  duaasList: (state) => state.duaas,
  duaaDetails: (state) => state.selectedDuaa,
  categoriesList: (state) => state.categories,
  currentCategory: (state) => state.currentCategory,
  duaasUser: (state) => state.duaasUser,
};

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CURRENT_CATEGORY(state, category) {
    state.currentCategory = category;
  },
  SET_SELECTED_DUAA(state, titreduaa) {
    state.selectedDuaa = titreduaa;
  },
  SET_USER_DUAA(state, duaasUser) {
    state.duaasUser = duaasUser;
  },
};

const actions = {
  async fetchCategories({ commit }, lang) {
    try {
      const response = await axios.get("/sujets", {
        params: {
          locale: lang,
          fields: ["id", "name", "slug"],
          populate: {
            icon: {
              fields: ["url"],
            },
          },
        },
      });

      commit("SET_CATEGORIES", response.data.data);
      // console.log("cat DOUAS: ", response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  },
  async fetchCategoryBySlug({ commit }, { slug, lang }) {
    try {
      const response = await axios.get("/sujets", {
        params: {
          locale: lang,
          filters: {
            slug: {
              $eq: slug,
            },
          },
          populate: {
            situations: {
              fields: ["id", "name", "slug"],
              locale: lang,
            },
            icon: { fields: ["url"] },
            locale: lang,
          },
        },
      });

      // console.log("response slug : ", response.data.data[0]);

      commit("SET_CURRENT_CATEGORY", response.data.data[0]);
    } catch (error) {
      // console.error("Erreur lors de la récupération de la catégorie par slug:", error);
      commit("SET_CURRENT_CATEGORY", null);
    }
  },
  async fetchTitreDuaaBySlug({ commit, rootGetters }, { slug, lang }) {
    const userId = rootGetters["user/userId"];
    try {
      const response = await axios.get(`/situations`, {
        params: {
          locale: lang, // Utilisation de la locale
          filters: {
            slug: {
              $eq: slug,
            },
          },
          fields: ["name", "slug"],
          populate: {
            douas: {
              fields: ["arab", "transcript", "source", "url_mp3", "translite"],
              populate: {
                users_permissions_users: {
                  fields: ["id"],
                  filters: {
                    id: {
                      $eq: userId,
                    },
                  },
                },
              },
            },
            sujets: {
              fields: ["name", "slug"],
            },
          },
        },
      });

      // console.log("subSlug : ", slug);
      // console.log("log duaas", response);

      // Vérification si des données sont retournées
      if (response.data.data.length > 0) {
        commit("SET_SELECTED_DUAA", response.data.data[0]);
      } else {
        commit("SET_SELECTED_DUAA", null);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du titreduaa par slug:",
        error
      );
      commit("SET_SELECTED_DUAA", null);
    }
  },

  async fetchUserDuaa({ commit, rootGetters }, lang) {
    const userId = rootGetters["user/userId"];
    // console.log("userId :", userId);

    try {
      const response = await axios.get(`/douas`, {
        params: {
          locale: lang,
          fields: [
            "id",
            "arab",
            "transcript",
            "source",
            "url_mp3",
            "translite",
          ],
          filters: {
            users: {
              id: {
                $eq: userId,
              },
            },
          },
          populate: {
            situations: {
              fields: ["name", "slug"],
              locale: lang,
              populate: {
                sujets: {
                  fields: ["name", "slug"],
                  locale: lang,
                  populate: {
                    icon: {
                      fields: ["url"],
                      locale: lang,
                    },
                  },
                },
              },
            },
            users: {
              fields: ["id"],
            },
          },
        },
      });

      // console.log("log duaas", response);
      commit("SET_USER_DUAA", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des duaas", error);
      commit("SET_USER_DUAA", []);
    }
  },

  async fetchUserDuaaById({ commit }, { userId, lang }) {
    try {
      const response = await axios.get(`/douas`, {
        params: {
          locale: lang,
          fields: [
            "id",
            "arab",
            "transcript",
            "source",
            "url_mp3",
            "translite",
          ],
          filters: {
            users: {
              id: {
                $eq: userId,
              },
            },
          },
          populate: {
            situations: {
              fields: ["name", "slug"],
              locale: lang,
              populate: {
                sujets: {
                  fields: ["name", "slug"],
                  locale: lang,
                  populate: {
                    icon: {
                      fields: ["url"],
                      locale: lang,
                    },
                  },
                },
              },
            },
            users: {
              fields: ["id"],
            },
          },
        },
      });

      commit("SET_USER_DUAA", response.data.data);
    } catch (error) {
      console.error("Error fetching user duaas:", error);
      commit("SET_USER_DUAA", []);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
