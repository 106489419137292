<!-- src/components/CitySearch.vue -->
<template>
  <div>
    <div class="lists-my-city">
      <div class="add-city">
        <div class="circle-add" @click="centerDialogVisible = true">
          <el-icon><Plus /></el-icon>
        </div>
        <span>{{ $t("addCity") }}</span>
      </div>
      <div
        class="city"
        v-for="city in cities"
        :key="city.value"
        @click="selectExistingCity(city)"
        @touchstart="startLongPress(city)"
        @touchend="cancelLongPress"
        @mousedown="startLongPress(city)"
        @mouseup="cancelLongPress"
        @mouseleave="cancelLongPress"
      >
        <img
          :src="city.image || defaultImage"
          alt=""
          :class="{ active: city.active }"
        />
        <span class="name-city">{{ city.city }}</span>
      </div>
    </div>

    <!-- Afficher la ville par défaut si aucune autre n'est sélectionnée -->
    <div
      v-if="!isCitySelectedFromList && !hasActiveCity"
      class="city default-city"
      @click="selectDefaultCity"
    >
      <img
        :src="defaultImage"
        alt="Paris"
        :class="{ active: isDefaultCitySelected }"
      />
      <span class="name-city">{{ defaultCity.city }}</span>
    </div>
    <!-- Modal pour ajouter une ville -->
    <el-dialog
      v-model="centerDialogVisible"
      :title="$t('searchCity')"
      width="400"
      destroy-on-close
      center
    >
      <div class="dialog-content">
        <!-- Formulaire de recherche de ville -->
        <div class="search-section">
          <el-autocomplete
            v-model="searchQuery"
            :fetch-suggestions="onSearchInput"
            clearable
            class="inline-input w-50"
            :placeholder="$t('searchCity')"
            @select="addCity"
            :debounce="300"
            :trigger-on-focus="false"
          />
        </div>
      </div>
      <!-- Tableau des villes sélectionnées -->
      <div class="tables">
        <div class="line" v-for="city in cities" :key="city.value">
          <span
            ><i class="fa-solid fa-location-arrow"></i> {{ city.city }}</span
          >
          <el-button size="small" type="danger" @click="removeCity(city)">
            <i class="fa-solid fa-trash-can"></i>
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- Dialog de confirmation de suppression -->
    <el-dialog
      v-model="deleteDialogVisible"
      width="300"
      :title="$t('messageDelete')"
      align-center
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">{{ $t("escapeDelete") }}</el-button>
          <el-button type="danger" @click="confirmDelete">
            {{ $t("deletePost") }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Plus } from "@element-plus/icons-vue";
import { ref, onMounted, watch } from "vue";
import { ElMessage } from "element-plus"; // Importation de ElMessage pour les notifications

export default {
  name: "CitySearch",
  components: {
    Plus,
  },
  setup(props, { emit }) {
    // Définir la ville par défaut (Paris)
    const defaultCity = {
      value: "paris",
      city: "Paris",
      country: "France",
      latitude: 48.8566,
      longitude: 2.3522,
      image: "https://cp.doonun.com/uploads/280231_min_56f7144166.jpg", // Utiliser defaultImage si nécessaire
      active: true, // La ville par défaut est active au départ
    };

    const centerDialogVisible = ref(false);
    const deleteDialogVisible = ref(false);
    const searchQuery = ref("");
    const searchResults = ref([]);
    const showInputField = ref(false);
    const cities = ref([]);
    const defaultImage =
      "https://cp.doonun.com/uploads/280231_min_56f7144166.jpg";
    const longPressTimer = ref(null);
    const cityToDelete = ref(null);

    // Charger les villes sauvegardées depuis le stockage local
    const loadCitiesFromStorage = () => {
      const storedCities = localStorage.getItem("myCities");
      if (storedCities) {
        try {
          cities.value = JSON.parse(storedCities);
        } catch (error) {
          console.error(
            "Erreur lors de la lecture des villes sauvegardées :",
            error
          );
          cities.value = [];
        }
      } else {
        // Si aucune ville sauvegardée, initialiser avec la ville par défaut
        cities.value = [defaultCity];
        saveCitiesToStorage();
      }
    };

    // Sauvegarder les villes dans le stockage local
    const saveCitiesToStorage = () => {
      localStorage.setItem("myCities", JSON.stringify(cities.value));
    };

    // Charger la ville active depuis le stockage local
    const loadActiveCityFromStorage = () => {
      const storedCities = localStorage.getItem("myCities");
      if (storedCities) {
        try {
          const parsedCities = JSON.parse(storedCities);
          cities.value = parsedCities;

          const activeCity = cities.value.find((city) => city.active);
          if (activeCity) {
            emit("city-selected", activeCity);
          } else {
            // Si aucune ville n'est active, activer la ville par défaut
            setActiveCity(defaultCity.value);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la lecture des villes sauvegardées :",
            error
          );
          cities.value = [defaultCity];
          saveCitiesToStorage();
          emit("city-selected", defaultCity);
        }
      } else {
        // Si aucune ville sauvegardée, initialiser avec la ville par défaut
        cities.value = [defaultCity];
        saveCitiesToStorage();
        emit("city-selected", defaultCity);
      }
    };

    // Initialiser les villes et la ville active lors du montage
    onMounted(() => {
      loadCitiesFromStorage();
      loadActiveCityFromStorage();
    });

    // Observer les changements dans la liste des villes pour les sauvegarder
    watch(
      cities,
      () => {
        saveCitiesToStorage();
      },
      { deep: true }
    );

    const onSearchInput = async (query, callback) => {
      if (query.length < 3) {
        callback([]);
        return;
      }
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&city=${encodeURIComponent(
            query
          )}&addressdetails=1`
        );
        const data = await response.json();
        const suggestions = data.map((item) => {
          // Extraction du nom de la ville
          const city =
            item.address?.village ||
            item.address?.city ||
            item.address?.town ||
            item.address?.county ||
            item.address?.state_district || // Possibilité de département
            item.address?.state ||
            "Ville inconnue";

          // Extraction du département
          const department =
            item.address?.state ||
            item.address?.state_district ||
            item.address?.county ||
            "Département inconnu";

          return {
            value: `${city}, ${department}`, // Affichage "Ville, Département"
            city: `${city}, ${department}`, // Stockage combiné pour affichage ultérieur
            country: item.address?.country || "Pays inconnu",
            latitude: Number(item.lat),
            longitude: Number(item.lon),
            image: null,
            active: false,
          };
        });
        callback(suggestions);
      } catch (error) {
        console.error("Erreur lors de la récupération des villes :", error);
        callback([]);
      }
    };

    // Fonction pour ajouter une ville
    const addCity = (item) => {
      if (cities.value.length >= 5) {
        ElMessage.warning("Vous ne pouvez ajouter que 5 villes maximum.");
        return;
      }
      const exists = cities.value.find((city) => city.value === item.value);
      if (exists) {
        ElMessage.info("Cette ville est déjà dans votre liste.");
        return;
      }

      // Désactiver toutes les autres villes
      cities.value.forEach((city) => (city.active = false));

      const newCity = { ...item, image: defaultImage, active: true };
      cities.value.push(newCity);
      centerDialogVisible.value = false;

      // Émission de l'événement vers le parent avec les détails de la ville active
      emit("city-selected", newCity);

      ElMessage.success(
        `${item.city}, ${item.country} a été ajoutée et sélectionnée.`
      );
    };

    // Fonction pour sélectionner une ville existante
    const selectExistingCity = (city) => {
      setActiveCity(city.value);
      emit("city-selected", city);
    };

    // Fonction pour sélectionner la ville par défaut (Paris)
    const selectDefaultCity = () => {
      setActiveCity(defaultCity.value);
      emit("city-selected", defaultCity);
      ElMessage.success(`${defaultCity.city} a été sélectionnée.`);
    };

    // Fonction pour définir une ville comme active
    const setActiveCity = (cityValue) => {
      cities.value.forEach((city) => {
        if (city.value === cityValue) {
          city.active = true;
        } else {
          city.active = false;
        }
      });
    };

    // Fonction pour supprimer une ville
    const removeCity = (cityToRemove) => {
      // Filtrer la ville à supprimer
      cities.value = cities.value.filter(
        (city) => city.value !== cityToRemove.value
      );

      ElMessage.success(
        `${cityToRemove.city}, ${cityToRemove.country} a été supprimée.`
      );

      // Si la ville supprimée était active, activer une autre ville
      if (cityToRemove.active) {
        if (cities.value.length > 0) {
          setActiveCity(cities.value[0].value); // Activer la première ville de la liste
          ElMessage.info(`${cities.value[0].city} a été activée.`);
        } else {
          // Si aucune ville n'est restante, activer la ville par défaut
          setActiveCity(defaultCity.value);
          ElMessage.info(`${defaultCity.city} a été activée.`);
        }
      }
    };

    // Gestion de l'appui long
    const startLongPress = (city) => {
      if (city.value === defaultCity.value) return; // Ne pas permettre la suppression de la ville par défaut
      
      longPressTimer.value = setTimeout(() => {
        cityToDelete.value = city;
        deleteDialogVisible.value = true;
      }, 800); // Délai de 800ms pour l'appui long
    };

    const cancelLongPress = () => {
      if (longPressTimer.value) {
        clearTimeout(longPressTimer.value);
        longPressTimer.value = null;
      }
    };

    const confirmDelete = () => {
      if (cityToDelete.value) {
        removeCity(cityToDelete.value);
        deleteDialogVisible.value = false;
        cityToDelete.value = null;
      }
    };

    // Vérifier si la ville sélectionnée est dans la liste des villes ajoutées
    const isCitySelectedFromList = () => {
      return cities.value.some(
        (city) => city.active && city.value !== defaultCity.value
      );
    };

    // Fonction pour vérifier si une ville est sélectionnée
    const isSelectedCity = (city) => {
      return city.active;
    };

    // Fonction pour vérifier si la ville par défaut est sélectionnée
    const isDefaultCitySelected = () => {
      return cities.value.some(
        (city) => city.active && city.value === defaultCity.value
      );
    };

    return {
      centerDialogVisible,
      deleteDialogVisible,
      searchQuery,
      searchResults,
      showInputField,
      cities,
      defaultImage,
      onSearchInput,
      addCity,
      selectExistingCity,
      defaultCity,
      selectDefaultCity,
      removeCity,
      isCitySelectedFromList,
      isSelectedCity,
      isDefaultCitySelected,
      startLongPress,
      cancelLongPress,
      confirmDelete,
    };
  },
};
</script>

<style scoped>
.lists-my-city {
  width: 100%;
  max-width: 500px;
  display: flex;
  overflow-y: auto;
  gap: 15px;
  font-size: 12px;
  margin: 20px 0 30px 0;
  flex-wrap: nowrap;
}

.add-city,
.city {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  min-width: 60px;
  max-width: 60px;
  cursor: pointer;
}

.add-city i {
  font-size: 20px;
}

.add-city.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.add-city .circle-add {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--card);
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
}

.city img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eeeeee;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.name-city {
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60px;
}

.active {
  border: 5px solid var(--button);
  border-radius: 10px;
}

.search-section {
  margin-bottom: 10px;
}

.line {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 5px 5px 8px;
  border-radius: 6px;
  background: var(--body);
  margin-bottom: 5px;
}

.line span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
