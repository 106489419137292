// store/modules/goalCheck.js
import axios from "axios";

const state = () => ({
  goalChecks: [], // Tableau contenant les goal-checks
  lastCheckTime: null, // Dernière vérification de l'objectif
});

const mutations = {
  SET_GOAL_CHECKS(state, checks) {
    state.goalChecks = checks;
  },
  ADD_GOAL_CHECK(state, check) {
    state.goalChecks.push(check);
  },
  UPDATE_GOAL_CHECK(state, updatedCheck) {
    const index = state.goalChecks.findIndex(
      (check) => check.id === updatedCheck.id
    );
    if (index !== -1) {
      state.goalChecks.splice(index, 1, updatedCheck);
    }
  },
  SET_LAST_CHECK_TIME(state, time) {
    state.lastCheckTime = time;
  },
};

const actions = {
  async fetchGoalChecks({ commit, state }, { period = "week", force = false }) {
    try {
      const userId = this.getters["user/userId"];
      let startDate;
      let endDate = new Date();

      // Si nous avons déjà des données et que force n'est pas actif, ne pas recharger
      if (state.goalChecks.length > 0 && !force) {
        console.log("Utilisation des données de goal-checks en cache");
        return state.goalChecks;
      }
      
      switch (period) {
        case "day":
          startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          break;
        case "week":
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          break;
        case "month":
          startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          break;
        default:
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
      }

      const response = await axios.get(`/goal-checks`, {
        params: {
          filters: {
            users_permissions_user: { id: userId },
            createdAt: { $gte: startDate.toISOString(), $lte: endDate.toISOString() },
          },
          sort: ["createdAt:asc"],
          fields: ["id", "versesCount", "valide", "createdAt"],
        },
      });

      // Map Strapi v4 => on aplatit le tableau
      const rawData = response.data?.data || [];
      const formattedData = rawData.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));

      commit("SET_GOAL_CHECKS", formattedData);
    } catch (error) {
      console.error("Erreur fetchGoalChecks:", error);
    }
  },

  // Action supplémentaire si vous avez besoin de manipuler les goal-checks
  async addGoalCheck({ commit }, checkData) {
    try {
      const response = await axios.post(`/goal-checks`, {
        data: checkData,
      });
      commit("ADD_GOAL_CHECK", response.data.data);
    } catch (error) {
      console.error("Erreur addGoalCheck:", error);
      throw error;
    }
  },
  
  // Vérifier si l'objectif du jour est atteint
  async checkDailyGoalStatus({ commit, state, dispatch }) {
    try {
      console.log("Vérification du statut de l'objectif journalier...");
      
      // Forcer la récupération des dernières données à chaque fois
      await dispatch("fetchGoalChecks", { period: "week", force: true });
      
      // Obtenir l'objectif actuel pour vérifier s'il existe
      const goal = await dispatch("check/getCurrentDailyGoal", null, { root: true });
      
      if (!goal || goal <= 0) {
        console.log("Aucun objectif défini pour aujourd'hui");
        return false;
      }
      
      // Obtenir la date d'aujourd'hui au format ISO (YYYY-MM-DD)
      const today = new Date();
      const todayISO = today.toISOString().split('T')[0];
      
      console.log(`Recherche d'un goal-check pour la date: ${todayISO}`);
      console.log(`Nombre de goalChecks disponibles: ${state.goalChecks.length}`);
      
      // Vérifier si l'un des goal-checks correspond à aujourd'hui
      const todayCheck = state.goalChecks.find(check => {
        const checkDate = new Date(check.createdAt).toISOString().split('T')[0];
        const matches = checkDate === todayISO;
        if (matches) {
          console.log(`Goal-check trouvé pour aujourd'hui:`, check);
        }
        return matches;
      });
      
      // Mettre à jour la dernière heure de vérification
      commit("SET_LAST_CHECK_TIME", new Date());
      
      // Si on a trouvé un check pour aujourd'hui et qu'il est valide, c'est que l'objectif est atteint
      if (todayCheck && todayCheck.valide) {
        console.log("Objectif journalier atteint!");
        return true;
      } else {
        console.log("Objectif journalier non atteint.");
        return false;
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'objectif journalier:", error);
      return false;
    }
  }
};

const getters = {
  getGoalChecks: (state) => state.goalChecks,
  isDailyGoalAchieved: (state) => {
    if (!state.goalChecks || state.goalChecks.length === 0) return false;
    
    const today = new Date();
    const todayISO = today.toISOString().split('T')[0];
    
    const todayCheck = state.goalChecks.find(check => {
      const checkDate = new Date(check.createdAt).toISOString().split('T')[0];
      return checkDate === todayISO;
    });
    
    return todayCheck && todayCheck.valide === true;
  },
  getLastCheckTime: (state) => state.lastCheckTime,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
