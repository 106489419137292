// store/modules/like.js
import axios from "axios";
import { withCache, invalidateCache } from "../../utils/apiCache";

// Constantes pour les clés de cache
const CACHE_KEYS = {
  LIKES: (userId) => `user_likes_${userId}`
};

// TTL (Time-To-Live) pour différents types de données
const CACHE_TTL = {
  LIKES: 5 * 60 * 1000 // 5 minutes pour les likes
};

const state = {
  likes: {
    data: []
  },
  isLoadingLikes: false
};

const getters = {
  likes: (state) => state.likes,
};

const mutations = {
  SET_LIKES(state, likes) {
    state.likes = likes;
  },
  SET_LOADING_LIKES(state, isLoading) {
    state.isLoadingLikes = isLoading;
  }
};

const actions = {
  async fetchLikes({ commit, rootGetters, state }, { userId } = {}) {
    // Éviter des requêtes simultanées
    if (state.isLoadingLikes) {
      return;
    }
    
    commit("SET_LOADING_LIKES", true);
    
    try {
      // Utiliser l'ID fourni ou l'ID de l'utilisateur connecté si non fourni
      const userIdToUse = userId || rootGetters["user/userId"];
      
      // Vérifier si les données sont en cache
      const cachedLikes = await withCache(
        CACHE_KEYS.LIKES(userIdToUse),
        async () => {
          const response = await axios.get("/like-sourates", {
            params: {
              fields: ["id", "id_sourate"],
              filters: {
                users_permissions_users: userIdToUse,
              },
            },
          });
          
          // Vérifier que response.data est valide
          if (response && response.data) {
            return response.data;
          } else {
            console.error("Réponse API invalide pour les likes:", response);
            return { data: [] };
          }
        },
        CACHE_TTL.LIKES
      );
      
      commit("SET_LIKES", cachedLikes);
    } catch (error) {
      console.error("Erreur lors du chargement des likes:", error);
      // En cas d'erreur, initialiser avec une structure vide mais valide
      commit("SET_LIKES", { data: [] });
    } finally {
      commit("SET_LOADING_LIKES", false);
    }
  },

  async updateLike({ rootGetters, dispatch }, { id, liked, like }) {
    try {
      const userId = rootGetters["user/userId"];
      const endpoint = `/like-sourates/${id}?populate=*`;
      const method = "put";

      const getResponse = await axios.get(endpoint);

      if (
        getResponse.data &&
        getResponse.data.data &&
        getResponse.data.data.attributes &&
        getResponse.data.data.attributes.users_permissions_users &&
        getResponse.data.data.attributes.users_permissions_users.data
      ) {
        let currentUsers =
          getResponse.data.data.attributes.users_permissions_users.data.map(
            (user) => user.id
          );

        let data = {
          likes: like,
          users_permissions_users: {},
        };

        if (liked && !currentUsers.includes(userId)) {
          data.likes = like + 1;
          data.users_permissions_users.connect = [{ id: userId }];
        } else if (!liked && currentUsers.includes(userId)) {
          data.likes = like - 1;
          data.users_permissions_users.disconnect = [{ id: userId }];
        }

        await axios({
          method: method,
          url: endpoint,
          data: { data },
        });
        
        // Invalidation du cache après une mise à jour
        invalidateCache(CACHE_KEYS.LIKES(userId));
        
        // Rechargement des likes pour la mise à jour du store
        await dispatch("fetchLikes");
      } else {
        throw new Error(
          "users_permissions_users non trouvé dans la réponse de l'API"
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du like :", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
