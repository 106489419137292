import axios from "axios";

const state = {
  topicTags: [],
  currentTopicTag: null,
  currentTopic: null,
  currentTopicAyahs: [],
  isFetchingTopicTags: false,
  isFetchingTopic: false,
};

const getters = {
  topicTags: (state) => state.topicTags,
  currentTopicTag: (state) => state.currentTopicTag,
  currentTopic: (state) => state.currentTopic,
  currentTopicAyahs: (state) => state.currentTopicAyahs,
};

const mutations = {
  SET_TOPIC_TAGS(state, topicTags) {
    state.topicTags = topicTags;
  },
  SET_CURRENT_TOPIC_TAG(state, topicTag) {
    state.currentTopicTag = topicTag;
  },
  SET_IS_FETCHING_TOPIC_TAGS(state, isFetching) {
    state.isFetchingTopicTags = isFetching;
  },
  SET_CURRENT_TOPIC(state, topic) {
    state.currentTopic = topic;
  },
  SET_CURRENT_TOPIC_AYAHS(state, ayahs) {
    state.currentTopicAyahs = ayahs;
  },
  SET_IS_FETCHING_TOPIC(state, isFetching) {
    state.isFetchingTopic = isFetching;
  },
};

const actions = {
  async fetchTopicTags({ commit }) {
    if (state.topicTags.length && !state.isFetchingTopicTags) {
      return;
    }
    
    try {
      commit("SET_IS_FETCHING_TOPIC_TAGS", true);
      
      const response = await axios.get("/topic-tags", {
        params: {
          populate: {
            topics: {
              fields: ["id", "name"],
              populate: {
                ayahs: {
                  fields: [
                    "id",
                  ],
                },
              }
            },
          },
        },
      });

      // Traiter les données pour ajouter la longueur des ayahs à chaque topic si nécessaire
      const topicTags = response.data.data.reverse();
      
      commit("SET_TOPIC_TAGS", topicTags);
    } catch (error) {
      console.error("Error fetching topic tags:", error);
    } finally {
      commit("SET_IS_FETCHING_TOPIC_TAGS", false);
    }
  },
  
  async fetchTopicTag({ commit }, id) {
    try {
      const response = await axios.get(`/topic-tags/${id}`, {
        params: {
          populate: {
            topics: {
              fields: ["id", "name"],
              populate: {
                ayahs: {
                  fields: [
                    "id",
                    "arabe",
                    "fr",
                    "phonetique",
                    "numero",
                    "Tajweed",
                    "tafsir",
                  ],
                  populate: {
                    sourate: {
                      fields: ["Numero", "Phonetique", "Titre"],
                    },
                  },
                },
              },
            },
          },
        },
      });

      commit("SET_CURRENT_TOPIC_TAG", response.data.data);
    } catch (error) {
      console.error(`Error fetching topic tag with id ${id}:`, error);
    }
  },

  // Nouvelle action pour récupérer les ayahs d'un topic spécifique
  async fetchTopicAyahs({ commit }, topicId) {
    commit("SET_IS_FETCHING_TOPIC", true);
    try {
      const response = await axios.get(`/topics/${topicId}`, {
        params: {
          populate: {
            ayahs: {
              fields: [
                "id",
                "arabe",
                "fr",
                "phonetique",
                "numero",
                "Tajweed",
                "tafsir",
              ],
              populate: {
                sourate: {
                  fields: ["Numero", "Phonetique", "Titre", "id"],
                },
                users: {
                  fields: ["id"],
                },
              },
            },
          },
        },
      });

      const topic = response.data.data;

      commit("SET_CURRENT_TOPIC", topic);
      
      // Extraire les ayahs du topic pour faciliter l'accès
      if (topic && topic.attributes && topic.attributes.ayahs && topic.attributes.ayahs.data) {
        commit("SET_CURRENT_TOPIC_AYAHS", topic.attributes.ayahs.data);
      } else {
        commit("SET_CURRENT_TOPIC_AYAHS", []);
      }
    } catch (error) {
      console.error(`Error fetching topic with id ${topicId}:`, error);
      commit("SET_CURRENT_TOPIC", null);
      commit("SET_CURRENT_TOPIC_AYAHS", []);
    } finally {
      commit("SET_IS_FETCHING_TOPIC", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};